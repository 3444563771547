<template>
  <v-row>
    <v-col cols="12">
      <!----------Vendor Buttons Start------------->
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromVendor"
        class="mr-1"
        x-small
        depressed
        @click="editVendorDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <!------------Vendor Buttons End--------------->

      <!-- -------User Buttons Start--------------->
      <v-btn
        color="primary"
        v-if="params.context.parentComponent.fromUser"
        x-small
        depressed
        @click="editDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- -------User Buttons End--------------->

      <!----------Report Buttons Start------------->
      <v-btn
        color="primary"
        v-if="
          params.context.parentComponent.fromReport &&
          params.data.total_records > 0
        "
        class="mr-1"
        x-small
        depressed
        @click="downloadReport()"
      >
        <v-icon small>mdi-download</v-icon>
      </v-btn>
      <!-- -------Report Buttons End--------------->

      <!----------Inbound Shipment Details Buttons Start------------->
      <v-btn
        text
        v-if="
          params.context.parentComponent.fromInboundShipmentDetails ||
          params.context.parentComponent.fromVendorShipmentDetails
        "
        @click="moreDetails()"
      >
        <v-icon color="secondary">mdi-open-in-new</v-icon>
      </v-btn>
      <!-- -------Inbound Shipment Details Buttons End--------------->

      <!----------Organization Configuration Buttons Start------------->
      <v-btn
        color="primary"
        x-small
        depressed
        v-if="params.context.parentComponent.fromOrganizationConfiguration"
        @click="editDetails()"
      >
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <!-- -------Organization Configuration Buttons End--------------->

      <!----------Updates By AWB Buttons Start------------->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            v-if="params.context.parentComponent.fromUpdatesByAwb"
            class="mr-1"
            x-small
            depressed
            @click="resendUpdate()"
          >
            <v-icon small>mdi-reload</v-icon>
          </v-btn>
        </template>
        Resend Update
      </v-tooltip>
      <!-- -------Updates By AWB Buttons End--------------->
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    openOrganizationClientPage() {
      this.params.context.parentComponent.openOrganizationClientPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    downloadReport() {
      this.params.context.parentComponent.downloadReport(this.params.data);
    },
    editVendorDetails() {
      this.params.context.parentComponent.editVendorDetails(
        this.params.data.id
      );
    },
    moreDetails() {
      this.params.context.parentComponent.moreDetails(this.params.data.payload);
    },
    resendUpdate() {
      this.params.context.parentComponent.resendSingleUpdate(
        this.params.data.id
      );
    },
  },
};
</script>
