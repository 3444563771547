<template>
  <v-row class="ma-0">
    <v-col cols="12" class="relative-position">
      <v-card
        elevation="0"
        style="background-color: #f5f8fa"
        class="pa-1 list-box-Shadow"
      >
        <v-row no-gutters>
          <v-col cols="2" class="text-left d-flex align-center">
            <h3 class="text-uppercase text--primary">Integration Partner</h3>
          </v-col>
          <v-col cols="10" class="d-flex justify-end">
            <v-btn
              color="primary"
              small
              depressed
              class="text-capitalize"
              @click="addIntegrationPartner"
            >
              Add Integration Partner
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card elevation="0" style="height: 800px">
        <v-row>
          <v-col cols="2" v-for="api in integrationPartnerList" :key="api.id">
            <v-card elevation="1">
              <v-card-title class="grey lighten-3 primary--text">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex justify-center">
                    {{ api.name }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pa-2">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      small
                      text
                      class="mb-2"
                      color="secondary"
                      @click="viewDetails(api.id)"
                    >
                      <v-icon small class="pr-2">mdi-eye</v-icon>
                      Api List
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      text
                      small
                      color="secondary"
                      @click="editDetails(api.id)"
                    >
                      <v-icon small class="pr-2">mdi-pencil</v-icon>
                      edit Details
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <IntegrationPartnerForm
      ref="IntegrationPartnerForm"
      v-model="showIntegrationPartnerForm"
      :IntegrationPartnerID="IntegrationPartnerID"
      :editMode="editMode"
      @refreshList="getIntegrationPartnerList"
    />
    <IntegrationPartnerDetails
      v-model="showIntegrationPartnerDetails"
      :integrationPartnerDetails="integrationPartnerDetails"
      @closeDetails="showIntegrationPartnerDetails = false"
    />
  </v-row>
</template>
  
  <script>
import IntegrationPartnerForm from "./IntegrationPartnerForm.vue";
import IntegrationPartnerDetails from "./IntegrationPartnerDetails.vue";
import { bus } from "@/main";

export default {
  name: "IntegrationPartnerList",
  components: {
    IntegrationPartnerForm,
    IntegrationPartnerDetails,
  },
  data() {
    return {
      editMode: false,
      integrationPartnerList: [],
      integrationPartnerDetails: {},
      showIntegrationPartnerForm: false,
      showIntegrationPartnerDetails: false,
      IntegrationPartnerID: null,
    };
  },
  methods: {
    addIntegrationPartner() {
      this.editMode = false;
      this.showIntegrationPartnerForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.IntegrationPartnerID = id;
      this.showIntegrationPartnerForm = true;
    },
    viewDetails(id) {
      this.$api.integrationPartner
        .viewIntegrationPartnerDetails(id)
        .then((res) => {
          this.integrationPartnerDetails = res.data;
          this.showIntegrationPartnerDetails = true;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    getIntegrationPartnerList() {
      bus.$emit("showLoader", true);
      this.$api.integrationPartner
        .getIntegrationPartnerList()
        .then((res) => {
          bus.$emit("showLoader", false);
          this.integrationPartnerList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.getIntegrationPartnerList();
  },
};
</script>
  