<template>
  <div>
    <!-- Header Section -->
    <v-row class="ma-0">
      <v-col class="d-flex align-center">
        <v-btn
          elevation="0"
          size="large"
          icon
          color="primary"
          class="mr-2"
          name="back-button"
          @click="$router.back()"
        >
          <v-icon large color="primary">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <span
          class="text-h6 font-weight-bold text--primary pa-0 text-uppercase"
        >
          Shipments</span
        >
      </v-col>
    </v-row>

    <!-- Details Section -->
    <v-row class="ma-0">
      <v-col
        cols="12"
        lg="3"
        class="overflow-y-auto py-0 pr-lg-0"
        style="max-height: calc(85vh)"
      >
        <v-card elevation="1" rounded="lg" class="shadow-on-hover">
          <v-card rounded="lg" class="primary">
            <v-card-text class="pa-2">
              <v-row class="ma-0">
                <v-col cols="9" lg="9" class="pa-1">
                  <span class="text-caption text-white">Reference Number</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{ shipmentObject.reference_number }}
                  </span>
                  <span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="
                            copyReferenceNumber(shipmentObject.reference_number)
                          "
                        >
                          <v-icon color="white" small>
                            mdi-content-copy
                          </v-icon>
                        </v-btn>
                      </template>
                      Copy Reference Number
                    </v-tooltip>
                  </span>
                </v-col>

                <v-col
                  cols="3"
                  lg="3"
                  class="pa-1 d-flex align-center justify-end"
                >
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{ shipmentObject.awb_number }}
                          </span>
                        </v-chip>
                      </template>
                      AWB Number
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col cols="12 pa-1">
                  <v-divider class="white"></v-divider>
                </v-col>

                <v-col cols="12" class="pa-1">
                  <span class="text-caption text-white">Created</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{
                      shipmentObject && shipmentObject.created
                        ? convertEventTimeStamp(shipmentObject.created) +
                          " (" +
                          formatLastUpdatedTime(shipmentObject.created) +
                          ")"
                        : null
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
        <v-divider></v-divider>
        <v-card
          elevation="1"
          class="mt-3 rounded-lg"
          v-if="shipmentObject.is_success == 'No'"
        >
          <v-card-text
            v-if="
              shipmentObject &&
              shipmentObject.response &&
              shipmentObject.response.error
            "
            class="pa-0"
          >
            <v-list
              dense
              class="ml-2 pa-0"
              style="background: inherit !important"
              v-for="(error, key, i) in shipmentObject.response.error"
              :key="i"
            >
              <v-list-item dense>
                <span class="red--text font-weight-bold"
                  >{{ key }} -{{ error }}</span
                >
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>

        <!-- Timeline -->
        <v-card elevation="1" class="my-3 rounded-lg">
          <v-card-title class="primary py-1 px-3 text-white">
            <v-row no-gutters>
              <v-col cols="6">Shipment Timeline</v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2 mt-0 mb-3 pt-0">
            <v-stepper vertical elevation="0">
              <v-stepper-step step="1" :complete="curr >= 1">
                Created
              </v-stepper-step>
              <v-stepper-step step="2" :complete="curr >= 2">
                Picked Up
              </v-stepper-step>
              <v-stepper-step step="3" :complete="curr >= 3">
                Arrived At Hub
              </v-stepper-step>
              <v-stepper-step step="4" :complete="curr >= 4">
                Delivered
              </v-stepper-step>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Tabs Section -->
      <v-col cols="12" lg="9" class="ma-0 pa-0 pl-2">
        <v-card elevation="0" rounded="lg">
          <v-card-text class="ma-0 pa-0">
            <v-row no-gutters>
              <v-col cols="10" class="ma-0 pa-0">
                <v-tabs grow v-model="currentTab">
                  <v-tab name="summary">Summary</v-tab>
                  <v-tab name="history_logs">History</v-tab>
                  <v-tab name="system_logs">System Logs</v-tab>
                  <v-tab name="awb_logs">AWB Logs</v-tab>
                  <v-tab name="webhook_logs">Webhook Logs</v-tab>
                </v-tabs>
              </v-col>
              <v-col cols="2" class="d-flex align-center justify-end">
                <v-tooltip bottom v-if="shipmentObject.is_success === 'Yes'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      color="secondary"
                      small
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      @click="fetchShipmentStatus()"
                    >
                      <v-icon small>mdi-reload</v-icon>
                    </v-btn>
                  </template>
                  <span>Fetch Status</span>
                </v-tooltip>

                <v-menu offset-y v-if="shipmentObject.is_success == 'No'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      depressed
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                    >
                      <v-icon> mdi-dots-horizontal </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      value="Edit & Book"
                      @click="showEditForm = true"
                    >
                      <v-icon small class="mr-2"> mdi-eye </v-icon>
                      <v-list-item-title>Edit & Book</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-window
          v-model="currentTab"
          style="background-color: transparent !important"
        >
          <!-- Summary Tab -->
          <v-window-item>
            <v-row class="mt-0">
              <v-col cols="12">
                <FeroPartnerSummary
                  v-if="shipmentObject.integration_partner_name == 'Fero'"
                  :shipmentObject="shipmentObject"
                  :payloadData="shipmentObject.payload"
                />
                <CarriyoPartnerSummary
                  v-if="shipmentObject.integration_partner_name == 'Carriyo'"
                  :shipmentObject="shipmentObject"
                  :payloadData="shipmentObject.payload"
                />
              </v-col>
            </v-row>
          </v-window-item>

          <!-- History Logs -->
          <v-window-item style="height: calc(100vh - 185px)">
            <v-card
              elevation="1"
              class="overflow-auto rounded-lg mt-2"
              style="height: calc(100vh - 180px)"
            >
              <v-card-text v-if="!historyLogs.length">
                <div class="py-3 d-flex justify-center">No History Found</div>
              </v-card-text>
              <v-card-text v-else class="ma-0 pa-0">
                <v-timeline dense small>
                  <v-timeline-item
                    color="secondary"
                    v-for="item in historyLogs"
                    :key="item.id"
                    small
                    fill-dot
                  >
                    <v-alert
                      class="py-2 mb-1"
                      color="grey lighten-2"
                      :value="true"
                    >
                      <v-row>
                        <v-col cols="9">
                          {{ item.message }}
                        </v-col>
                        <v-col cols="3" class="d-flex">
                          {{
                            item.created
                              ? convertEventTimeStamp(item.created)
                              : null
                          }}
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- System Logs -->
          <v-window-item>
            <v-card class="overflow-auto" style="height: calc(100vh - 185px)">
              <v-card-text class="pa-0 ma-0">
                <BaseListLayout
                  name="systemList"
                  ref="systemList"
                  :table-header="systemColumns"
                  :table-data="systemLogs"
                  :hasAddButton="true"
                  :context="context"
                  :gridStyle="gridStyle"
                  :showColumnSelection="false"
                  :total="totalItems"
                  @getList="getClientShipmentSystemLogs"
                />
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- AWB Logs -->
          <v-window-item style="height: calc(100vh - 185px)">
            <v-card
              elevation="1"
              class="overflow-auto rounded-lg mt-2"
              style="height: calc(100vh - 180px)"
            >
              <v-card-text
                v-if="
                  shipmentObject &&
                  shipmentObject.awb_logs &&
                  !shipmentObject.awb_logs.length
                "
              >
                <div class="py-3 d-flex justify-center">
                  No AWB Logs To Show
                </div>
              </v-card-text>
              <v-card-text v-else class="ma-0 pa-0 mt-2">
                <v-timeline dense>
                  <v-timeline-item
                    color="secondary"
                    v-for="(log, i) in shipmentObject.awb_logs"
                    :key="i"
                    small
                    fill-dot
                  >
                    <div>
                      <v-row no-gutters>
                        <v-col cols="6">
                          <span style="font-size: 10px" class="text-caption">
                            {{ log.status }} (
                            {{
                              log.created
                                ? convertEventTimeStamp(log.created)
                                : null
                            }})
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <h5 class="black--text text-caption">
                            {{ log.message }}
                          </h5>
                        </v-col>
                        <v-col cols="12">
                          <span style="font-size: 10px" class="text-caption">
                            Customer Name - {{ log.customer_name }}, Current
                            Branch -
                            {{ log.current_branch }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- Webhook Logs -->
          <v-window-item>
            <v-card>
              <v-card-text class="pa-0 ma-0">
                <BaseListLayout
                  name="webhookLogsList"
                  ref="webhookLogsList"
                  :table-header="clientWebhookLogColumns"
                  :table-data="clientWebhookLogsData"
                  :context="context"
                  :total="totalItems"
                  :gridStyle="gridStyle"
                  :showColumnSelection="false"
                  @getList="getClientWebhookLogsList"
                />
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>

    <BaseDialog
      v-model="showPayloadPreview"
      width="60vh"
      title="Payload"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payloadPreviewData }}</pre>
        </div>
      </template>
      <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
    </BaseDialog>
    <EditOrderForm
      @refreshList="getClientShipmentHistoryLogs"
      :integrationPartner="shipmentObject.integration_partner_name"
      v-model="showEditForm"
      :orderObj="shipmentObject.payload"
      :objId="shipmentObject.id"
      @save="saveOrderFormData"
    />
    <Logs
      v-model="showLogsDialog"
      :logTitle="logFor"
      :logHeight="
        (style = 'height:calc(71.5vh)!important; overflow-x: hidden;')
      "
      :logs="historyLogs"
    />
  </div>
</template>
<script>
import { bus } from "@/main";
import { formatLastUpdated, convertDateTime } from "@/utils/functions";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import EditOrderForm from "./EditOrderForm.vue";
import FeroPartnerSummary from "./FeroPartnerSummary.vue";
import CarriyoPartnerSummary from "./CarriyoPartnerSummary.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import Logs from "@/components/common/Logs.vue";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    ShipmentCellRenderer,
    ActionButton,
    BaseDialog,
    EditOrderForm,
    CarriyoPartnerSummary,
    FeroPartnerSummary,
    CellRenderer,
    Logs,
  },
  data() {
    return {
      fromInboundShipmentDetails: true,
      totalItems: 0,
      showEditForm: false,
      gridStyle: {
        width: "100%",
        height: "60vh",
      },
      curr: 1,
      currentTab: "summary",
      shipmentObject: {},
      showPayloadPreview: false,
      payloadPreviewData: {},

      systemLogs: [],
      historyLogs: [],
      systemColumns: [
        { headerName: "Message", field: "message" },
        { headerName: "Added By", field: "added_by", maxWidth: 200 },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
          maxWidth: 150,
        },
        { headerName: "Status Code", field: "status_code", maxWidth: 150 },
        {
          headerName: "More Details",
          field: "actions",
          cellRenderer: "ActionButton",
          maxWidth: 150,
        },
      ],
      clientWebhookLogsData: [],
      clientWebhookLogColumns: [
        { headerName: "Trace ID", field: "trace_id" },
        { headerName: "AWB Number", field: "awb_number" },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Method", field: "method" },
        { headerName: "Status", field: "status" },
        // { headerName: "Endpoint", field: "endpoint" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "CellRenderer",
        },
      ],

      logFor: "",
      showLogsDialog: false,
    };
  },
  watch: {
    currentTab(val) {
      if (val == 0) {
        this.viewLogDetails();
      } else if (val == 1) {
        this.getClientShipmentHistoryLogs();
      } else if (val == 2) {
        // this.getClientShipmentSystemLogs();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },
    viewLogDetails() {
      let id = this.$route.params.id;
      this.$api.inbound
        .viewLogDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.shipmentObject = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },
    getClientWebhookLogsList(params = {}) {
      bus.$emit("showLoader", true);
      if (this.shipmentObject.awb_number)
        params.awb_number = this.shipmentObject.awb_number;
      else params.request_log = this.$route.params.id;

      this.$api.outboundLogs
        .getClientWebhookLogsList({ ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.clientWebhookLogsData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getClientShipmentSystemLogs(params = {}) {
      if (this.shipmentObject.awb) params.awb = this.shipmentObject.awb;
      else params.request_log = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting system logs",
            color: "red",
          });
        });
    },
    getClientShipmentHistoryLogs(params = {}) {
      if (this.shipmentObject.awb) params.awb = this.shipmentObject.awb;
      else params.request_log = this.$route.params.id;

      this.$api.inbound
        .getClientShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting history logs",
            color: "red",
          });
        });
    },
    fetchShipmentStatus() {
      bus.$emit("showLoader", true);

      this.$api.inbound
        .fetchShipmentStatus(this.shipmentObject.id)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Status Fetched Successfully",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.data?.error?.message,
            color: "red",
          });
        });
    },
    moreDetails(payload) {
      this.payloadPreviewData = payload;
      this.showPayloadPreview = true;
    },
    copyPayload() {
      if (this.payloadPreviewData) {
        navigator.clipboard.writeText(JSON.stringify(this.payloadPreviewData));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    saveOrderFormData(data) {
      this.shipmentObject.payload = { ...data };
    },

    // Logs Method
    openLogsDialog(openFor) {
      this.logFor = openFor;
      this.showLogsDialog = true;
    },

    copyReferenceNumber(refNumber) {
      navigator.clipboard.writeText(refNumber);
      bus.$emit("showToastMessage", {
        message: "Reference Number Copied",
        color: "success",
      });
    },
  },
  // mounted() {
  //   this.viewLogDetails();
  //   this.getClientShipmentHistoryLogs();
  // },
};
</script>
<style scoped>
.light_background {
  background-color: #17b7d0;
}

.v-tab--active {
  background-color: #17b7d0 !important;
}

.primary-detail-card {
  background: linear-gradient(
    50deg,
    primary 55%,
    gradiant-shade,
    primary 75%
  ) !important;
}
</style>
