const logo = require("@/assets/logo.png");

const itemsPerPage = 50;

const profileImage = require("@/assets/user.png");
const choices = require("@/utils/choices.js");

const formatDateValue = {
  formatDate: (value, withTime) => {
    if (value) {
      const _date = new Date(value);

      if (_date) {
        let date = _date.getDate();
        let month = _date.getMonth() + 1;
        let year = _date.getFullYear();

        date = date < 10 ? `0${date}` : `${date}`;
        month = month < 10 ? `0${month}` : `${month}`;

        let fullDate = [date, month, year].join("/");

        let time = [];
        if (withTime) {
          let hour = _date.getHours();
          let minute = _date.getMinutes();

          hour = hour < 10 ? `0${hour}` : `${hour}`;
          minute = minute < 10 ? `0${minute}` : `${minute}`;

          time = [hour, minute].join(":");

          fullDate = [fullDate, time].join(" ");
        }

        return fullDate;
      } else {
        return "";
      }
    }
    return "";
  },
};
export default {
  logo,
  itemsPerPage,
  profileImage,
  choices,
  formatDateValue,
};
