<template>
  <v-dialog
    v-model="showOrganizationVendorBulkStatusMappingDialog"
    persistent
    @submit="submitData()"
    @reset="resetForm()"
    width="30%"
    max-width="60vw"
  >
    <v-card>
      <v-card-title class="primary">
        <v-row class="d-flex justify-space-between">
          <v-col cols="10">
            <h3 class="text-body-1 text-white font-weight-bold">
              Bulk Upload For Status Mapping
            </h3>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-icon
              color="white"
              @click="showOrganizationVendorBulkStatusMappingDialog = false"
            >
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-2">
        <v-row v-if="nonFieldErrors && nonFieldErrors.length > 0">
          <v-col cols="12">
            <v-alert color="error" class="white--text" dismissible dense>
              <p v-for="(error, i) in nonFieldErrors" :key="i">
                {{ error }}
              </p>
              <template #close>
                <v-icon @click="nonFieldErrors = []" color="secondary"
                  >mdi-close</v-icon
                >
              </template>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-form v-model="isValid" ref="bulkStatusMappingForm">
          <v-row class="no-gutters">
            <v-col cols="12" lg="8" class="pr-2">
              <v-file-input
                label="Upload File"
                outlined
                dense
                accept=".xlsx, .xls, .csv"
                v-model="selectedFile"
                @change="getFileData"
                @click:clear="(selectedFile = null), (errors = [])"
              ></v-file-input>
            </v-col>
            <v-col cols="12" lg="4" class="d-flex justify-end pr-2">
              <v-btn
                color="primary"
                customClass="rounded-lg"
                depressed
                @click="downloadSampleExcel()"
              >
                <v-icon> mdi-download </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="no-gutters mt-3 pa-2" v-show="dataWithError.length > 0">
            <v-col cols="12">
              <AgGridVue
                @grid-ready="gridReady"
                :grid-options="gridOptions"
                :column-defs="columnDefs"
                :default-col-def="defaultColDef"
                :context="context"
                :row-data="dataWithError"
                style="width: 100%; height: 400px"
                class="ag-theme-balham"
              >
              </AgGridVue>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          :disabled="!selectedFile"
          customClass="rounded-lg"
          id="submit"
          @click="submitData()"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions.js";
import { AgGridVue } from "ag-grid-vue";
import { compareTwoStrings } from "@/utils/stringCompare.js";
import BulkUploadErrorTooltip from "@/pages/Configuration/organizationVendor/BulkUploadErrorTooltip.vue";
import XLSX from "xlsx";

export default {
  name: "OrganizationVendorBulkStatusMappingDialog",
  components: {
    AgGridVue,
    BulkUploadErrorTooltip,
  },
  props: {
    value: {
      type: Boolean,
    },
    organizationVendorID: {
      type: Number,
    },
  },
  data() {
    return {
      isValid: true,

      selectedFile: null,
      fullData: [],
      dataWithError: [],
      allFields: [],
      requiredFields: [],

      columnDefs: [],
      gridApi: null,
      columnApi: null,
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      defaultColDef: {
        lockPosition: true,
      },

      errors: [],
      nonFieldErrors: [],

      statusList: [
        "Pending",
        "Created",
        "Ready For Pickup",
        "Out For Pickup",
        "Picked Up",
        "Partially Picked Up",
        "Pickup Failed",
        "In Transit",
        "Hold at Hub",
        "Out For Delivery",
        "Partially Delivered",
        "Delivered",
        "Delivery Failed",
        "Out For Return",
        "Returned",
        "Return Failed",
        "Cancelled",
        "Lost",
        "Destroyed",
        "Return to Shipper",
      ],
    };
  },
  watch: {
    showOrganizationVendorBulkStatusMappingDialog(value) {
      if (!value) {
        this.resetForm();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    bulkStatusMapFields() {
      return [
        {
          name: "Organization Status*",
          key: "organization_status",
          type: "string",
          isRequired: true,
          matchRatio: 0.95,
        },
        {
          name: "Vendor Status*",
          key: "vendor_status",
          type: "string",
          isRequired: true,
          matchRatio: 0.95,
        },
        {
          name: "Vendor Status Code",
          key: "vendor_status_code",
          type: "string",
          isRequired: false,
          matchRatio: 0.95,
        },
      ];
    },
    bulkStatusMapSampleData() {
      return [
        {
          "Organization Status": "Pending",
          "Vendor Status": "Pending",
          "Vendor Status Code": "",
        },
        {
          "Organization Status": "Created",
          "Vendor Status": "Created",
          "Vendor Status Code": "",
        },
      ];
    },
    showOrganizationVendorBulkStatusMappingDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    getFileData(file) {
      if (file) {
        this.requiredFields = this.bulkStatusMapFields.filter(
          (f) => f.isRequired
        );
        this.allFields = this.bulkStatusMapFields;

        let reader = new FileReader();
        reader.onload = async () => {
          this.fullData = [];
          this.dataWithError = [];

          let fileData = reader.result;

          let wb = XLSX.read(fileData, {
            type: "binary",
          });

          const headers = this.getHeadersArray(wb.Sheets[wb.SheetNames[0]]);

          this.setHeaders(this.correctSpellingMistakesInHeaders(headers));

          let rowData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);

          rowData = this.formatDataKeys(rowData);
          this.formatAndValidateData(rowData);
        };

        reader.readAsBinaryString(file);
      } else {
        this.errors = [];
        this.fullData = [];
        this.dataWithError = [];
      }
    },
    getHeadersArray(sheetData) {
      return XLSX.utils.sheet_to_csv(sheetData).split(/\r?\n/)[0].split(",");
    },
    correctSpellingMistakesInHeaders(headers) {
      return headers.map((header) => {
        let field = this.getFieldConfig(header);

        if (field) {
          return field.name;
        } else {
          return header;
        }
      });
    },
    getFieldConfig(name) {
      let field = this.allFields.find((f) => {
        return (
          this.stringCompare(f.name, name.trim()) >= f.matchRatio ||
          this.stringCompare(f.key, name.trim()) >= f.matchRatio
        );
      });

      if (field) {
        return field;
      } else {
        return null;
      }
    },
    async setHeaders(headers) {
      this.columns = [];

      headers.forEach((header) => {
        let field = this.getFieldConfig(header);

        let key = header.toLowerCase().replace(/\ /g, "_");
        if (field) {
          key = field.key;
          header = field.name;
        }

        let obj = {
          label: header,
          name: key,
          field: key,
          align: "left",
        };

        this.columns.push(obj);
      });
    },
    formatDataKeys(data) {
      return data.map((d) => {
        const headers = Object.keys(d);
        let i = 0;
        const obj = {};
        while (i < headers.length) {
          const header = headers[i];
          i++;
          const field = this.getFieldConfig(header);
          if (field) {
            obj[field.key] = d[header];
          } else {
            obj[header.toLowerCase().replace(/\ /g, "_")] = d[header];
          }
        }
        return obj;
      });
    },
    formatAndValidateData(data) {
      this.errors = [];
      data.forEach(async (rec, index) => {
        this.errors[index] = {};
        this.fullData.push(rec);
      });
    },

    // Download Sample File Methods
    downloadSampleExcel() {
      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Status Mapping" + "Excel File",
        Subject: "Sample Excel",
        Author: "Fero",
        CreatedDate: new Date(),
      };

      wb.SheetNames.push("Status Mapping Sheet");
      wb.Sheets["Status Mapping Sheet"] = this.getSheetData();

      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      let blob = new Blob([this.s2ab(wbout)], {
        type: "application/octet-stream",
      });

      this.download(blob);
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    download(blob) {
      let url = window.URL.createObjectURL(blob);

      let a = document.createElement("a");
      let fileName = "Status Mapping" + " " + "Excel File.xlsx";
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    getSheetData() {
      let ws = XLSX.utils.json_to_sheet([{}]);
      ws = XLSX.utils.json_to_sheet(this.bulkStatusMapSampleData);
      return ws;
    },

    submitData() {
      bus.$emit("showLoader", true);
      let payload = [];

      this.fullData.map((data) => {
        data.organization_vendor = this.organizationVendorID;
      });

      payload = [...this.fullData];

      this.$api.organizationVendor
        .bulkUploadOrganizationVendorStatus(this.organizationVendorID, payload)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.selectedFile = null;
          bus.$emit("showToastMessage", {
            message: "File Uploaded Successfully",
            color: "success",
          });
          this.clearDialogData();
          this.showOrganizationVendorBulkStatusMappingDialog = false;
          this.$emit("updateList");
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.dataWithError = [];
          const errorObj = err;
          if (errorObj && errorObj.data.non_field_errors) {
            this.nonFieldErrors = errorObj.data.non_field_errors;
          } else {
            this.errors = errorObj.data;
            this.dataWithError = this.fullData;
            this.generateErrorRow(this.errors);
          }
        });
    },
    resetForm() {
      const form = this.$refs.bulkStatusMappingForm;
      if (form) {
        form.reset();
      }
      this.nonFieldErrors = [];
      this.errors = [];
      this.clearDialogData();
    },
    clearDialogData() {
      this.selectedFile = null;
      this.file = null;
      this.fullData = [];
      this.nonFieldErrors = [];
      this.rowData = [];
      this.columnDefs = [];
      this.requiredFields = [];
      this.allFields = [];
    },
    generateErrorRow(errorRow) {
      let errorIndexRow = errorRow.map((item) => {
        return Object.keys(item).length > 0 ? true : false;
      });

      this.columnDefs = Object.keys(this.errors[0]).map((item) => {
        return {
          headerName: item.replace(/_/g, " ").toUpperCase(),
          field: item,
          editable: "oldIndex" != item ? true : false,
          hide: "oldIndex" == item || "formError" == item ? true : false,
          valueParser: this.dataChange,
          cellStyle: this.cellStyle,
        };
      });

      this.columnDefs.unshift({
        headerName: "Errors",
        pinned: "left",
        width: 200,
        cellRenderer: "BulkUploadErrorTooltip",
      });

      this.errors = this.errors.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });

      this.fullData = this.errors.filter((item, index) => {
        if (errorIndexRow[index]) {
          return item;
        }
      });

      this.dataWithError = this.dataWithError.map((row, index) => {
        let error = this.errors[index];
        return { ...row, formError: error };
      });
    },
    dataChange(param) {
      let field = param.colDef.field;
      let localError = this.errors[param.node.rowIndex];
      if (param && param.newValue != param.oldValue) {
        delete localError[field];
      }
      let newIndex = this.dataWithError.findIndex(
        (item) => item.oldIndex == param.data.oldIndex
      );
      this.dataWithError[newIndex] = param.data;

      if (
        localError.non_field_errors ||
        (Object.keys(localError) && Object.keys(localError).length == 0)
      ) {
        setTimeout(() => {
          this.errors.splice(param.node.rowIndex, 1);
          this.rowData.splice(param.node.rowIndex, 1);
        }, 200);
      }

      return param.newValue;
    },
    cellStyle(param) {
      if (
        param &&
        param.colDef &&
        param.colDef.field &&
        this.errors[param.rowIndex].hasOwnProperty(param.colDef.field)
      ) {
        return {
          backgroundColor: "red",
        };
      } else {
        return {
          backgroundColor: "white",
        };
      }
    },
  },
  setup() {
    let stringCompare = compareTwoStrings;
    return { stringCompare };
  },
};
</script>

<style></style>
