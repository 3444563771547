<template>
  <BaseDialog
    v-model="showOrganizationForm"
    :title="`${editMode ? 'Edit' : 'Add'} Organization`"
    width="50vh"
    @closeDialog="resetForm(), (showOrganizationForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="organizationForm"
        id="organizationForm"
        name="organizationForm"
      >
        <v-row no-gutters>
          <v-col cols="12" class="pb-3">
            <v-text-field
              outlined
              type="url"
              hide-details="auto"
              dense
              label="Organization Code*"
              :rules="[(val) => !!val || 'Organization Code is required']"
              :error-messages="
                formErrors && formErrors.code ? formErrors.code : ''
              "
              v-model="organizationDetails.code"
              @input="
                formErrors && formErrors.code ? delete formErrors.code : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-text-field
              outlined
              type="url"
              hide-details="auto"
              dense
              label="Organization Name*"
              :rules="[(val) => !!val || 'Organization Name is required']"
              :error-messages="
                formErrors && formErrors.name ? formErrors.name : ''
              "
              v-model="organizationDetails.name"
              @input="
                formErrors && formErrors.name ? delete formErrors.name : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Organization URL*"
              :rules="[(val) => !!val || 'Organization URL is required']"
              :error-messages="
                formErrors && formErrors.url ? formErrors.url : ''
              "
              v-model="organizationDetails.url"
              @input="formErrors && formErrors.url ? delete formErrors.url : ''"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-text-field
              class="pr-2"
              outlined
              hide-details="auto"
              dense
              readonly
              label="Api Key*"
              v-model="organizationDetails.api_key"
              :error-messages="
                formErrors && formErrors.api_key ? formErrors.api_key : ''
              "
              :append-icon="copied ? 'mdi-check' : 'mdi-content-copy'"
              @click:append="copyApiValue(organizationDetails.api_key)"
            />
            <v-btn color="secondary" small @click="generateApiKey">
              Generate Key
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn color="primary" v-if="!editMode" small @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()">
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
export default {
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    organizationID: {
      type: Number,
    },
  },
  data() {
    return {
      copied: false,
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      organizationDetails: {
        api_key: "",
      },
    };
  },

  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    showOrganizationForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showOrganizationForm(val) {
      if (val) {
        if (this.editMode && this.organizationID) {
          this.getOrganizationObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    getOrganizationObject() {
      this.$api.organization
        .getOrganizationObject(this.organizationID)
        .then((res) => {
          this.organizationDetails = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.organizationForm;
      if (form) {
        form.reset();
      }
      this.copied = false;
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.organizationDetails,
      };
      if (!this.editMode) {
        this.$api.organization
          .addOrganization(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization created!",
              color: "success",
            });
            this.resetForm();
            this.showOrganizationForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.organization
          .editOrganization({
            id: this.organizationDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization details updated!",
              color: "success",
            });
            this.resetForm();
            this.showOrganizationForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            this.organizationForm = false;
          });
      }
    },
    generateApiKey() {
      if (this.formErrors && this.formErrors.api_key) {
        delete this.formErrors.api_key;
      }
      this.$api.organization
        .generateApiKey()
        .then((res) => {
          this.copied = false;
          this.organizationDetails.api_key = res.data.api_key;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    copyApiValue(value) {
      if (value) {
        navigator.clipboard.writeText(value);
        this.copied = true;
      }
    },
  },
};
</script>

<style>
</style>