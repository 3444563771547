import { integrationPartner } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getIntegrationPartnerList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(integrationPartner.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addIntegrationPartner(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(integrationPartner.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editIntegrationPartner(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${integrationPartner.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewIntegrationPartnerDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integrationPartner.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getIntegrationPartnerObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integrationPartner.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getIntegrationPartnerOptions(params) {
    return new Promise((resolve, reject) => {
      axios
        .options(integrationPartner.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getIntegrationPartnerSelectionList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integrationPartner.base}select/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  //integration Partner Api start
  getIntegrationPartnerApiList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integrationPartner.integrationPartnerApi}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addIntegrationPartnerApi(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(integrationPartner.integrationPartnerApi, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getIntegrationPartnerApiObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integrationPartner.integrationPartnerApi}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editIntegrationPartnerApi(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${integrationPartner.integrationPartnerApi}${id}/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  applySchemaChangesToClient(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${integrationPartner.integrationPartnerApi}${id}/apply_changes_to_client/`,
          payload
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
