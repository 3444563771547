import Vue from "vue";
import VueRouter from "vue-router";

import DefaultLayout from "../layouts/DefaultLayout.vue";
import MainLayout from "../layouts/MainLayout.vue";

import Login from "../pages/Login/Login.vue";
import mainRoutes from "@/router/routes";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
      },
    ],
  },
  {
    path: "/",
    component: MainLayout,
    children: mainRoutes,
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "hash",
  routes: routes,
});

export default router;
