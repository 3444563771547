<template>
  <v-row>
    <v-col cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="editDetails()">
            <v-icon medium color="green">mdi-pencil</v-icon>
          </v-btn>
        </template>
        Update Details
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewDetails()">
            <v-icon color="primary" medium>mdi-eye</v-icon>
          </v-btn>
        </template>
        View Details
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            v-on="on"
            @click="openOrganizationApiPage()"
          >
            <v-icon color="black" large> mdi-api </v-icon>
          </v-btn>
        </template>
        <span> View API</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            v-on="on"
            @click="openOrganizationVendorPage()"
          >
            <v-icon medium color="primary">mdi-alpha-v-circle</v-icon>
          </v-btn>
        </template>
        <span>View Vendors</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="openConfiguration()">
            <v-icon medium color="black">mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>API Configuration</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            v-on="on"
            @click="copyOrganizationApiKey()"
          >
            <v-icon medium color="primary">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy API Key</span><br />
        <span>{{ params.data.api_key }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
  
<script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    openOrganizationApiPage() {
      this.params.context.parentComponent.openOrganizationApiPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    openOrganizationVendorPage() {
      this.params.context.parentComponent.openOrganizationVendorPage(
        this.params.data.id,
        this.params.data.name
      );
    },
    openConfiguration() {
      this.params.context.parentComponent.openConfiguration(
        this.params.data.id,
        this.params.data.name
      );
    },
    copyOrganizationApiKey() {
      this.params.context.parentComponent.copyApiKey(this.params.data.api_key);
    },
  },
};
</script>
  
  <style>
</style>