<template>
  <v-row class="ma-0">
    <v-col cols="12" class="relative-position">
      <v-card
        elevation="0"
        style="background-color: #f5f8fa"
        class="pa-1 list-box-Shadow"
      >
        <v-row no-gutters>
          <v-col cols="2" class="text-left d-flex align-center">
            <h3 class="text-uppercase text--primary">Vendor</h3>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card elevation="0" style="height: 800px;">
        <v-row>
          <v-col cols="2" v-for="vendor in vendorList" :key="vendor.name">
            <v-card elevation="1" ref="vendorList">
              <v-card-title class="grey lighten-2 primary--text">
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex justify-center pa-2">
                    <v-icon>mdi-account</v-icon>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    {{ vendor.name }}
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  x-small
                  text
                  color="primary"
                  @click="viewDetails(vendor.name)"
                >
                  View
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
  
<script>
import { bus } from "@/main";

export default {
  name: "Vendor",
  data() {
    return {
      vendorList: [],
    };
  },
  methods: {
    viewDetails(name) {
      this.$router.push({
        name: "vendor-details",
        params: { name: name },
      });
    },
    getVendorList() {
      bus.$emit("showLoader", true);
      this.$api.vendor
        .getVendorList()
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vendorList = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "No data found",
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.getVendorList();
  },
};
</script>