<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <!-- Details Card -->
        <v-card
          color="primary"
          theme="dark"
          variant="elevated"
          class="list-box-Shadow"
          v-if="Object.values(vendorDetails).length"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" v-for="details in basicDetails" :key="details">
                <v-row>
                  <v-col cols="12" class="text-caption text-white">
                    <span> {{ details.label }}: </span>
                    <span class="font-weight-bold text-capitalize">
                      {{ details.value }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Other Details -->
    <v-row no-gutters>
      <v-col cols="8" class="px-3">
        <v-row>
          <v-col cols="6">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="trackingDetailObject.Shipment.sender"
            >
              <v-card-title class="text-h6 py-1"> Sender Address </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in originAddress"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="trackingDetailObject.Shipment.receiver"
            >
              <v-card-title class="text-h6 py-1"> 
                Receiver Address
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in destinationAddress"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Events -->
      <v-col
        cols="4"
        v-if="trackingDetailObject && trackingDetailObject.ShipmentStatuses"
        class="px-3"
      >
        <v-card
          class="overflow-y-auto py-2"
          :style="`height: calc(100vh - 282px)`"
        >
          <v-card-title class="text-h6 py-0"> Shipment Statuses </v-card-title>
          <hr />
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="event in trackingDetailObject.ShipmentStatuses"
              :key="event"
              small
              fill-dot
            >
              <div class="row text-black no-gutters">
                <div class="col-12">
                  <div class="font-weight-normal text-capitalize">
                    <strong>{{ event.status }}</strong>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-caption">
                    <v-icon small>mdi-calendar-clock </v-icon>
                    {{ event.time }}
                  </div>
                </div>
                <div class="col-6" v-if="event.new_location">
                  <div class="text-caption">
                    <v-icon small>mdi-map-marker</v-icon>
                    {{ event.new_location }}
                  </div>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import { convertDateTime } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "SkipExpressAwbTrack",
  components: {
    BaseListLayout,
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicDetails() {
      return [
        {
          label: "Order Awb",
          value: this.vendorDetails.order_awb,
        },
        {
          label: "Reference Number",
          value: this.trackingDetailObject?.Shipment?.reference,
        },
        {
          label: "SKU",
          value: this.trackingDetailObject?.Shipment?.sku,
        },
        {
          label: "Origin",
          value: this.trackingDetailObject?.Shipment?.origin,
        },
        {
          label: "Destination",
          value: this.trackingDetailObject?.Shipment?.destination,
        },
        {
          label: "Parcel Quantity",
          value: this.trackingDetailObject?.Shipment?.parcel_quantity,
        },
        {
          label: "Parcel Weight",
          value: this.trackingDetailObject?.Shipment?.parcel_weight,
        },
        {
          label: "Payment Mode",
          value: this.trackingDetailObject?.Shipment?.payment_mode,
        },
        {
          label: "Service Id",
          value: this.trackingDetailObject?.Shipment?.service_id,
        },
        {
          label: "Description",
          value: this.trackingDetailObject?.Shipment?.description,
        },
      ];
    },
    originAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.Shipment?.sender?.name,
        },
        {
          label: "Address",
          value: this.trackingDetailObject?.Shipment?.sender?.address,
        },
        {
          label: "Zipcode",
          value: this.trackingDetailObject?.Shipment?.sender?.zip_code,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.Shipment?.sender?.phone,
        },
        {
          label: "Email",
          value: this.trackingDetailObject?.Shipment?.sender?.email,
        },
      ];
    },
    destinationAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.Shipment?.receiver?.name,
        },
        {
          label: "Address",
          value: this.trackingDetailObject?.Shipment?.receiver?.address,
        },
        {
          label: "Zipcode",
          value: this.trackingDetailObject?.Shipment?.receiver?.zip_code,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.Shipment?.receiver?.phone,
        },
        {
          label: "Phone2",
          value: this.trackingDetailObject?.Shipment?.receiver?.phone2,
        },
        {
          label: "Email",
          value: this.trackingDetailObject?.Shipment?.receiver?.email,
        },
        {
          label: "Signature",
          value: this.trackingDetailObject?.Shipment?.receiver?.signature,
        },
        {
          label: "Photo",
          value: this.trackingDetailObject?.Shipment?.receiver?.photo,
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
  },
};
</script>

<style>
</style>