<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <!-- Details Card -->
        <v-card
          color="primary"
          theme="dark"
          variant="elevated"
          class="list-box-Shadow"
          v-if="Object.values(vendorDetails).length"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" v-for="(details, i) in basicDetails" :key="i">
                <v-row>
                  <v-col cols="12" class="text-caption text-white">
                    <span> {{ details.label }}: </span>
                    <span class="font-weight-bold text-capitalize">
                      {{ details.value }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Other Details -->
    <v-row no-gutters>
      <v-col cols="8" class="px-3">
        <v-row>
          <v-col cols="12">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="Object.values(shipmentAddress).length"
            >
              <v-card-title class="text-h6 py-1">
                Shipment Address
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="(details, i) in shipmentAddress"
                    :key="i"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Events -->
      <v-col
        cols="4"
        v-if="shipmentActivity && shipmentActivity.length"
        class="px-3"
      >
        <v-card
          class="overflow-y-auto py-2"
          :style="`height: calc(100vh - 282px)`"
        >
          <v-card-title class="text-h6 py-0"> Shipment Activity </v-card-title>
          <hr />
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="(event, i) in shipmentActivity"
              :key="i"
              small
              fill-dot
            >
              <div class="row text-black no-gutters">
                <div class="col-12">
                  <div class="font-weight-normal text-capitalize">
                    <strong>{{ event.details }}</strong>
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-caption">
                    {{ event.status }}
                  </div>
                </div>
                <div class="col-6">
                  <div class="text-caption">
                    <v-icon small>mdi-calendar-clock </v-icon>
                    {{ event.time }}
                  </div>
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import { convertDateTime } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "SkipExpressAwbTrack",
  components: {
    BaseListLayout,
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    trackResponseShipment() {
      return this.trackingDetailObject.Shipment;
    },
    shipmentActivity() {
      return this.trackResponseShipment.Activity;
    },
    basicDetails() {
      return [
        {
          label: "Order Awb",
          value: this.vendorDetails.order_awb,
        },
        {
          label: "Vendor AWB Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Last Update Sent To Org At",
          value: this.vendorDetails.last_update_sent_to_org_at,
        },
        {
          label: "Last Update Time",
          value: this.vendorDetails?.last_updated_timestamp
            ? convertDateTime(this.vendorDetails?.last_updated_timestamp)
            : null,
        },
        {
          label: "AWB Number",
          value: this.trackResponseShipment.awb_number,
        },
        {
          label: "Current Status",
          value: this.trackResponseShipment.current_status,
        },
        {
          label: "Status Datetime",
          value: this.trackResponseShipment.status_datetime,
        },
      ];
    },
    shipmentAddress() {
      return [
        {
          label: "Address",
          value: this.trackResponseShipment.ShipmentAddress.address,
        },
        {
          label: "City",
          value: this.trackResponseShipment.ShipmentAddress.city,
        },
        {
          label: "Country",
          value: this.trackResponseShipment.ShipmentAddress.country,
        },
      ];
    },
  },
};
</script>

<style>
</style>