<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="organizationWebhookList"
        ref="incomingWebhookList"
        title="Incoming Webhook Logs"
        searchable
        :table-header="columns"
        :table-data="tableData"
        :context="context"
        :total="totalItems"
        localStorageKey="incomingWebhookLogsColumns"
        @selectionChanged="selectionChanged"
        @getList="getOrganizationWebhookLogsList"
      >
        <template #leftFilterSlot>
          <v-btn
            color="primary"
            x-small
            fab
            depressed
            class="ma-1 rounded-lg"
            @click="showFilterDialog = true"
            ><v-icon> mdi-filter</v-icon>
          </v-btn>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Webhook"
            v-model="showExportDialog"
            request_type="Incoming Webhook"
          >
          </ExportReportDialog>
          <WebhookLogsFilterDialog
            title="incoming_webhook_logs_"
            v-model="showFilterDialog"
            @applyFilters="applyFilters"
          >
          </WebhookLogsFilterDialog>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>
            Export Data
          </v-btn>
          <v-btn
            :disabled="selectedItems.length == 0"
            small
            color="primary"
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn>
        </template>
      </BaseListLayout>
    </v-col>
    <v-col
      cols="4"
      v-if="hasOrgWebhookLogsDetails"
      class="pt-0 left-devider-shadow overflow-hidden-y"
    >
      <BaseLogsDetailsLayout
        :trace_id="orgWebhookLogsObject.trace_id"
        v-model="hasOrgWebhookLogsDetails"
        :details="details"
        :detailsObject="orgWebhookLogsObject"
        @closeDetails="hasOrgWebhookLogsDetails = false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import WebhookLogsFilterDialog from "@/components/common/WebhookLogsFilterDialog.vue";
import BaseLogsDetailsLayout from "@/components/BaseLayoutComponents/BaseLogsDetailsLayout.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import { bus } from "@/main";

export default {
  name: "OrganizationWebhookLogs",
  components: {
    BaseListLayout,
    ActionsButton,
    CellRenderer,
    ExportReportDialog,
    BaseLogsDetailsLayout,
    WebhookLogsFilterDialog,
  },
  data() {
    return {
      totalItems: 0,
      canEdit: false,
      showExportDialog: false,
      showFilterDialog: false,
      hasOrgWebhookLogsDetails: false,
      tableData: [],
      orgWebhookLogsObject: {},

      selectedItems: [],

      columns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          maxWidth: 50,
        },
        {
          headerName: "Trace ID",
          field: "trace_id",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Received On",
          field: "created",
          minWidth: 200,
          autoHeight: true,
          cellRenderer: "CellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 180,
          autoHeight: true,
        },
        { headerName: "Status", field: "status" },
        { headerName: "Response Code", field: "status_code" },
        {
          headerName: "Success",
          field: "is_success",
          cellRenderer: "CellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    details() {
      return [
        {
          label: "Status Code",
          value: this.orgWebhookLogsObject.status_code,
        },
        {
          label: "Organization Name",
          value: this.orgWebhookLogsObject.organization_name,
        },
        {
          label: "Endpoint",
          value: this.orgWebhookLogsObject.endpoint,
        },
      ];
    },
  },
  methods: {
    getOrganizationWebhookLogsList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("incoming_webhook_logs_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }

      this.$api.inboundLogs
        .getOrganizationWebhookLogsList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    viewDetails(id) {
      this.$api.inboundLogs
        .getOrganizationWebhookLogsObject(id)
        .then((res) => {
          this.orgWebhookLogsObject = res.data;
          this.hasOrgWebhookLogsDetails = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    selectionChanged(selectedRows) {
      this.selectedItems = selectedRows;
    },
    bulkResend() {
      bus.$emit("showLoader", true);
      let selectedItemsIds = [];
      this.selectedItems.map((item) => {
        selectedItemsIds.push(item.id);
      });
      this.$api.inboundLogs
        .bulkResendOrganizationWebhookLog({ request_ids: selectedItemsIds })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.$refs.incomingWebhookList.updateList();
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err?.data?.detail,
            color: "red",
          });
        });
    },
    applyFilters() {
      this.$refs.incomingWebhookList.refreshList();
    },
  },
};
</script>
