
<template>
  <v-menu
    :close-on-content-click="false"
    max-width="400px"
    nudge-left="345"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" fab dark x-small color="#112f4f">
        <v-badge
          :color="totalNotifications == 0 ? '' : '#17b7d0'"
          :content="totalNotifications"
        >
          <v-icon color="white"> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card width="400px" elevation="0">
      <v-card-title class="pt-4 pb-2 body-1 d-flex justify-space-between">
        <span>Notifications</span>
      </v-card-title>

      <v-card-text
        elevation-0
        style="max-height: 350px"
        class="pa-0 overflow-y-auto overflow-x-hidden"
        @scroll="handleScroll()"
        ref="notificationRef"
      >
        <div
          v-if="userNotifications.length == 0"
          class="d-flex justify-center py-2"
        >
          <span>No new Notifications!</span>
        </div>
        <div v-for="(notification, i) in userNotifications" :key="i">
          <v-row class="pl-4 pa-0 my-1 notification">
            <v-col cols="12" class="pa-0 ma-0">
              <hr style="opacity: 0.4" />
            </v-col>

            <v-col cols="9" class="d-flex align-center">
              <v-row no-gutters>
                <v-col cols="12" class="text-black">
                  <span> {{ notification.title }} </span>
                </v-col>

                <v-col cols="12" class="text-caption">
                  {{ notification.message }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <v-btn
                small
                icon
                @click.stop="markAsRead(notification)"
                class="primary--text"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-btn
                v-if="notification.link"
                small
                icon
                @click.stop="downloadReport(notification)"
                class="primary--text"
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { bus } from "@/main";

export default {
  data() {
    return {
      notificationInterval: null,
      userNotifications: [],
      totalNotifications: null,
    };
  },
  methods: {
    handleScroll() {
      let elem = this.$refs.notificationRef;
      if (elem == null) {
        return false;
      } else if (
        Math.ceil(elem.offsetHeight + elem.scrollTop) == elem.scrollHeight
      ) {
        this.getUserNotificationList(false);
      }
    },

    getUserNotificationList(reload) {
      let params;
      if (!reload) {
        params = { offset: this.userNotifications.length };
      } else {
        params = { offset: 0 };
      }

      if (reload || this.totalNotifications != this.userNotifications.length) {
        this.$api.userNotification
          .getUserNotificationList(params)
          .then((res) => {
            if (!reload) {
              this.userNotifications = [
                ...this.userNotifications,
                ...res.data.results,
              ];
            } else {
              this.userNotifications = res.data.results;
              this.totalNotifications = res.count;
            }
          })

          .catch((err) => {
            if (err.message) {
              bus.$emit("showToastMessage", {
                message: err.message,

                color: "error",
              });
            } else {
              bus.$emit("showToastMessage", {
                message: "Error Fetching data!",

                color: "error",
              });
            }
          });
      }
    },
    markAsRead(notification) {
      let payload = {
        is_read: true,
        read_on: new Date().toISOString(),
        title: notification.title,
        message: notification.message,
        user: notification.user,
      };
      this.$api.userNotification
        .markAsSeenUserNotification(notification.id, payload)
        .then(() => {
          this.getUserNotificationList(true);
        })
        .catch((err) => {
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Error Fetching data!",
              color: "error",
            });
          }
        });
    },
    downloadReport(notification) {
      this.$api.report
        .downloadReport(notification.report_id)
        .then((res) => {
          let url = window.URL.createObjectURL(new Blob([res.data]));

          let a = document.createElement("a");
          let fileName = `report-${notification.report_id}.xlsx`;

          a.href = url;
          a.download = fileName;
          a.target = "_blank";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err?.data?.message,
            color: "red",
          });
        });
    },
  },
  mounted() {
    this.getUserNotificationList(true);
    this.notificationInterval = setInterval(() => {
      this.getUserNotificationList(true);
    }, 120000);
  },
  beforeDestroy() {
    clearInterval(this.notificationInterval);
    this.userNotifications = [];
    this.totalNotifications = null;
  },
};
</script>
<style scoped>
.notification:hover {
  background-color: #ebf7fc !important;
}
</style>