export function handleResponse(res) {
  let obj = {};
  if (res.data && typeof res.data == typeof {}) {
    obj.data = res.data;
    if (res.data.data) {
      obj.data = res.data.data;
    }
    if (
      res.data.count != null ||
      res.data.count != undefined ||
      res.data.count != ""
    ) {
      obj.count = res.data.count;
    }
  } else {
    obj = res;
  }
  return obj;
}

export function handleError(err) {
  let obj = {};
  if (err.response && err.response.data) {
    obj.data = err.response.data;
    if (obj.data.error) {
      obj.data = err.response.data.error;
    }
  } else {
    obj.response = err.response;
  }
  // obj.status = err.response.status;
  return obj;
}

export function generatedFormData(obj, skipKeys = []) {
  let formData = new FormData();
  if (obj && typeof obj == "object") {
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        value = value.join(",");
      }
      if (key == "coordinates") {
        value = JSON.stringify(value);
      }
      if (skipKeys.indexOf(key) == -1) {
        if (value != "" && value != null) {
          formData.append(key, value);
        }
      } else {
        formData.append(key, null);
      }
    });
  }
  return formData;
}

export function convertToBlobAndDownload(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/octetstream" })
  );
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function returnToday() {
  let today = new Date();
  return getDateTime(today);
}

export function getUserProfile() {
  let userData = localStorage.getItem("profile");
  if (userData) {
    userData = JSON.parse(localStorage.getItem("profile"));
  }
  return userData;
}

export function getAllUserPreferences() {
  let preferences = localStorage.getItem("userPreferences");
  if (preferences) {
    return JSON.parse(preferences);
  }
  return {};
}

export function getUserPreferences(key) {
  let preferences = getAllUserPreferences();
  if (preferences && preferences[key]) {
    return preferences[key];
  }
  return [];
}

export function convertDateTime(value) {
  const date = new Date(value);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // hour12: false, // Use 24-hour format
  };
  const time = new Intl.DateTimeFormat("en-US", options).format(date);
  return time;
}

export function formatLastUpdated(value) {
  const now = new Date();
  const lastUpdated = new Date(value);
  const diffInMilliseconds = now - lastUpdated;

  const seconds = Math.floor(diffInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
}
