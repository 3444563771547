import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"d-flex justify-end align-center py-2",attrs:{"cols":"12"}},[_c('div',[_c(VSelect,{staticClass:"mr-2 rounded-lg",attrs:{"label":"Page Size","dense":"","outlined":"","hide-details":"","items":_vm.itemsPerPageValues,"menu-props":{ offsetY: true }},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c(VBtn,{staticClass:"rounded-lg elevation-0",attrs:{"disabled":_vm.pageNo == 1,"x-small":"","fab":"","text":""},on:{"click":function($event){return _vm.previousPage()}}},[_c(VIcon,[_vm._v("mdi-arrow-left")])],1),_c('span',{staticClass:"pa-2"},[_c('span',[_vm._v(_vm._s(_vm.pageNo))]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.totalPages == 0 ? 1 : _vm.totalPages))])]),_c(VBtn,{staticClass:"rounded-lg elevation-0",attrs:{"x-small":"","fab":"","text":"","disabled":_vm.pageNo == _vm.totalPages || _vm.totalPages == 0},on:{"click":function($event){return _vm.nextPage()}}},[_c(VIcon,[_vm._v("mdi-arrow-right")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }