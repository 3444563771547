<template>
  <router-view></router-view>
</template>
 
<script>
export default {
  name: "VendorShipments",
};
</script>
 
<style></style>
 