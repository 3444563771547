<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <!-- <v-card
          elevation="0"
          style="background-color: #f5f8fa"
          class="list-box-Shadow light_background"
        >
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="6" class="d-flex align-center justify-start pb-4">
                <h3
                  class="text-uppercase text-h6 text_color--text font-weight-bold"
                >
                  Vendor AWB Track
                </h3>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-text-field
                  v-model.trim="searchAWBs"
                  outlined
                  dense
                  label="Search AWBs here.."
                  clearable
                  append-icon="mdi-magnify"
                  @keydown.enter="getVendorAwbDetailsObject()"
                  @click:append="getVendorAwbDetailsObject()"
                  @click:clear="clearData()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title v-if="searchAWBs.length == 0" style="margin-top: 250px">
            <v-row>
              <v-col cols="12">
                <span
                  class="text-h5 text_color--text font-italic d-flex align center justify-center"
                >
                  Please Seach AWB and get details here...
                </span>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card> -->

        <!-- Details Card -->
        <v-card
          color="primary"
          theme="dark"
          variant="elevated"
          class="list-box-Shadow"
          v-if="Object.values(vendorDetails).length"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="3" v-for="details in basicDetails" :key="details">
                <v-row>
                  <v-col cols="12" class="text-caption text-white">
                    <span> {{ details.label }}: </span>
                    <span class="font-weight-bold text-capitalize">
                      {{ details.value }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Other Details -->
    <v-row no-gutters>
      <v-col cols="8" class="px-3">
        <v-row>
          <v-col cols="6">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="Object.values(trackingDetails.origin).length"
            >
              <v-card-title class="text-h6 py-1"> Origin Address </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in originAddress"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="Object.values(trackingDetails.destination).length"
            >
              <v-card-title class="text-h6 py-1">
                Destination Address
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in destinationAddress"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="trackingDetails.proofOfDelivery"
            >
              <v-card-title class="text-h6 py-1">
                Proof Of Delivery
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col cols="12" v-for="details in podDetails" :key="details">
                    <v-row>
                      <v-col cols="2" class="text-left">
                        <span> {{ details.label }}: </span>
                      </v-col>
                      <v-col cols="8" class="text-left">
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                      <br />
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- Events -->
      <v-col
        cols="4"
        v-if="trackingDetails && trackingDetails.events"
        class="px-3"
      >
        <v-card
          class="overflow-y-auto py-2"
          :style="`height: calc(100vh - 282px)`"
        >
          <v-card-title class="text-h6 py-0"> Events </v-card-title>
          <hr />
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="event in trackingDetails.events"
              :key="event"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal">
                  <strong>{{ event.description }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ convertEventTimeStamp(event.timestamp) }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.location.address.addressLocality }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import { convertDateTime } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "DHLAwbTrack",
  components: {
    BaseListLayout,
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetails: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicDetails() {
      return [
        {
          label: "Organization AWB",
          value: this.vendorDetails.order_awb,
        },
        {
          label: "Vendor AWB",
          value: this.vendorDetails.vendor_awb,
        },
        {
          label: "Vendor AWb Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Tracking Number",
          value: this.vendorDetails.track_no,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Organization At",
          value: convertDateTime(this.vendorDetails.last_update_sent_to_org_at),
        },
        {
          label: "Last Update Received At",
          value: convertDateTime(this.vendorDetails.last_update_received_at),
        },
        {
          label: "System Status",
          value: this.vendorDetails.organization_awb_status
            ? this.vendorDetails.organization_awb_status
            : "N/A",
        },
      ];
    },
    originAddress() {
      return [
        {
          label: "Country Code",
          value: this.trackingDetails?.origin?.address?.countryCode,
        },
        {
          label: "Postal Code",
          value: this.trackingDetails?.origin?.address?.postalCode,
        },
        {
          label: "Address Locality",
          value: this.trackingDetails?.origin?.address?.addressLocality,
        },
      ];
    },
    destinationAddress() {
      return [
        {
          label: "Country Code",
          value: this.trackingDetails?.destination?.address?.countryCode,
        },
        {
          label: "Postal Code",
          value: this.trackingDetails?.destination?.address?.postalCode,
        },
        {
          label: "Address Locality",
          value: this.trackingDetails?.destination?.address?.addressLocality,
        },
      ];
    },
    podDetails() {
      return [
        {
          label: "Document Url",
          value: this.trackingDetails.details?.proofOfDelivery?.documentUrl,
        },
        {
          label: "Signature Url",
          value: this.trackingDetails.details?.proofOfDelivery?.signatureUrl,
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
  },
};
</script>

<style>
</style>