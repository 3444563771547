import { userNotification } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getUserNotificationList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userNotification.base}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  markAsSeenUserNotification(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${userNotification.base}${id}/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
