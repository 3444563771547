<template>
  <BaseDialog
    v-model="showWebhookConfigurationForm"
    title="Webhook Configuration"
    width="65%"
    @closeDialog="showWebhookConfigurationForm = false"
  >
    <template #dialogContent>
      <v-row>
        <v-col :cols="12" lg="4">
          <v-tabs vertical v-model="currentTab">
            <v-tab v-for="tab in tabs" :key="tab.key"> {{ tab.value }} </v-tab>
          </v-tabs>
        </v-col>
        <v-col :cols="12" lg="8" class="left-devider-shadow">
          <v-row no-gutters>
            <v-col
              cols="12"
              v-if="
                formErrors.non_field_errors &&
                formErrors.non_field_errors.length > 0
              "
            >
              <v-alert dense type="error">
                <v-list
                  dense
                  style="background: inherit !important"
                  v-for="(error, i) in formErrors.non_field_errors"
                  :key="i"
                  class="pa-0"
                >
                  <v-list-item dense>
                    <span>{{ error }}</span>
                  </v-list-item>
                </v-list>
              </v-alert>
            </v-col>
          </v-row>
          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <v-row no-gutters class="px-3 mt-2">
                <v-col cols="12" class="pb-3">
                  <BaseSelect
                    :required="true"
                    label="Select Status"
                    :isClearable="true"
                    :itemsList="possibleOrderStatusList"
                    item-text="organization_status"
                    :multiple="true"
                    :return-object="true"
                    v-model="webhookConfigForm.client_webhook_statuses"
                    :error-messages="
                      formErrors && formErrors.client_webhook_statuses
                        ? formErrors.client_webhook_statuses
                        : ''
                    "
                    @change="
                      formErrors && formErrors.client_webhook_statuses
                        ? delete formErrors.client_webhook_statuses
                        : ''
                    "
                  />
                </v-col>
              </v-row>
              <v-card height="600px" class="overflow-y-auto" elevation="0">
                <v-card-text>
                  <v-row
                    class="mt-3"
                    no-gutters
                    v-for="(
                      status, i
                    ) in webhookConfigForm.client_webhook_statuses"
                    :key="i"
                  >
                    <v-col cols="6">
                      <v-text-field
                        disabled
                        outlined
                        dense
                        hide-details="auto"
                        label="Default Label"
                        v-model="status.organization_status"
                      />
                    </v-col>
                    <v-col cols="6" class="pl-4">
                      <v-text-field
                        outlined
                        hide-details="auto"
                        dense
                        label="Custom Label"
                        v-model="status.client_status"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card height="660px" class="overflow-y-auto" elevation="0">
                <v-card-text>
                  <v-row class="mt-3" no-gutters>
                    <v-col cols="12">
                      <JsonEditorVue
                        :mode="'code'"
                        v-model="webhookConfigForm.headers"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-row no-gutters class="px-3 mt-2">
                <v-col cols="12">
                  <BaseSelect
                    :required="true"
                    label="Select Fields"
                    :isClearable="true"
                    :itemsList="fieldList"
                    item-text="key"
                    :multiple="true"
                    :return-object="true"
                    v-model="webhookConfigForm.clients_webhook_fields"
                    :error-messages="
                      formErrors && formErrors.clients_webhook_fields
                        ? formErrors.clients_webhook_fields
                        : ''
                    "
                    @change="
                      formErrors && formErrors.clients_webhook_fields
                        ? delete formErrors.clients_webhook_fields
                        : ''
                    "
                  />
                </v-col>
              </v-row>
              <v-card height="600px" class="overflow-y-auto" elevation="0">
                <v-card-text>
                  <v-row
                    class="mt-3"
                    no-gutters
                    v-for="(
                      field, i
                    ) in webhookConfigForm.clients_webhook_fields"
                    :key="i"
                  >
                    <v-col cols="6">
                      <v-text-field
                        disabled
                        outlined
                        hide-details="auto"
                        dense
                        label="Default Label"
                        v-model="field.key"
                      />
                    </v-col>
                    <v-col cols="6" class="pl-4">
                      <v-text-field
                        outlined
                        hide-details="auto"
                        dense
                        label="Custom Label"
                        v-model="field.label_name"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card height="650px" class="overflow-y-auto" elevation="0">
                <v-card-text>
                  <v-row
                    class="mt-2"
                    no-gutters
                    v-for="(custom, i) in customFields"
                    :key="i"
                  >
                    <v-col cols="5" class="pa-1">
                      <v-text-field
                        outlined
                        hide-details="auto"
                        dense
                        label="Label Name"
                        clearable
                        v-model="custom.label_name"
                      />
                    </v-col>
                    <v-col cols="5" class="pa-1">
                      <v-text-field
                        class="pl-2"
                        outlined
                        hide-details="auto"
                        dense
                        label="Default Value"
                        clearable
                        v-model="custom.default_value"
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="d-flex align-center pa-1">
                      <v-btn
                        color="green"
                        class="ml-2"
                        depressed
                        small
                        @click="addItem"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                      <v-btn
                        color="red"
                        class="ml-2"
                        depressed
                        small
                        :disabled="i == 0"
                        @click="removeItem(i)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card height="650px" class="overflow-y-auto" elevation="0">
                <v-card-text>
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        outlined
                        hide-details="auto"
                        dense
                        label="Webhook URL"
                        clearable
                        v-model="webhookConfigForm.webhook_url"
                        :error-messages="formErrors && formErrors.webhook_url"
                        @change="delete formErrors.webhook_url"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <v-btn
        color="primary"
        :disabled="!isValid"
        class="rounded-lg"
        small
        @click="submitForm()"
      >
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseDialog, BaseSelect, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    clientID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      currentTab: null,
      tabs: [
        { key: "status", value: "Status Mapping" },
        { key: "header", value: "Header Mapping" },
        { key: "field", value: "Field Mapping" },
        { key: "metadata", value: "Additional MetaData" },
        { key: "webhookUrlUpdate", value: "Webhook URL Update" },
      ],
      webhookConfigForm: {},
      fieldList: [
        { key: "WayBillNumber", label_name: "WayBillNumber" },
        { key: "CustomerName", label_name: "CustomerName" },
        { key: "CustomerCode", label_name: "CustomerCode" },
        { key: "Status", label_name: "Status" },
        { key: "Message", label_name: "Message" },
        { key: "LastLocation", label_name: "LastLocation" },
        { key: "Updated", label_name: "Updated" },
        { key: "StatusCode", label_name: "StatusCode" },
      ],
      possibleOrderStatusList: [
        { organization_status: "Pending", client_status: "Pending" },
        { organization_status: "Created", client_status: "Created" },
        {
          organization_status: "Out For Pickup",
          client_status: "Out For Pickup",
        },
        {
          organization_status: "Ready For Pickup",
          client_status: "Ready For Pickup",
        },
        { organization_status: "Picked Up", client_status: "Picked Up" },
        {
          organization_status: "Partially Picked Up",
          client_status: "Partially Picked Up",
        },
        {
          organization_status: "Pickup Failed",
          client_status: "Pickup Failed",
        },
        {
          organization_status: "In Transit",
          client_status: "In Transit",
        },
        {
          organization_status: "Hold at Hub",
          client_status: "Hold at Hub",
        },
        {
          organization_status: "Out For Delivery",
          client_status: "Out For Delivery",
        },
        {
          organization_status: "Partially Delivered",
          client_status: "Partially Delivered",
        },
        { organization_status: "Delivered", client_status: "Delivered" },
        {
          organization_status: "Delivery Failed",
          client_status: "Delivery Failed",
        },
        {
          organization_status: "Out For Return",
          client_status: "Out For Return",
        },
        {
          organization_status: "Returned",
          client_status: "Returned",
        },
        {
          organization_status: "Return Failed",
          client_status: "Return Failed",
        },
        {
          organization_status: "Cancelled",
          client_status: "Cancelled",
        },
        {
          organization_status: "Lost",
          client_status: "Lost",
        },
        {
          organization_status: "Destroyed",
          client_status: "Destroyed",
        },
        {
          organization_status: "Return to Shipper",
          client_status: "Return to Shipper",
        },
      ],
      customFields: [{}],

      selectedFields: [],
    };
  },

  computed: {
    showWebhookConfigurationForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showWebhookConfigurationForm(val) {
      if (val) {
        if (this.clientID) {
          (this.fieldList = [
            { key: "WayBillNumber", label_name: "WayBillNumber" },
            { key: "CustomerName", label_name: "CustomerName" },
            { key: "CustomerCode", label_name: "CustomerCode" },
            { key: "Status", label_name: "Status" },
            { key: "Message", label_name: "Message" },
            { key: "LastLocation", label_name: "LastLocation" },
            { key: "Updated", label_name: "Updated" },
            { key: "StatusCode", label_name: "StatusCode" },
          ]),
            (this.possibleOrderStatusList = [
              { organization_status: "Pending", client_status: "Pending" },
              { organization_status: "Created", client_status: "Created" },
              {
                organization_status: "Out For Pickup",
                client_status: "Out For Pickup",
              },
              {
                organization_status: "Ready For Pickup",
                client_status: "Ready For Pickup",
              },
              { organization_status: "Picked Up", client_status: "Picked Up" },
              {
                organization_status: "Partially Picked Up",
                client_status: "Partially Picked Up",
              },
              {
                organization_status: "Pickup Failed",
                client_status: "Pickup Failed",
              },
              {
                organization_status: "In Transit",
                client_status: "In Transit",
              },
              {
                organization_status: "Hold at Hub",
                client_status: "Hold at Hub",
              },
              {
                organization_status: "Out For Delivery",
                client_status: "Out For Delivery",
              },
              {
                organization_status: "Partially Delivered",
                client_status: "Partially Delivered",
              },
              { organization_status: "Delivered", client_status: "Delivered" },
              {
                organization_status: "Delivery Failed",
                client_status: "Delivery Failed",
              },
              {
                organization_status: "Out For Return",
                client_status: "Out For Return",
              },
              {
                organization_status: "Returned",
                client_status: "Returned",
              },
              {
                organization_status: "Return Failed",
                client_status: "Return Failed",
              },
              {
                organization_status: "Cancelled",
                client_status: "Cancelled",
              },
              {
                organization_status: "Lost",
                client_status: "Lost",
              },
              {
                organization_status: "Destroyed",
                client_status: "Destroyed",
              },
              {
                organization_status: "Return to Shipper",
                client_status: "Return to Shipper",
              },
            ]);
          this.getClientWebHookConfig();
        }
      } else {
        this.currentTab = 0;
        this.webhookConfigForm.headers = {};
      }
    },
  },
  methods: {
    getClientWebHookConfig() {
      this.$api.client
        .getClientWebHookConfig(this.clientID)
        .then((res) => {
          this.webhookConfigForm = res.data;
          let list = [];
          let c_list = [];
          this.webhookConfigForm.clients_webhook_fields.forEach((f) => {
            if (f.default_value) {
              c_list.push(f);
            } else {
              list.push(f);
            }
          });
          this.webhookConfigForm.clients_webhook_fields = list;
          this.customFields = c_list;
          if (!c_list.length) {
            this.customFields = [{}];
          }

          this.selectedFields = list;
          this.selectedFields.map((fields) => {
            delete fields.id, delete fields.default_value;
          });
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    submitForm() {
      let form = this.webhookConfigForm;
      bus.$emit("showLoader", true);
      let data = {};

      if (this.currentTab == 0) {
        data.client_webhook_statuses = form.client_webhook_statuses;
      } else if (this.currentTab == 1) {
        data.headers = form.headers;
      } else if (this.currentTab == 2 || this.currentTab == 3) {
        this.customFields.forEach((c) => {
          if (c.label_name) {
            c.key = c.label_name;
          }
        });
        let fields = [];
        if (this.customFields.length == 1) {
          let values = Object.values(this.customFields[0]);
          if (values[0] && values[1] && values[2]) {
            fields = form.clients_webhook_fields.concat(this.customFields);
          } else {
            fields = form.clients_webhook_fields;
          }
        } else if (this.customFields.length >= 2) {
          fields = form.clients_webhook_fields.concat(this.customFields);
        }
        data.clients_webhook_fields = fields;
      } else if (this.currentTab == 4) {
        data.webhook_url = form.webhook_url;
      }

      let method =
        this.currentTab == 0
          ? "configureClientWebhookStatuses"
          : this.currentTab == 1
          ? "configureClientWebhookHeaders"
          : this.currentTab == 4
          ? "clientWebhookUrlUpdate"
          : "configureClientWebhookFields";

      this.$api.client[method](this.clientID, data)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
          this.showWebhookConfigurationForm = false;
          this.$emit("updateList");
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
          bus.$emit("showToastMessage", {
            message: err.data,
            color: "error",
          });
        });
    },
    addItem() {
      this.customFields.push({});
    },
    removeItem(index) {
      this.customFields.splice(index, 1);
    },
  },
};
</script>