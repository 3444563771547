<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        ref="clientList"
        name="ClientList"
        title="Client"
        searchable
        :table-header="columns"
        :table-data="clientList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        localStorageKey="clientColumns"
        @getList="getClientList"
      >
        <template #addButton>
          <v-btn color="primary" small depressed @click="addClient">
            Add Client
          </v-btn>
        </template>
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>
            Export Data
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <v-row class="pa-0 ma-0 d-flex">
            <v-col
              v-if="!isUserTypeOrganization"
              cols="3"
              class="pa-0 ma-0 d-flex"
            >
              <BaseSelect
                class="pl-2 rounded-pill"
                label="Organization"
                :isClearable="true"
                :itemsList="organizationList"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.organization"
                @change="applyFilter"
              />
            </v-col>
            <v-col cols="3" class="pa-0 ma-0 d-flex">
              <BaseSelect
                class="pl-2 rounded-pill"
                label="Integration Partner"
                :isClearable="true"
                :itemsList="integrationPartnerList"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.integration_partner"
                @change="applyFilter"
              />
            </v-col>
            <v-col cols="3" class="ml-2 pa-0 ma-0 d-flex">
              <BaseDatePickerInput
                v-model="filters.created"
                id="created"
                name="created"
                label="Created"
                class="rounded-pill"
                clearable
                @input="applyFilter"
              />
            </v-col>
          </v-row>
        </template>
        <template #dialogs>
          <ClientForm
            from="Client"
            ref="clientForm"
            v-model="showClientForm"
            :editMode="editMode"
            :clientID="clientID"
            @updateList="updateList"
          />
          <WebhookConfigurationForm
            v-model="showClientConfigurationForm"
            :clientID="clientID"
            @updateList="updateList"
          />
          <ExportReportDialog
            title="Client Logs"
            v-model="showExportDialog"
            request_type="Client"
          />
          <Logs
            v-model="showLogsDialog"
            logTitle="Client Configuration"
            :logHeight="
              (style = 'height:calc(71.5vh)!important; overflow-x: hidden;')
            "
            :logs="clientLogs"
          />
        </template>
      </BaseListLayout>
      <ClientDetails
        v-model="showClientDetails"
        :clientID="clientID"
        @closeDetails="showClientDetails = false"
      />
      <!-- Enable Disable Webhook Dialogs Start -->
      <BaseDialog
        title="Disable Webhook"
        v-model="showDisableDialog"
        max-width="400"
      >
        <template #dialogContent>
          <span class="text-lg-subtitle-1 font-weight-bold">
            Are you sure you want to disable webhook ?
          </span>
        </template>
        <template #actions>
          <v-btn color="error" small @click="showDisableDialog = false">
            Cancel
          </v-btn>
          <v-btn color="success" small @click="enableDisableWebhook('disable')">
            Yes
          </v-btn>
        </template>
      </BaseDialog>
      <BaseDialog
        title="Enable Webhook"
        v-model="showEnableDialog"
        max-width="400"
      >
        <template #dialogContent>
          <v-form ref="webhookFormRef" id="form" name="from">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  outlined
                  hide-details="auto"
                  clearable
                  dense
                  label="Webhook URL"
                  :error-messages="
                    formErrors && formErrors.webhook_url
                      ? formErrors.webhook_url
                      : ''
                  "
                  v-model="webhookForm.webhook_url"
                  @input="
                    formErrors && formErrors.webhook_url
                      ? delete formErrors.webhook_url
                      : ''
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #actions>
          <v-btn color="error" small @click="showEnableDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="success"
            :disabled="!webhookForm.webhook_url"
            small
            @click="enableDisableWebhook('enable')"
          >
            Submit
          </v-btn>
        </template>
      </BaseDialog>
      <!-- Enable Disable Webhook Dialogs End-->
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ClientForm from "./ClientForm.vue";
import WebhookConfigurationForm from "./WebhookConfigurationForm.vue";
import ClientButton from "@/components/AgGridButtons/ClientButton.vue";
import ClientDetails from "./ClientDetails.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import Logs from "@/components/common/Logs.vue";
import ConfigCellRenderer from "@/components/AgGridButtons/ConfigCellRenderer.vue";
import { bus } from "@/main";
import { getUserProfile } from "@/utils/functions.js";

export default {
  name: "Client",
  components: {
    BaseListLayout,
    ClientForm,
    ClientButton,
    ClientDetails,
    BaseSelect,
    WebhookConfigurationForm,
    CellRenderer,
    ShipmentCellRenderer,
    BaseDatePickerInput,
    BaseDialog,
    ExportReportDialog,
    Logs,
    ConfigCellRenderer,
  },
  data() {
    return {
      fromClientConfigure: true,
      clientID: null,
      totalItems: 0,
      clientList: [],
      filters: {},
      editMode: false,
      showClientForm: false,
      showClientDetails: false,
      showClientConfigurationForm: false,
      organizationList: [],
      integrationPartnerList: [],

      clientId: null,
      showDisableDialog: false,
      showEnableDialog: false,
      webhookForm: {},
      formErrors: {},

      commonColumns: [
        {
          headerName: "Client Code",
          field: "code",
          minWidth: 120,
          pinned: "left",
        },
        {
          headerName: "Client Name",
          field: "name",
          minWidth: 180,
        },
        {
          headerName: "Created On",
          field: "created",
          cellRenderer: "ConfigCellRenderer",
          minWidth: 190,
        },
        {
          headerName: "Updated On",
          field: "modified",
          minWidth: 190,
          cellRenderer: "ConfigCellRenderer",
        },
        {
          headerName: "Integration Partner",
          field: "integration_partner_name",
          minWidth: 120,
        },
        {
          headerName: "Is Webhook Enabled",
          field: "is_webhook_enabled",
          cellRenderer: "CellRenderer",
          minWidth: 100,
        },
        {
          headerName: "Webhook URL",
          field: "webhook_url",
          minWidth: 150,
          tooltipField: "webhook_url",
        },
        { headerName: "Total AWBs", field: "total_awbs", minWidth: 100 },
        {
          headerName: "Last 24 Hour Total AWBs",
          field: "total_past_day_awbs",
          minWidth: 180,
        },
        {
          headerName: "Last Failed Webhook Update At",
          field: "last_failed_webhook_update_at",
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        {
          headerName: "Last Webhook Sent At",
          field: "last_webhook_sent_at",
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        {
          headerName: "Last Failed Order Request At",
          field: "last_failed_order_request_at",
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        {
          headerName: "Last Request Received At",
          field: "last_request_received_at",
          cellRenderer: "ShipmentCellRenderer",
          minWidth: 180,
        },
        { headerName: "Created By", field: "created_by", minWidth: 150 },
        { headerName: "Updated By", field: "updated_by", minWidth: 150 },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ClientButton",
          minWidth: 260,
          pinned: "right",
        },
      ],

      showExportDialog: false,
      showLogsDialog: false,
      clientLogs: [],
    };
  },
  watch: {
    showEnableDialog(val) {
      if (!val) {
        this.webhookForm = {};
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
    columns() {
      if (this.isUserTypeOrganization) {
        return this.commonColumns;
      } else {
        this.commonColumns.splice(2, 0, {
          headerName: "Organization",
          field: "organization_name",
          minWidth: 180,
        });
        return [...this.commonColumns];
      }
    },
  },
  methods: {
    getClientList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("Client_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }

      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("integration_partner" in filters && filters != null) {
        filters.integration_partner = filters.integration_partner.join(",");
      }

      this.$api.client
        .getClientList({ ...params, ...filters })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.clientList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.clientList.refreshList();
    },
    updateList() {
      this.$refs.clientList.updateList();
    },
    addClient() {
      this.editMode = false;
      this.showClientForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.clientID = id;
      this.showClientForm = true;
    },
    viewDetails(id) {
      this.clientID = id;
      this.showClientDetails = true;
    },
    webhookEnable(id) {
      this.clientId = id;
      this.showEnableDialog = true;
    },
    webhookDisable(id) {
      this.clientId = id;
      this.showDisableDialog = true;
    },
    enableDisableWebhook(type) {
      bus.$emit("showLoader", true);
      let payload = {};
      if (type == "disable") {
        payload.is_webhook_enabled = false;
        payload.webhook_url = null;
      } else {
        payload.is_webhook_enabled = true;
        payload.webhook_url = this.webhookForm.webhook_url;
      }

      this.$api.client
        .webhookEnableDisable(this.clientId, payload)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
          type == "enable"
            ? (this.showEnableDialog = false)
            : (this.showDisableDialog = false);
          this.updateList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    copyApiKey(api_key) {
      if (api_key) {
        navigator.clipboard.writeText(api_key);
        bus.$emit("showToastMessage", {
          message: "API Key Copied",
          color: "success",
        });
      }
    },
    viewClientConfigurationLogs(row) {
      bus.$emit("showLoader", true);

      this.$api.client
        .getClientLogs(row.id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.clientLogs = res.data;
          this.showLogsDialog = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },

    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getIntegrationPartnerSelectionList() {
      this.$api.integrationPartner
        .getIntegrationPartnerSelectionList({ limit: "all" })
        .then((res) => {
          this.integrationPartnerList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    applyFilter() {
      localStorage.setItem("Client_Filters", JSON.stringify(this.filters));
      this.refreshList();
    },
    openClientConfigurationForm(id) {
      this.clientID = id;
      this.showClientConfigurationForm = true;
    },
  },
  mounted() {
    if (!this.isUserTypeOrganization) {
      this.getOrganizationOptions();
    }
    this.getIntegrationPartnerSelectionList();
    let f = localStorage.getItem("Client_Filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
};
</script>
