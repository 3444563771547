import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"elevation":"1"}},[_c(VCardTitle,{staticClass:"py-1 px-3 ma-0 secondary text-white"},[_vm._v(" Basic Information ")]),_c(VCardText,{staticClass:"px-3 py-2"},_vm._l((_vm.basicDetails),function(detail){return _c(VRow,{key:detail.label,attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(detail.label)+" ")]),_c(VCol,{staticClass:"text-right",attrs:{"cols":"6"}},[_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(detail.value)+" ")])])],1)}),1)],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"elevation":"1"}},[_c(VCardTitle,{staticClass:"py-1 px-3 ma-0 secondary text-white"},[_vm._v(" Event ")]),_c(VCardText,[_c(VTimeline,{attrs:{"dense":"","side":"end","light":"","align-top":""}},_vm._l((_vm.trackingDetailObject.events),function(event,i){return _c(VTimelineItem,{key:i,attrs:{"small":"","fill-dot":""}},[_c('div',{staticClass:"text-black"},[_c('div',{staticClass:"font-weight-normal"},[_c('strong',[_vm._v(_vm._s(event.hub_name))])]),_c('div',{staticClass:"text-caption"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-calendar-clock ")]),_vm._v(" "+_vm._s(event.customer_update)+" ")],1),_c('div',{staticClass:"text-caption"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.convertEventTimeStamp(event.event_time))+" ")],1)])])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }