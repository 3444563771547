<template>
  <BaseDialog
    v-model="showIntegrationPartnerForm"
    :title="`${editMode ? 'Edit' : 'Add'} Integration Partner`"
    width="50vh"
    @closeDialog="resetForm(), (showIntegrationPartnerForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="IntegrationPartnerForm"
        id="IntegrationPartnerForm"
        name="IntegrationPartnerForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Integration Partner Name*"
              :rules="[
                (val) => !!val || 'Integration Partner Name is required',
              ]"
              :error-messages="
                formErrors && formErrors.name ? formErrors.name : ''
              "
              v-model="integrationPartnerDetails.name"
              @input="
                formErrors && formErrors.name ? delete formErrors.name : ''
              "
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        v-if="!editMode"
        class="rounded-lg"
        small
        @click="$refs.IntegrationPartnerForm.reset()"
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!isValid"
        class="rounded-lg"
        small
        @click="submitForm()"
      >
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>
  
  <script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
export default {
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    IntegrationPartnerID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      integrationPartnerDetails: {},
    };
  },

  computed: {
    showIntegrationPartnerForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showIntegrationPartnerForm(val) {
      if (val) {
        if (this.editMode && this.IntegrationPartnerID) {
          this.getIntegrationPartnerObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    getIntegrationPartnerObject() {
      this.$api.integrationPartner
        .getIntegrationPartnerObject(this.IntegrationPartnerID)
        .then((res) => {
          this.integrationPartnerDetails = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.IntegrationPartnerForm;
      if (form) {
        form.reset();
      }
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.integrationPartnerDetails,
      };
      if (!this.editMode) {
        this.$api.integrationPartner
          .addIntegrationPartner(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Integration Partner created!",
              color: "success",
            });
            this.resetForm();
            this.showIntegrationPartnerForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.integrationPartner
          .editIntegrationPartner({
            id: this.integrationPartnerDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Integration Partner details updated!",
              color: "success",
            });
            this.resetForm();
            this.showIntegrationPartnerForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
  },
};
</script>
  
<style>
</style>