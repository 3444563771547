import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Metadata`,"width":"80vh"},on:{"closeDialog":function($event){_vm.resetForm(), (_vm.showVendorMetadataForm = false)}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"vendorMetadataForm",attrs:{"id":"vendorMetadataForm","name":"vendorMetadataForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pb-3",attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"label":"Metadata Type*","isSearchRequired":false,"required":true,"itemsList":_vm.metadataTypes,"isClearable":false,"item-text":"display_name","item-value":"value","rules":[(val) => !!val || 'Metadata Type is required'],"error-messages":_vm.formErrors && _vm.formErrors.metadata_type
                ? _vm.formErrors.metadata_type
                : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.metadata_type
                ? delete _vm.formErrors.metadata_type
                : ''}},model:{value:(_vm.vendorMetadataForm.metadata_type),callback:function ($$v) {_vm.$set(_vm.vendorMetadataForm, "metadata_type", $$v)},expression:"vendorMetadataForm.metadata_type"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('JsonEditorVue',{attrs:{"mode":'code'},model:{value:(_vm.vendorMetadataForm.metadata),callback:function ($$v) {_vm.$set(_vm.vendorMetadataForm, "metadata", $$v)},expression:"vendorMetadataForm.metadata"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit ")])]},proxy:true}]),model:{value:(_vm.showVendorMetadataForm),callback:function ($$v) {_vm.showVendorMetadataForm=$$v},expression:"showVendorMetadataForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }