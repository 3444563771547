import { client } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getClientList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(client.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getClientObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${client.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addClient(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(client.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editClient(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${client.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  //Webhook Configuration
  getClientWebHookConfig(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${client.base}${id}/get_configure_webhook/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  webhookEnableDisable(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${client.base}${id}/webhook_enable_disable/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  clientWebhookUrlUpdate(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${client.base}${id}/webhook_url_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  configureClientWebhookStatuses(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${client.base}${id}/configure_webhook_status/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  configureClientWebhookHeaders(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${client.base}${id}/configure_webhook_headers/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  configureClientWebhookFields(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${client.base}${id}/configure_webhook_field/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Logs Method
  getClientLogs(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${client.base}${id}/logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
