<template>
  <v-dialog
    v-model="showWebhookUpdateFilter"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="33%"
  >
    <v-card>
      <v-card-title>
        <h3 class="text-body-1 text-capitalize text--primary font-weight-bold">
          Update By AWB Filter
        </h3>
        <v-spacer></v-spacer>
        <v-icon @click="showWebhookUpdateFilter = false"> mdi-close </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="webhookFilters">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-1 pl-1 pb-3">
              <BaseDatePickerInput
                v-model="filters.start_date"
                id="start_date"
                name="start_date"
                label="Start Date"
                clearable
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-1 pl-1 pb-3">
              <BaseDatePickerInput
                v-model="filters.end_date"
                id="end_date"
                name="end_date"
                label="End Date"
                :min="filters.start_date"
                clearable
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="6" md="6" class="pl-1 pb-3">
              <BaseSelect
                label="Has Updated"
                :isClearable="true"
                :itemsList="hasUpdatedOption"
                item-text="label"
                item-value="value"
                v-model="filters.has_updated"
              />
            </v-col>

            <v-col cols="6" md="6" class="pl-1 pb-3">
              <BaseSelect
                label="Status"
                :isClearable="true"
                :itemsList="$globalConstant.choices.default.orderStatusOption"
                item-text="label"
                item-value="value"
                v-model="filters.last_status"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          small
          class="rounded-lg"
          id="resetFilter"
          @click="resetFilters()"
        >
          Reset
        </v-btn>
        <v-btn
          small
          color="primary"
          class="rounded-lg"
          id="applyFilter"
          @click="applyFilters()"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";

export default {
  components: { BaseDatePickerInput, BaseSelect },
  props: {
    title: String,
    value: Boolean,
  },
  data() {
    return {
      filters: {},
      hasUpdatedOption: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    };
  },
  watch: {
    showWebhookUpdateFilter(value) {
      if (value) {
        let filters = localStorage.getItem("UpdateByAWBFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.filters = filters;
      }
    },
  },
  computed: {
    showWebhookUpdateFilter: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    applyFilters() {
      localStorage.setItem("UpdateByAWBFilters", JSON.stringify(this.filters));
      this.$emit("applyFilters");
      this.showWebhookUpdateFilter = false;
    },
    resetFilters() {
      this.filters = {};
      localStorage.removeItem("UpdateByAWBFilters");
      this.$emit("applyFilters");
      this.showWebhookUpdateFilter = false;
      if (this.$refs.webhookFilters) {
        this.$refs.webhookFilters.reset();
      }
    },
  },
};
</script>
