import { organizationURL } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getOrganizationURLList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(organizationURL.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addOrganizationURL(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(organizationURL.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editOrganizationURL(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${organizationURL.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationURLObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organizationURL.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewOrganizationURLObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organizationURL.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getRequestForOptions(params) {
    return new Promise((resolve, reject) => {
      axios
        .options(organizationURL.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
