// New Routes
import Dashboard from "../pages/Dashboard";

import Organization from "../pages/Configuration/organization/Parent.vue";
import OrganizationList from "../pages/Configuration/organization";
import OrganizationDetails from "../pages/Configuration/organization/OrganizationDetails";
import OrganizationApi from "../pages/Configuration/organizationApi";
import OrganizationVendor from "../pages/Configuration/organizationVendor";
import Client from "../pages/Configuration/client";
import User from "../pages/Configuration/user";
import IntegrationPartner from "../pages/Configuration/IntegrationPartner";
import Vendor from "../pages/Configuration/Vendor/Parent.vue";
import VendorList from "../pages/Configuration/Vendor";
import VendorDetailPage from "../pages/Configuration/Vendor/VendorDetails";
import OrganizationConfiguration from "../pages/Configuration/OrganizationConfiguration";

//Inbound-shipments
import InboundShipments from "../pages/Inbound/Shipments/Parent.vue";
import ShipmentList from "../pages/Inbound/Shipments/Index.vue";
import ShipmentAWBList from "@/pages/Inbound/Shipments/ShipmentAWBList.vue";
import ShipmentDetails from "../pages/Inbound/Shipments/ShipmentDetails.vue";

//Vendor Shipments
import VendorShipments from "../pages/VendorShipments/Shipments/Parent.vue";
import VendorShipmentList from "../pages/VendorShipments/Shipments/Index.vue";
import VendorAWBList from "@/pages/VendorShipments/Shipments/VendorAWBList.vue";
import VendorShipmentDetails from "../pages/VendorShipments/Shipments/VendorShipmentDetails.vue";

// Old Routes
import Configuration from "../pages/Config";
import Report from "../pages/Report";
import IncomingWebhookLogs from "../pages/RequestedLogs/IncomingWebhookLogs";
import OutgoingWebhookLogs from "../pages/RequestedLogs/OutgoingWebhookLogs";
import WebhookUpdate from "../pages/WebhookUpdate";

//AWB Track For Different Vendors
import VendorDetails from "../components/common/VendorDetails.vue";

//AWB Track And Trace
import AWBTrackAndTrace from "../pages/AwbTrackAndTrace";

import { getUserProfile } from "../utils/functions";

const organizationRoutes = [
  {
    path: "dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "organization-api",
    name: "api",
    component: OrganizationApi,
  },
  {
    path: "organization-vendors",
    name: "vendors",
    component: OrganizationVendor,
  },
  {
    path: "client",
    name: "client",
    component: Client,
  },
  {
    path: "awbTrackAndTrace",
    name: "awbTrackAndTrace",
    component: AWBTrackAndTrace,
  },
  {
    path: "vendorAWBTrack",
    name: "vendorAWBTrack",
    component: VendorDetails,
  },
  {
    path: "inbound",
    name: "inbound",
    component: InboundShipments,
    children: [
      {
        path: "request-log",
        name: "request-log",
        component: ShipmentList,
      },
      {
        path: "request-log/:id",
        name: "shipment-details",
        component: ShipmentDetails,
      },
    ],
  },
  {
    path: "vendor-shipment",
    name: "vendor-shipment",
    component: VendorShipments,
    children: [
      {
        path: "list",
        name: "list",
        component: VendorShipmentList,
      },
      {
        path: "details",
        name: "vendor-shipment-details",
        component: VendorShipmentDetails,
        // props: (route) => ({ vendor: route.query.vendor }),
      },
    ],
  },
  //webhook
  {
    path: "webhook-incoming",
    name: "webhook-incoming",
    component: IncomingWebhookLogs,
  },
  {
    path: "webhook-client",
    name: "webhook-client",
    component: OutgoingWebhookLogs,
  },
  {
    path: "webhook-updateByAWB",
    name: "webhook-updateByAWB",
    component: WebhookUpdate,
  },
  {
    path: "report",
    name: "report",
    component: Report,
  },
];

const adminRoutes = [
  {
    path: "dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "organization",
    name: "organization",
    component: Organization,
    children: [
      {
        path: "list",
        name: "organization-list",
        component: OrganizationList,
      },
      {
        path: ":id",
        name: "organization-details",
        component: OrganizationDetails,
      },
      {
        path: ":id&:name/api",
        name: "organization-api-list",
        component: OrganizationApi,
      },
      {
        path: ":id&:name/vendor",
        name: "organization-vendor-list",
        component: OrganizationVendor,
      },
      {
        path: ":id&:name/client",
        name: "organization-client",
        component: Client,
      },
      {
        path: ":id&:name/configuration",
        name: "organization-configuration",
        component: OrganizationConfiguration,
      },
    ],
  },
  {
    path: "user",
    name: "user",
    component: User,
  },
  {
    path: "client",
    name: "client",
    component: Client,
  },
  {
    path: "integration-partner",
    name: "integration-partner",
    component: IntegrationPartner,
  },
  {
    path: "vendor",
    name: "vendor",
    component: Vendor,
    children: [
      {
        path: "list",
        name: "list",
        component: VendorList,
      },
      {
        path: ":name",
        name: "vendor-details",
        component: VendorDetailPage,
      },
    ],
  },
  {
    path: "inbound",
    name: "inbound",
    component: InboundShipments,
    children: [
      {
        path: "request-log",
        name: "request-log",
        component: ShipmentList,
      },
      {
        path: "request-log/:id",
        name: "shipment-details",
        component: ShipmentDetails,
      },
      {
        path: "shipment_awb_list",
        name: "shipment_awb_list",
        component: ShipmentAWBList,
      },
    ],
  },
  {
    path: "vendor-shipment",
    name: "vendor-shipment",
    component: VendorShipments,
    children: [
      {
        path: "list",
        name: "list",
        component: VendorShipmentList,
      },
      {
        path: "details",
        name: "vendor-shipment-details",
        component: VendorShipmentDetails,
        // props: (route) => ({ vendor: route.query.vendor }),
      },
      {
        path: "awb_list",
        name: "awb_list",
        component: VendorAWBList,
      },
    ],
  },
  {
    path: "configuration",
    name: "configuration",
    component: Configuration,
  },
  {
    path: "report",
    name: "report",
    component: Report,
  },
  {
    path: "awbTrackAndTrace",
    name: "awbTrackAndTrace",
    component: AWBTrackAndTrace,
  },
  {
    path: "vendorAWBTrack",
    name: "vendorAWBTrack",
    component: VendorDetails,
  },
  //webhook
  {
    path: "webhook-incoming",
    name: "webhook-incoming",
    component: IncomingWebhookLogs,
  },
  {
    path: "webhook-client",
    name: "webhook-client",
    component: OutgoingWebhookLogs,
  },
  {
    path: "webhook-updateByAWB",
    name: "webhook-updateByAWB",
    component: WebhookUpdate,
  },
  //Vendor API Request Logs Start
  // {
  //   path: "vendorAPIRequestLogs-trackShipment",
  //   name: "vendorAPIRequestLogs-trackShipment",
  //   component: TrackShipmentLogs,
  // },
  // {
  //   path: "vendorAPIRequestLogs-hubToOrganization",
  //   name: "vendorAPIRequestLogs-hubToOrganization",
  //   component: HubToOrganizationLogs,
  // },
  // {
  //   path: "vendorAPIRequestLogs-hubToVendor",
  //   name: "vendorAPIRequestLogs-hubToVendor",
  //   component: HubToVendorLogs,
  // },
  // {
  //   path: "vendorAPIRequestLogs-orgToHub",
  //   name: "vendorAPIRequestLogs-orgToHub",
  //   component: OrganizationToHubLogs,
  // },
  //Vendor API Request Logs End
  // {
  //   path: "outbound",
  //   name: "outbound",
  //   component: Shipments,
  //   children: [
  //     {
  //       path: "shipment-list",
  //       name: "shipment-list",
  //       component: ShipmentList,
  //     },
  //     {
  //       path: "shipment-list/:id",
  //       name: "shipment-details",
  //       component: ShipmentDetails,
  //     },
  //   ],
  // },
];
const routeList =
  getUserProfile() && getUserProfile().user_type == "organization"
    ? organizationRoutes
    : adminRoutes;

export default routeList;
