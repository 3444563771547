import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":"Organization Api Details","height":"90vh","width":"1100"},on:{"closeDialog":function($event){_vm.showOrganizationURLDetails = false}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCardTitle,_vm._l((_vm.basicDetails),function(detail){return _c('span',{key:detail.label,staticClass:"mr-8",staticStyle:{"font-size":"14px"}},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(detail.label)+" -")]),_c('span',{staticClass:"font-weight-bold secondary--text"},[_vm._v(" "+_vm._s(detail.value)+" ")])])}),0)],1)],1),_c(VCard,{staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"px-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"pb-4 text-lg-subtitle-1 text-uppercase font-weight-bold text--primary"},[_vm._v(" Schema ")]),_c('JsonEditorVue',{staticStyle:{"background-color":"white"},attrs:{"mode":'code'},model:{value:(_vm.organizationApiDetails.schema),callback:function ($$v) {_vm.$set(_vm.organizationApiDetails, "schema", $$v)},expression:"organizationApiDetails.schema"}})],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.showOrganizationURLDetails),callback:function ($$v) {_vm.showOrganizationURLDetails=$$v},expression:"showOrganizationURLDetails"}})
}
var staticRenderFns = []

export { render, staticRenderFns }