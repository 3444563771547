import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} API`,"width":"70vh"},on:{"closeDialog":function($event){_vm.resetForm(), (_vm.showOrganizationURLForm = false)}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"organizationApiForm",attrs:{"id":"organizationApiForm","name":"organizationApiForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,[(
            _vm.formErrors.non_field_errors &&
            _vm.formErrors.non_field_errors.length > 0
          )?_c(VCol,{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"dense":"","type":"error"}},_vm._l((_vm.formErrors.non_field_errors),function(error,i){return _c(VList,{key:i,staticStyle:{"background":"inherit !important"},attrs:{"dense":""}},[_c(VListItem,{staticStyle:{"min-height":"10px !important"},attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(error))])])],1)}),1)],1):_vm._e(),_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"required":true,"label":"Request For*","item-text":"display_value","item-value":"value","itemsList":_vm.requestForList,"rules":[(val) => !!val || 'Request For is required'],"error-messages":_vm.formErrors && _vm.formErrors.request_for
                ? _vm.formErrors.request_for
                : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.request_for
                ? delete _vm.formErrors.request_for
                : ''}},model:{value:(_vm.organizationApiForm.request_for),callback:function ($$v) {_vm.$set(_vm.organizationApiForm, "request_for", $$v)},expression:"organizationApiForm.request_for"}})],1),_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Organization URL*","rules":[(val) => !!val || 'Organization URL is required'],"error-messages":_vm.formErrors && _vm.formErrors.url ? _vm.formErrors.url : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.url ? delete _vm.formErrors.url : ''}},model:{value:(_vm.organizationApiForm.url),callback:function ($$v) {_vm.$set(_vm.organizationApiForm, "url", $$v)},expression:"organizationApiForm.url"}})],1),(!_vm.isUserTypeOrganization)?_c(VCol,{staticClass:"pa-2",attrs:{"cols":"12"}},[_vm._v(" Schema "),_c('JsonEditorVue',{attrs:{"mode":'code'},model:{value:(_vm.organizationApiForm.schema),callback:function ($$v) {_vm.$set(_vm.organizationApiForm, "schema", $$v)},expression:"organizationApiForm.schema"}})],1):_vm._e()],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c(VBtn,{attrs:{"color":"primary","small":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit ")])]},proxy:true}]),model:{value:(_vm.showOrganizationURLForm),callback:function ($$v) {_vm.showOrganizationURLForm=$$v},expression:"showOrganizationURLForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }