<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="shipmentList"
        :hasTabs="true"
        ref="shipmentList"
        title="Shipments"
        searchable
        :table-header="
          currentTabValue == 'order'
            ? orderColumns
            : currentTabValue == 'order_cancel'
            ? cancelOrderColumns
            : trackOrderColumns
        "
        :table-data="shipmentList"
        :rowHeight="40"
        :context="context"
        :total="totalItems"
        localStorageKey="shipmentColumns"
        @selectionChanged="selectionChanged"
        @getList="getRequestLogList"
      >
        <!-- <template #tabSlot>
          <v-tabs v-model="currentTab" background-color="#f5f8fa">
            <v-tabs-slider color="#f5f8fa"></v-tabs-slider>
            <v-tab
              v-for="item in items"
              :key="item.value"
              style="height: 50%"
              class="mr-2 grey rounded-pill white--text"
            >
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template> -->
        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>
            Export Data
          </v-btn>
          <!-- <v-btn
            :disabled="selectedItems.length == 0"
            small
            color="primary"
            class="ma-1"
            @click="bulkResend()"
          >
            <v-icon size="16">mdi-upload</v-icon>Bulk Resend
          </v-btn> -->
        </template>
        <template #leftFilterSlot>
          <div>
            <FilterPanel filter_for="inbound" @applyFilters="applyFilter" />
          </div>
        </template>
        <template #dialogs>
          <ExportReportDialog
            title="Shipment Request Logs"
            v-model="showExportDialog"
            request_type="Inbound"
            request_for="order"
          >
          </ExportReportDialog>
          <EditOrderForm
            :integrationPartner="selectedShipment.integration_partner_name"
            :objId="selectedShipment.id"
            v-model="showEditForm"
            @refreshList="getRequestLogList"
          />
          <ClientWebhookLogsDialog
            v-model="showClientWebhookLogsDialog"
            :rowData="rowData"
          />
          <Logs
            v-model="showLogsDialog"
            logTitle="AWB"
            :logHeight="
              (style = 'height:calc(71.5vh)!important; overflow-x: hidden;')
            "
            :logs="awbLogs"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import FilterPanel from "@/components/common/FilterPanel.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ShipmentAlertCell from "@/components/AgGridButtons/ShipmentAlertCell.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import ShipmenButtons from "@/components/AgGridButtons/ShipmenButtons.vue";
import EditOrderForm from "./EditOrderForm.vue";
import ClientWebhookLogsDialog from "./ClientWebhookLogsDialog.vue";
import Logs from "@/components/common/Logs.vue";
import { bus } from "@/main";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    BaseSelect,
    FilterPanel,
    ShipmentCellRenderer,
    ExportReportDialog,
    ShipmentAlertCell,
    ShipmenButtons,
    EditOrderForm,
    ClientWebhookLogsDialog,
    Logs,
  },
  data() {
    return {
      fromShippingShipments: true,
      currentTab: null,
      currentTabValue: "order",
      items: [
        { name: "Create Order", value: "order" },
        { name: "Cancel Order", value: "order_cancel" },
        { name: "Track Order", value: "awb_track" },
        { name: "PDF Order", value: "awb_pdf" },
      ],
      totalItems: 0,
      shipmentList: [],
      filters: {},
      showEditForm: false,
      selectedShipment: {},
      orderColumns: [
        // {
        //   headerCheckboxSelection: true,
        //   checkboxSelection: true,
        //   showDisabledCheckboxes: true,
        //   maxWidth: 50,
        //   pinned: "left",
        // },
        {
          headerName: "Alert",
          field: "is_success",
          pinned: "left",
          maxWidth: 100,
          minWidth: 100,
          cellRenderer: "ShipmentAlertCell",
        },
        {
          headerName: "AWB Number",
          field: "awb_number",
          minWidth: 180,
          autoHeight: true,
          cellRenderer: "ShipmentCellRenderer",
          onCellClicked: (params) => {
            let rowId = params && params.data && params.data.id;
            if (rowId) {
              this.$router.push({
                name: "shipment-details",
                params: { id: rowId },
              });
            }
          },
        },
        {
          headerName: "Reference Number",
          field: "reference_number",
          cellRenderer: "ShipmentCellRenderer",
          autoHeight: true,
          minWidth: 150,
          onCellClicked: (params) => {
            let rowId = params && params.data && params.data.id;
            if (rowId) {
              this.$router.push({
                name: "shipment-details",
                params: { id: rowId },
              });
            }
          },
        },

        { headerName: "Status", field: "status", minWidth: 150 },
        {
          headerName: "Status Last Updated at",
          field: "last_updated",
          minWidth: 280,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Created On",
          field: "created",
          minWidth: 280,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Updated On",
          field: "modified",
          minWidth: 280,
          cellRenderer: "ShipmentCellRenderer",
        },
        {
          headerName: "Integration Partner",
          field: "integration_partner_name",
          minWidth: 150,
        },
        {
          headerName: "Organization",
          field: "organization_name",
          minWidth: 150,
        },
        { headerName: "Client", field: "client_name", minWidth: 150 },
        { headerName: "Status Code", field: "status_code", minWidth: 100 },
        // {
        //   headerName: "Is Success",
        //   field: "is_success",
        //   cellRenderer: "ShipmentCellRenderer",
        // },
        {
          headerName: "Actions",
          field: "actions",
          minWidth: 180,
          pinned: "right",
          cellRenderer: "ShipmenButtons",
        },
      ],
      cancelOrderColumns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          maxWidth: 50,
        },
        {
          headerName: "Reference Number",
          field: "reference_number",
          cellRenderer: "ShipmentCellRenderer",
          autoHeight: true,
        },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "ShipmentCellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Status Code", field: "status_code" },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
        },
      ],
      trackOrderColumns: [
        {
          headerCheckboxSelection: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
          maxWidth: 50,
        },
        {
          headerName: "Reference Number",
          field: "reference_number",
          cellRenderer: "ShipmentCellRenderer",
          autoHeight: true,
        },
        { headerName: "AWB Number", field: "awb_number" },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "ShipmentCellRenderer",
        },
        { headerName: "Organization", field: "organization_name" },
        { headerName: "Client", field: "client_name" },
        { headerName: "Status Code", field: "status_code" },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
        },
      ],

      selectedItems: [],
      showExportDialog: false,

      rowData: {},
      showClientWebhookLogsDialog: false,
      awbLogs: [],
      showLogsDialog: false,
    };
  },
  watch: {
    currentTab(val) {
      this.currentTabValue = this.items[val].value;
      this.refreshList();
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getRequestLogList(params = {}) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("client_shipments_filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }
      params.category = this.currentTabValue;

      this.$api.inbound
        .getRequestLogList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.shipmentList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error getting request log list",
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.shipmentList.refreshList();
    },
    updateList() {
      this.$refs.shipmentList.updateList();
    },
    viewDetails(id) {
      this.$router.push({
        name: "shipment-details",
        params: { id: id },
      });
    },
    editAndBook(data) {
      this.showEditForm = true;
      this.selectedShipment = data;
    },
    fetchShipmentStatus(data) {
      bus.$emit("showLoader", true);

      this.$api.inbound
        .fetchShipmentStatus(data.id)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Status Fetched Successfully",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail ? err.data.detail : "Connection error",
            color: "red",
          });
        });
    },
    applyFilter(filters) {
      localStorage.setItem("client_shipments_filters", JSON.stringify(filters));
      this.refreshList();
    },
    selectionChanged(selectedRows) {
      this.selectedItems = selectedRows;
    },
    bulkResend() {
      bus.$emit("showLoader", true);
      let selectedItemsIds = [];
      this.selectedItems.map((item) => {
        selectedItemsIds.push(item.id);
      });
      this.$api.inbound
        .bulkResendShipmentFailedApis({ ids: selectedItemsIds })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.updateList();
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err?.data?.message,
            color: "red",
          });
        });
    },

    // Logs methods
    viewAWBLogs(id) {
      this.$api.inbound
        .viewLogDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.awbLogs = res.data.awb_logs;
          this.showLogsDialog = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error getting data",
            color: "red",
          });
        });
    },
    viewWebhookLogs(row) {
      this.rowData = row;
      this.showClientWebhookLogsDialog = true;
    },
  },
  mounted() {
    let f = localStorage.getItem("client_shipments_filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
  },
};
</script>

<style scoped>
.v-tab--active {
  background-color: #17b7d0 !important;
}
</style>
