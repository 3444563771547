import { organizationVendor } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getOrganizationVendorList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(organizationVendor.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addOrganizationVendor(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(organizationVendor.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editOrganizationVendor(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${organizationVendor.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationVendorObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organizationVendor.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewOrganizationVendorObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organizationVendor.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorOptions(params) {
    return new Promise((resolve, reject) => {
      axios
        .options(organizationVendor.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadOrganizationVendorStatus(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${organizationVendor.base}${id}/vendor_status_bulk_create/`,
          payload
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Method For Org. Vendor Status Mapping
  getOrganizationVendorStatusDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organizationVendor.base}${id}/get_vendor_status_mapping/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
