<template>
  <BaseDialog
    v-model="showChangePasswordForm"
    title="Change Password"
    width="50vh"
    @closeDialog="resetForm(), (showChangePasswordForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="changePasswordForm"
        id="changePasswordForm"
        name="changePasswordForm"
      >
        <v-row no-gutters>
          <v-col cols="12" class="pr-3 pb-3">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="Existing Password*"
              :type="isExistingPassword ? 'text' : 'password'"
              :append-icon="isExistingPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[(val) => !!val || 'Existing Password is required']"
              v-model="userProfile.existing_password"
              @click:append="toggleExistingPasswordVissibility"
            />
          </v-col>
          <v-col cols="12" class="pr-3 pb-3">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="New Password*"
              :type="isNewPassword ? 'text' : 'password'"
              :append-icon="!isNewPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :rules="[(val) => !!val || 'New Password is required']"
              v-model="userProfile.new_password"
              @click:append="toggleNewPasswordVissibility"
            />
          </v-col>
          <v-col cols="12" class="pr-3 pb-3">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="Confirm Password*"
              :type="isConfirmPassword ? 'text' : 'password'"
              :append-icon="!isConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :rules="[(val) => !!val || 'Confirm Password is required']"
              v-model="userProfile.confirm_password"
              @click:append="toggleConfirmPasswordVissibility"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()"
        >Submit</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  name: "ChangePasswordForm",
  components: { BaseDialog },
  props: {
    value: {
      type: Boolean,
    },
    userId: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      userProfile: {},
      isExistingPassword: false,
      isNewPassword: false,
      isConfirmPassword: false,
    };
  },
  watch: {
    showChangePasswordForm(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
  computed: {
    showChangePasswordForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    toggleExistingPasswordVissibility() {
      this.isExistingPassword = !this.isExistingPassword;
    },
    toggleNewPasswordVissibility() {
      this.isNewPassword = !this.isNewPassword;
    },
    toggleConfirmPasswordVissibility() {
      this.isConfirmPassword = !this.isConfirmPassword;
    },
    submitForm() {
      this.formErrors.confirm_password = "";
      if (this.userProfile.new_password === this.userProfile.confirm_password) {
        bus.$emit("showLoader", true);
        let payload = {
          id: this.userId,
          data: {
            existing_password: this.userProfile.existing_password,
            new_password: this.userProfile.new_password,
          },
        };
        this.$api.user
          .changePassword(payload)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Password Changed Successfully",
              color: "success",
            });
            this.resetForm();
            this.showChangePasswordForm = false;
            localStorage.clear();
            this.$router.push("/login");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            bus.$emit("showToastMessage", {
              message: err.data,
              color: "error",
            });
          });
      } else {
        this.formErrors.confirm_password =
          "New Password and Confirm Password must match";
      }
    },
    resetForm() {
      const form = this.$refs.changePasswordForm;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>

<style></style>
