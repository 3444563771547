<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="OrganizationList"
        ref="organizationList"
        title="Organization"
        searchable
        :table-header="columns"
        :table-data="organizationList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        localStorageKey="organizationColumns"
        @getList="getOrganizationList"
      >
        <template #addButton>
          <v-btn
            small
            color="primary"
            depressed
            class="text-capitalize"
            @click="addNewOrganization"
          >
            Add Organization
          </v-btn>
        </template>

        <template #rightSlot>
          <v-btn
            color="primary"
            small
            class="ma-1"
            @click="showExportDialog = true"
          >
            <v-icon size="16">mdi-export</v-icon>
            Export Data
          </v-btn>
        </template>

        <template #dialogs>
          <OrganizationForm
            ref="organizationForm"
            v-model="showOrganizationForm"
            :organizationID="organizationID"
            :editMode="editMode"
            @updateList="updateList"
          />

          <ExportReportDialog
            title="Organization Logs"
            v-model="showExportDialog"
            request_type="Organization"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import OrganizationButton from "@/components/AgGridButtons/OrganizationButton.vue";
import OrganizationForm from "./OrganizationForm.vue";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import ExportReportDialog from "@/components/common/ExportReportDialog.vue";
import ConfigCellRenderer from "@/components/AgGridButtons/ConfigCellRenderer.vue";
import { bus } from "@/main";

export default {
  name: "OrganizationList",
  components: {
    BaseListLayout,
    OrganizationButton,
    OrganizationForm,
    CellRenderer,
    ExportReportDialog,
    ConfigCellRenderer,
  },
  data() {
    return {
      fromOrganizationConfigure: true,
      editMode: false,
      organizationList: [],
      showOrganizationForm: false,
      organizationID: null,
      totalItems: 0,
      columns: [
        {
          headerName: "Organization Code",
          field: "code",
          minWidth: 150,
          pinned: "left",
        },
        { headerName: "Organization Name", field: "name", minWidth: 150 },
        {
          headerName: "Organization URL",
          field: "url",
          minWidth: 200,
          tooltipField: "url",
        },
        { headerName: "Total Clients", field: "total_clients", minWidth: 100 },
        { headerName: "Total Vendors", field: "total_vendors", minWidth: 100 },
        { headerName: "Total AWBs", field: "total_awbs", minWidth: 100 },
        {
          headerName: "Created On",
          field: "created",
          cellRenderer: "ConfigCellRenderer",
          minWidth: 190,
        },
        {
          headerName: "Updated On",
          field: "modified",
          minWidth: 190,
          cellRenderer: "ConfigCellRenderer",
        },
        { headerName: "Created By", field: "created_by", minWidth: 150 },
        { headerName: "Updated By", field: "updated_by", minWidth: 150 },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "OrganizationButton",
          minWidth: 250,
          pinned: "right",
        },
      ],
      showExportDialog: false,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getOrganizationList(params) {
      bus.$emit("showLoader", true);
      this.$api.organization
        .getOrganizationList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.organizationList = res.data.results;
        })
        .catch((err) => {
          console.log(err);

          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    addNewOrganization() {
      this.editMode = false;
      this.showOrganizationForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.organizationID = id;
      this.showOrganizationForm = true;
    },
    viewDetails(id) {
      this.$router.push({
        name: "organization-details",
        params: { id: id },
      });
    },
    openOrganizationApiPage(id, name) {
      this.$router.push({
        name: "organization-api-list", // path name for admin user
        params: { id: id, name: name },
      });
    },
    openOrganizationVendorPage(id, name) {
      this.$router.push({
        name: "organization-vendor-list", // path name for admin user
        params: { id: id, name: name },
      });
    },
    openOrganizationClientPage(id, name) {
      this.$router.push({
        name: "organization-client",
        params: { id: id, name: name },
      });
    },
    openConfiguration(id, name) {
      this.$router.push({
        name: "organization-configuration",
        params: { id: id, name: name },
      });
    },
    updateList() {
      this.$refs.organizationList.updateList();
    },
    copyApiKey(api_key) {
      if (api_key) {
        navigator.clipboard.writeText(api_key);
        bus.$emit("showToastMessage", {
          message: "API Key Copied",
          color: "success",
        });
      }
    },
  },
};
</script>
