<template>
  <v-navigation-drawer
    permanent
    width="270"
    style="z-index: 11 !important"
    dark
    class="primary"
    clipped
    app
    :mini-variant.sync="mini"
    v-model="drawer"
    @click="rail = false"
    v-if="sideNav && sideNav.length > 0"
  >
    <v-list-item class="px-2" style="height: 70px">
      <v-list-item-avatar>
        <v-btn depressed class="mr-1" fab dark x-small color="secondary">
          {{
            loggedUser.first_name.slice(0, 1) + loggedUser.last_name.slice(0, 1)
          }}
        </v-btn>
        <!-- <v-img src="https://randomuser.me/api/portraits/women/81.jpg"></v-img> -->
      </v-list-item-avatar>
      <v-list-item-title>
        {{ loggedUser.first_name + " " + loggedUser.last_name }}
        <br />
        <span style="font-size: 12px" class="secondary--text">{{
          loggedUser.user_type
        }}</span>
      </v-list-item-title>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item v-for="(item, i) in sideNav" :key="i" class="pa-0 ma-0">
        <v-list-group
          v-if="item && item.subLinks && item.subLinks.length > 0"
          class="w-100 v-list-sub-group heading--text"
          style="color: white !important"
        >
          <template v-slot:activator>
            <v-list-item router exact :to="item.to">
              <v-list-item-action class="mr-2">
                <v-icon class="text-h6 font-weight-light">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="text-transform: capitalize">
                  {{ item.title }}
                  <v-icon v-if="item.isNew" color="green">
                    mdi-circle-small
                  </v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            class="secondary"
            router
            exact
            v-for="(subItem, index) in item.subLinks"
            :key="index"
            :to="subItem.to"
            :id="subItem.to"
          >
            <v-list-item-action class="mr-2">
              <v-icon class="text-h6 font-weight-light">
                {{ subItem.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="text-transform: capitalize">
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :to="item.to" router exact>
          <v-list-item-action
            v-if="
              item.title == 'Updates By AWB' ||
              item.title == 'AWB Shipment List' ||
              item.title == 'Client AWB List'
            "
            class="mr-2"
          >
            <span class="text-h6 white--text rounded-lg text-caption">
              AWB
            </span>
          </v-list-item-action>
          <v-list-item-action class="mr-2" v-else>
            <v-icon class="text-h6 font-weight-light">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="text-transform: capitalize">
              {{ item.title }}
              <v-icon v-if="item.isNew" color="green">
                mdi-circle-small
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getUserProfile } from "@/utils/functions.js";

export default {
  data() {
    return {
      loggedUser: null,
      drawer: true,
      mini: true,
      sideNav: [],
      adminMenu: [
        {
          title: "dashboard",
          icon: "mdi-view-dashboard",
          to: "/dashboard",
          key: "routes",
        },
        {
          title: "Configuration",
          icon: "mdi-account-cog",
          isNew: true,
          subLinks: [
            {
              title: "Organization",
              icon: "mdi-office-building",
              to: "/organization/list",
              key: "routes",
            },
            {
              title: "User",
              icon: "mdi-account",
              to: "/user",
              key: "routes",
            },
            {
              title: "Client",
              icon: "mdi-account",
              to: "/client",
              key: "routes",
            },
            {
              title: "Integration Partner",
              icon: "mdi-handshake",
              to: "/integration-partner",
              key: "routes",
            },
            {
              title: "Vendor",
              icon: "mdi-account-tie  ",
              to: "/vendor/list",
              key: "routes",
            },
          ],
        },
        //Incoming
        {
          title: "Shipping",
          icon: "mdi-account-arrow-right",
          isNew: true,
          subLinks: [
            {
              title: "Shipments",
              to: "/inbound/request-log",
              icon: "mdi-weight",
              key: "routes",
            },
            {
              title: "Client AWB List",
              to: "/inbound/shipment_awb_list",
              icon: "mdi-archive-outline",
              key: "routes",
            },
          ],
        },
        //Vendor
        {
          title: "Vendor Shipments",
          icon: "mdi-account-arrow-right",
          isNew: true,
          subLinks: [
            {
              title: "Shipments",
              to: "/vendor-shipment/list",
              icon: "mdi-weight",
              key: "routes",
            },
            {
              title: "AWB Shipment List",
              to: "/vendor-shipment/awb_list",
              icon: "mdi-archive-outline",
              key: "routes",
            },
          ],
        },
        {
          title: "Webhook",
          icon: "mdi-webhook",
          subLinks: [
            {
              title: "Incoming Update",
              to: "/webhook-incoming",
              icon: "mdi-arrow-right-bold-circle",
              key: "routes",
            },
            {
              title: "Client Update",
              to: "/webhook-client",
              icon: "mdi-arrow-left-bold-circle",
              key: "routes",
            },
          ],
        },
        // Update By AWB
        {
          title: "Updates By AWB",
          icon: "mdi-view-dashboard",
          to: "/webhook-updateByAWB",
          key: "routes",
          isNew: true,
        },
        // {
        //   title: "Vendor",
        //   icon: "mdi-math-log",
        //   subLinks: [
        //     // {
        //     //   title: "Track Shipment Logs",
        //     //   key: "routes",
        //     //   icon: "mdi-webhook",
        //     //   to: "vendorAPIRequestLogs-trackShipment",
        //     // },
        //     {
        //       title: "Hub To Organization Logs",
        //       key: "routes",
        //       icon: "mdi-alpha-h-box-outline",
        //       to: "vendorAPIRequestLogs-hubToOrganization",
        //     },
        //     {
        //       title: "Hub To Vendor Logs",
        //       key: "routes",
        //       icon: "mdi-account-details",
        //       to: "vendorAPIRequestLogs-hubToVendor",
        //     },
        //     {
        //       title: "Organization To Hub Logs",
        //       key: "routes",
        //       icon: "mdi-alpha-o-box-outline",
        //       to: "vendorAPIRequestLogs-orgToHub",
        //     },
        //   ],
        // },
        {
          title: "Export History",
          icon: "mdi-file-export",
          to: "/report",
          key: "routes",
        },
        {
          title: "AWB Track And Trace",
          to: "/awbTrackAndTrace",
          icon: "mdi-map-marker-path",
          key: "routes",
        },
        {
          title: "Vendor AWB Track",
          to: "/vendorAWBTrack",
          icon: "mdi-alpha-v-box-outline",
          key: "routes",
        },
        {
          title: "Configuration",
          icon: "mdi-account-cog",
          to: "/configuration",
          key: "routes",
        },
      ],
      organizationMenu: [
        {
          title: "dashboard",
          icon: "mdi-view-dashboard",
          to: "/dashboard",
          key: "project",
        },
        {
          title: "Api",
          icon: "mdi-map-marker",
          to: "/organization-api",
          key: "routes",
        },
        {
          title: "Vendor",
          icon: "mdi-account-tie",
          to: "/organization-vendors",
          key: "routes",
        },
        {
          title: "Client",
          icon: "mdi-account-group",
          to: "/client",
          key: "routes",
        },
        {
          title: "Shipping",
          icon: "mdi-account-arrow-right",
          isNew: true,
          subLinks: [
            {
              title: "Shipments",
              to: "/inbound/request-log",
              icon: "mdi-weight",
              key: "routes",
            },
          ],
        },
        {
          title: "Vendor Shipments",
          icon: "mdi-account-arrow-right",
          isNew: true,
          subLinks: [
            {
              title: "Shipments",
              to: "/vendor-shipment/list",
              icon: "mdi-weight",
              key: "routes",
            },
          ],
        },
        {
          title: "Webhook",
          icon: "mdi-webhook",
          subLinks: [
            {
              title: "Incoming Update",
              to: "/webhook-incoming",
              icon: "mdi-arrow-right-bold-circle",
              key: "routes",
            },
            {
              title: "Client Update",
              to: "/webhook-client",
              icon: "mdi-arrow-left-bold-circle",
              key: "routes",
            },
            {
              title: "Updates By AWB",
              to: "/webhook-updateByAWB",
              key: "routes",
            },
          ],
        },
        {
          title: "Export History",
          icon: "mdi-file-export",
          to: "/report",
          key: "routes",
        },
        // {
        //   title: "Configuration",
        //   icon: "mdi-account-cog",
        //   to: "/configuration",
        //   key: "routes",
        // },
        // {
        //   title: "Incoming Request Logs",
        //   icon: "mdi-account-arrow-right",
        //   subLinks: [
        //     {
        //       title: "Order Logs",
        //       to: "inboundLogs-order",
        //       icon: "mdi-reorder-horizontal",
        //       key: "routes",
        //     },
        //     {
        //       title: "Cancel Order Logs",
        //       to: "inboundLogs-cancelorder",
        //       icon: "mdi-close-box",
        //       key: "routes",
        //     },
        //     {
        //       title: "Track AWB Logs",
        //       key: "routes",
        //       icon: "mdi-webhook",
        //       to: "inboundLogs-trackAWB",
        //     },
        //     {
        //       title: "Generate AWB PDF Logs",
        //       to: "inboundLogs-generateawbpdf",
        //       icon: "mdi-file-pdf-box",
        //       key: "routes",
        //     },
        //     {
        //       title: "Order Pricing Logs",
        //       to: "inboundLogs-orderpricing",
        //       icon: "mdi-text-box",
        //       key: "routes",
        //     },
        //     {
        //       title: "Products Logs",
        //       to: "inboundLogs-product",
        //       icon: "mdi-professional-hexagon",
        //       key: "routes",
        //     },
        //     {
        //       title: "Services Logs",
        //       to: "inboundLogs-service",
        //       icon: "mdi-toolbox-outline",
        //       key: "routes",
        //     },
        //     {
        //       title: "Instructions Logs",
        //       to: "inboundLogs-instructions",
        //       icon: "mdi-format-list-bulleted-square",
        //       key: "routes",
        //     },
        //     {
        //       title: "Locations Logs",
        //       to: "inboundLogs-location",
        //       icon: "mdi-map-marker-outline",
        //       key: "routes",
        //     },
        //     {
        //       title: "Areas Logs",
        //       to: "inboundLogs-areas",
        //       icon: "mdi-home-city",
        //       key: "routes",
        //     },
        //   ],
        // },
        // {
        //   title: "Outgoing Request Logs",
        //   icon: "mdi-table-arrow-left",
        //   subLinks: [
        //     {
        //       title: "Order Logs",
        //       to: "outboundLogs-order",
        //       icon: "mdi-reorder-horizontal",
        //       key: "routes",
        //     },
        //     {
        //       title: "Cancel Order Logs",
        //       to: "outboundLogs-cancelorder",
        //       icon: "mdi-close-box",
        //       key: "routes",
        //     },
        //     {
        //       title: "Track AWB Logs",
        //       key: "routes",
        //       icon: "mdi-webhook",
        //       to: "outboundLogs-trackAWB",
        //     },
        //     {
        //       title: "Generate AWB PDF Logs",
        //       to: "outboundLogs-generateawbpdf",
        //       icon: "mdi-file-pdf-box",
        //       key: "routes",
        //     },
        //     {
        //       title: "Order Pricing Logs",
        //       to: "outboundLogs-orderpricing",
        //       icon: "mdi-text-box",
        //       key: "routes",
        //     },
        //     {
        //       title: "Products Logs",
        //       to: "outboundLogs-product",
        //       icon: "mdi-professional-hexagon",
        //       key: "routes",
        //     },
        //     {
        //       title: "Services Logs",
        //       to: "outboundLogs-service",
        //       icon: "mdi-toolbox-outline",
        //       key: "routes",
        //     },
        //     {
        //       title: "Instructions Logs",
        //       to: "outboundLogs-instructions",
        //       icon: "mdi-format-list-bulleted-square",
        //       key: "routes",
        //     },
        //     {
        //       title: "Locations Logs",
        //       to: "outboundLogs-location",
        //       icon: "mdi-map-marker-outline",
        //       key: "routes",
        //     },
        //     {
        //       title: "Areas Logs",
        //       to: "outboundLogs-areas",
        //       icon: "mdi-home-city",
        //       key: "routes",
        //     },
        //   ],
        // },
        {
          title: "AWB Track And Trace",
          to: "/awbTrackAndTrace",
          icon: "mdi-map-marker-path",
          key: "routes",
        },
        // {
        //   title: "Vendor Request Logs",
        //   icon: "mdi-math-log",
        //   subLinks: [
        //     // {
        //     //   title: "Track Shipment Logs",
        //     //   key: "routes",
        //     //   icon: "mdi-webhook",
        //     //   to: "vendorAPIRequestLogs-trackShipment",
        //     // },
        //     {
        //       title: "Hub To Organization Logs",
        //       key: "routes",
        //       icon: "mdi-alpha-h-box-outline",
        //       to: "vendorAPIRequestLogs-hubToOrganization",
        //     },
        //     {
        //       title: "Hub To Vendor Logs",
        //       key: "routes",
        //       icon: "mdi-account-details",
        //       to: "vendorAPIRequestLogs-hubToVendor",
        //     },
        //     {
        //       title: "Organization To Hub Logs",
        //       key: "routes",
        //       icon: "mdi-alpha-o-box-outline",
        //       to: "vendorAPIRequestLogs-orgToHub",
        //     },
        //   ],
        // },

        // Vendor AWB Track
        {
          title: "Vendor AWB Track",
          to: "/vendorAWBTrack",
          icon: "mdi-alpha-v-box-outline",
          key: "routes",
        },
      ],
    };
  },
  computed: {
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  mounted() {
    const userProfile = localStorage.getItem("profile");
    this.loggedUser = JSON.parse(userProfile);

    this.sideNav = this.isUserTypeOrganization
      ? this.organizationMenu
      : this.adminMenu;
  },
};
</script>

<style lang="scss">
.v-list-sub-group {
  .v-list-group__header {
    padding: 0 !important;
  }
}
</style>
