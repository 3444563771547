<template>
  <v-dialog
    v-model="showFilterDialog"
    @submit="applyFilters()"
    @reset="resetFilters()"
    width="33%"
    ><v-card>
      <v-card-title>
        <h3 class="text-body-1 text-capitalize text--primary font-weight-bold">
          {{ title.replace(/_/g, " ") }}Filter
        </h3>
        <v-spacer></v-spacer>
        <v-icon @click="showFilterDialog = false"> mdi-close </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form ref="webhookFilters">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pl-1 pb-3">
              <BaseSelect
                label="Order Status"
                :itemsList="$globalConstant.choices.default.orderStatusOption"
                :isClearable="true"
                item-text="label"
                item-value="value"
                v-model="filters.status"
              />
            </v-col>
            <v-col cols="12" md="4" class="pl-1 pb-3">
              <BaseSelect
                label="Status"
                :isClearable="true"
                :itemsList="$globalConstant.choices.default.statusOption"
                item-text="label"
                item-value="value"
                v-model="filters.is_success"
              />
            </v-col>
            <v-col cols="12" md="4" class="pl-1 pb-3">
              <BaseSelect
                label="Status Code"
                :isClearable="true"
                item-text="label"
                item-value="value"
                :itemsList="$globalConstant.choices.default.statusCodeOption"
                v-model="filters.status_code"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          small
          class="rounded-lg"
          id="resetFilter"
          @click="resetFilters()"
        >
          Reset
        </v-btn>
        <v-btn
          small
          color="primary"
          class="rounded-lg"
          id="applyFilter"
          @click="applyFilters()"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";

export default {
  components: { BaseDatePickerInput, BaseSelect },
  props: {
    title: String,
    value: Boolean,
  },
  data() {
    return {
      filters: {},
      clientList: [],
    };
  },
  watch: {
    showFilterDialog(value) {
      if (value) {
        let filters = localStorage.getItem(this.title + "Filters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.filters = filters;
      }
    },
  },
  computed: {
    showFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    applyFilters() {
      localStorage.setItem(
        this.title + "Filters",
        JSON.stringify(this.filters)
      );
      this.$emit("applyFilters");
      this.showFilterDialog = false;
    },
    resetFilters() {
      this.filters = {};
      localStorage.removeItem(this.title + "Filters");
      this.$emit("applyFilters");
      this.showFilterDialog = false;
      if (this.$refs.webhookFilters) {
        this.$refs.webhookFilters.reset();
      }
    },
  },
};
</script>
