import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-space-between pa-8",attrs:{"cols":"12"}},[_c('div',[_c('h3',{staticClass:"text-uppercase text-h6 font-weight-bold"},[_vm._v("Dashboard")])])]),_c(VCol,{staticClass:"px-8 pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c(VRow,_vm._l((_vm.dashboardStatistics),function(value,key,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"2","sm":"2"}},[_c(VCard,{staticClass:"rounded-lg secondary",attrs:{"elevation":"1"}},[_c(VCardTitle,{staticClass:"d-flex justify-center"},[_c('h3',{staticClass:"text--primary font-weight-bold text-body-2 text-capitalize"},[_vm._v(" "+_vm._s(key.replace(/_/g, " "))+" ")])]),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c(VIcon,{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(_vm.dashboardIcon[key])+" ")]),_c('span',{staticClass:"text-body-1 text--primary font-weight-bold"},[_vm._v(_vm._s(value))])],1)],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }