<template>
  <v-row>
    <v-col cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="editDetails()">
            <v-icon medium color="green">mdi-pencil</v-icon>
          </v-btn>
        </template>
        Update Details
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewDetails()">
            <v-icon color="primary" medium>mdi-eye</v-icon>
          </v-btn>
        </template>
        View Details
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.data.is_webhook_enabled"
            v-bind="attrs"
            icon
            v-on="on"
            @click="webhookDisable()"
          >
            <v-icon color="green" large> mdi-toggle-switch </v-icon>
          </v-btn>
          <v-btn v-else v-bind="attrs" icon v-on="on" @click="webhookEnable()">
            <v-icon color="grey" large>mdi-toggle-switch-off</v-icon>
          </v-btn>
        </template>
        <span>
          Webhook
          {{ params.data.is_webhook_enabled ? "Disable" : "Enable" }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            v-on="on"
            :disabled="!params.data.is_webhook_enabled"
            @click="openClientConfigurationForm()"
          >
            <v-icon medium color="primary">mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>Update Configuration</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="copyClientApiKey()">
            <v-icon medium color="black">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>Copy API Key</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewClientLogs()">
            <v-icon color="primary">mdi-math-log</v-icon>
          </v-btn>
        </template>
        Client Logs
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    editDetails() {
      this.params.context.parentComponent.editDetails(this.params.data.id);
    },
    webhookEnable() {
      this.params.context.parentComponent.webhookEnable(this.params.data.id);
    },
    webhookDisable() {
      this.params.context.parentComponent.webhookDisable(this.params.data.id);
    },
    openClientConfigurationForm() {
      this.params.context.parentComponent.openClientConfigurationForm(
        this.params.data.id
      );
    },
    copyClientApiKey() {
      this.params.context.parentComponent.copyApiKey(this.params.data.api_key);
    },
    viewClientLogs() {
      this.params.context.parentComponent.viewClientConfigurationLogs(
        this.params.data
      );
    },
  },
};
</script>

<style></style>
