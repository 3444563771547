import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"title":`${_vm.editMode ? 'Edit' : 'Add'} Integration Partner`,"width":"50vh"},on:{"closeDialog":function($event){_vm.resetForm(), (_vm.showIntegrationPartnerForm = false)}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"IntegrationPartnerForm",attrs:{"id":"IntegrationPartnerForm","name":"IntegrationPartnerForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","hide-details":"auto","dense":"","label":"Integration Partner Name*","rules":[
              (val) => !!val || 'Integration Partner Name is required',
            ],"error-messages":_vm.formErrors && _vm.formErrors.name ? _vm.formErrors.name : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.name ? delete _vm.formErrors.name : ''}},model:{value:(_vm.integrationPartnerDetails.name),callback:function ($$v) {_vm.$set(_vm.integrationPartnerDetails, "name", $$v)},expression:"integrationPartnerDetails.name"}})],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c(VBtn,{staticClass:"rounded-lg",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.$refs.IntegrationPartnerForm.reset()}}},[_vm._v(" Reset ")]):_vm._e(),_c(VBtn,{staticClass:"rounded-lg",attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit ")])]},proxy:true}]),model:{value:(_vm.showIntegrationPartnerForm),callback:function ($$v) {_vm.showIntegrationPartnerForm=$$v},expression:"showIntegrationPartnerForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }