<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  methods: {
    async checkIfLoggedIn() {
      let user = localStorage.getItem("user");
      if (!user) {
        if (this.$route.path !== "/login") {
          this.$router.push("/login");
        }
      } else if (this.$route.path !== "/dashboard") {
        this.$router.push("/dashboard");
      }
    },
  },
  beforeMount() {
    this.checkIfLoggedIn();
  },
};
</script>

<style></style>
