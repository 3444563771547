import { vendor } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getVendorList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendor.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorApiList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendor.vendor_api, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorApiObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendor.vendor_api}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addVendorApi(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(vendor.vendor_api, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editVendorApi(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${vendor.vendor_api}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorApiChoicesList(params) {
    return new Promise((resolve, reject) => {
      axios
        .options(vendor.vendor_api, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorMetaDataList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendor.vendor_metadata, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorMetadataObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendor.vendor_metadata}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addVendorMetadata(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(vendor.vendor_metadata, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editVendorMetadata(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${vendor.vendor_metadata}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVendorMetadataTypeList(params) {
    return new Promise((resolve, reject) => {
      axios
        .options(vendor.vendor_metadata, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
