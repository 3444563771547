import { vendorAPIRequestLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  // Add New TrackShipmentLogsList API Get Method here
  
  //Track Vendor AWB
  getVendorAwbTrackingDetails(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendorAPIRequestLogs.shipmentTracking, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTrackShipmentLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorAPIRequestLogs.shipmentTracking}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Hub To Organization Logs
  getHubToOrganizationLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendorAPIRequestLogs.hubToOrganization, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getHubToOrganizationLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorAPIRequestLogs.hubToOrganization}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Hub To Vendor Logs
  getHubToVendorLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendorAPIRequestLogs.hubToVendor, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getHubToVendorLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorAPIRequestLogs.hubToVendor}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  // Organization To Hub Logs
  getOrganizationToHubLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vendorAPIRequestLogs.organizationToHub, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationToHubLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vendorAPIRequestLogs.organizationToHub}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
