<template>
  <v-dialog
    v-model="showExportDialog"
    @submit="exportData()"
    @reset="resetFilters()"
    width="30%"
    >
    <v-card>
      <v-card-title>
        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <h3 class="text-body-1 text--primary font-weight-bold">
              {{ title }} Export Data
            </h3>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-icon @click="showExportDialog = false"> mdi-close </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-form v-model="isValid" ref="exportReportForm">
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-1 pl-1 pb-3">
              <BaseDatePickerInput
                v-model="report.start_date"
                id="start_date"
                name="start_date"
                :rules="[(val) => !!val || 'Start Date is required']"
                label="Start Date*"
              ></BaseDatePickerInput>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="pt-1 pl-1 pb-3">
              <BaseDatePickerInput
                v-model="report.end_date"
                id="end_date"
                name="end_date"
                :min="report.start_date"
                :rules="[(val) => !!val || 'End Date is required']"
                label="End Date*"
              ></BaseDatePickerInput>
            </v-col>
            <v-col
              v-if="!isUserTypeOrganization"
              cols="12"
              md="12"
              class="pl-1 pb-3"
            >
              <BaseSelect
                label="Organization"
                :isClearable="true"
                :itemsList="organizationList"
                item-text="name"
                item-value="id"
                multiple
                v-model="report.organization"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="pl-1 pb-3"
              v-if="title == 'Vendor Track Shipment'"
            >
              <BaseSelect
                label="Vendor"
                :isClearable="true"
                :itemsList="vendorList"
                item-text="display_value"
                item-value="value"
                multiple
                v-model="report.vendor"
              />
            </v-col>
            <v-col cols="12" md="12" class="pl-1 pb-3" v-else>
              <BaseSelect
                label="Client"
                :isClearable="true"
                :itemsList="clientList"
                item-text="name"
                item-value="id"
                multiple
                v-model="report.client"
              />
            </v-col>
            <v-col cols="6" md="6" class="pl-1 pb-3">
              <BaseSelect
                label="Status"
                :isClearable="true"
                :itemsList="$globalConstant.choices.default.statusOption"
                item-text="label"
                item-value="value"
                v-model="report.is_success"
              />
            </v-col>
            <v-col cols="6" md="6" class="pl-1 pb-3">
              <BaseSelect
                label="Status Code"
                :isClearable="true"
                item-text="label"
                item-value="value"
                :itemsList="$globalConstant.choices.default.statusCodeOption"
                v-model="report.status_code"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          small
          color="primary"
          customClass="rounded-lg"
          id="reset"
          @click="$refs.exportReportForm.reset()"
        >
          Reset
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!isValid"
          small
          customClass="rounded-lg"
          id="submit"
          @click="exportData()"
          >Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script>
import BaseDatePickerInput from "@/components/BaseComponents/BaseDatePickerInput.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";

import { bus } from "@/main";
export default {
  name: "ExportReportDialog",
  components: { BaseDatePickerInput, BaseSelect },
  props: {
    title: String,
    value: Boolean,
    request_type: String,
    request_for: String,
  },
  data() {
    return {
      isValid: true,
      report: {},
      clientList: [],
      organizationList: [],
      vendorList: [],
    };
  },
  watch: {
    showExportDialog(value) {
      if (value) {
        this.getOrganizationOptions();
        this.getClientList();
        this.getOrganizationVendorList();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showExportDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getClientList() {
      this.$api.client
        .getClientList({ limit: "all" })
        .then((res) => {
          this.clientList = res?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizationVendorList() {
      this.$api.organizationVendor
        .getVendorOptions({ request_type: "form" })
        .then((res) => {
          this.vendorList = res.data.vendor;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    exportData() {
      let data = {
        payload: { ...this.report },
        request_type: this.request_type,
        request_for: this.request_for,
      };
      this.$api.report
        .generateReport(data)
        .then((res) => {
          bus.$emit("showToastMessage", {
            message: "Report Generated!",
            color: "success",
          });
          this.resetForm();
          this.showExportDialog = false;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetFilters() {
      this.report = {};
    },
    resetForm() {
      const form = this.$refs.exportReportForm;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>
  