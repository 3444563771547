<template>
  <BaseAppBar class="white">
    <template #appBarLogo>
      <v-img style="width: 150px" :src="$globalConstant.logo" />
    </template>
    <template #userNotifications>
      <BaseNotification />
    </template>
    <template #userProfile>
      <v-btn x-small text><v-icon>mdi-dots-vertical</v-icon></v-btn>
    </template>
    <template #listItemsMenu>
      <v-card elevation="0" width="auto" min-width="300">
        <v-row no-gutters class="light_background">
          <v-col cols="4" class="text-center py-3 px-0">
            <v-avatar>
              <v-img
                class="mx-5 pr-4 profile-image"
                style="height: 50px !important; width: 50px !important"
                contain
                :src="$globalConstant.profileImage"
                alt="User"
              />
            </v-avatar>
          </v-col>
          <v-col cols="8" class="d-flex flex-column justify-center">
            <h4
              class="primary--text text-capitalize d-flex justify-space-between"
            >
              <span>
                {{ loggedUser.first_name }}
                {{ loggedUser.last_name }}
              </span>
            </h4>
            <h4
              class="secondary--text text-body-2 font-weight-light text-capitalize"
            >
              {{ loggedUser.user_type }}
            </h4>
          </v-col>
        </v-row>
        <v-card-text class="px-8">
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">mdi-account</v-icon>
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.username }}
              </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 green--text">
                {{
                  loggedUser.is_active ? "mdi-check-circle" : "mdi-close-circle"
                }}
              </v-icon>
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.is_active ? "Active" : "Inactive" }}
              </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">
                mdi-office-building</v-icon
              >
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.organization_name }}
              </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">mdi-account</v-icon>
            </div>
            <div>
              <span class="text-caption">
                {{ loggedUser.client }}
              </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon small class="mr-4 primary--text">mdi-email</v-icon>
            </div>
            <div>
              <span class="text-caption"> {{ loggedUser.email }} </span>
            </div>
          </div>
          <div class="d-flex my-2">
            <div>
              <v-icon
                small
                class="mr-4 primary--text"
                @click="showChangePasswordForm = true"
                >mdi-key-variant</v-icon
              >
            </div>
            <div>
              <span class="text-caption"> Change Password </span>
            </div>
          </div>
        </v-card-text>
        <v-card-text class="d-flex justify-end light_background">
          <v-btn
            class="rounded-lg primary"
            depressed
            small
            @click="$emit('logout')"
          >
            <v-icon small color="">mdi-logout</v-icon>
            Logout
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
    <template #dialogs>
      <ChangePasswordForm
        v-model="showChangePasswordForm"
        :userId="loggedUser?.id"
      />
    </template>
  </BaseAppBar>
</template>

<script>
import BaseAppBar from "@/components/BaseComponents/BaseAppBar.vue";
import BaseNotification from "./BaseNotification.vue";
import ChangePasswordForm from "@/pages/Configuration/user/ChangePasswordForm.vue";

export default {
  components: { BaseAppBar, BaseNotification, ChangePasswordForm },
  data() {
    return {
      loggedUser: {},
      showChangePasswordForm: false,
    };
  },
  methods: {},
  mounted() {
    const userProfile = localStorage.getItem("profile");
    this.loggedUser = JSON.parse(userProfile);
  },
};
</script>

<style></style>
