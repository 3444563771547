<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        ref="configurationList"
        title="API Configuration"
        :table-header="columns"
        :table-data="tableData"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        name="configurationList"
        :showAddButton="true"
        :showBackIcon="true"
        :showColumnSelection="false"
        @getList="getOrganizationConfigurationList"
      >
        <template #addButton>
          <span class="pr-10 text--primary text-sm-body-2">
            Organization : {{ $route.params.name }}
          </span>
        </template>
      </BaseListLayout>
      <BaseDialog
        v-model="showUpdateConfigDialog"
        title="Update Configuration"
        max-width="400"
        @closeDialog="showUpdateConfigDialog = false"
      >
        <template #dialogContent>
          <v-select
            label="Select Days"
            dense
            outlined
            hide-details
            v-model="organizationConfigurationForm.no_of_day"
            :items="[7, 15, 30, 60, 90]"
          >
          </v-select>
        </template>
        <template #actions>
          <v-btn color="primary" small @click="updateOrganizationConfiguration">
            Update
          </v-btn>
        </template>
      </BaseDialog>
    </v-col>
  </v-row>
</template>
<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import { bus } from "@/main";

export default {
  name: "Configuration",
  components: { BaseListLayout, ActionButton, BaseDialog },
  data() {
    return {
      fromOrganizationConfiguration: true,
      canEdit: true,
      showUpdateConfigDialog: false,
      totalItems: 0,
      tableData: [],
      organizationConfigurationForm: {},
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    columns() {
      return [
        { headerName: "Log Type", field: "log_type" },
        { headerName: "No Of Days", field: "no_of_day" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
        },
      ];
    },
  },
  methods: {
    getOrganizationConfigurationList(params = {}) {
      params.organization = this.$route.params.id;
      bus.$emit("showLoader", true);
      this.$api.organization
        .getOrganizationConfigurationList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.tableData = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Error fetching data",
            color: "red",
          });
        });
    },
    editDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.organization
        .getOrganizationConfigurationObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.organizationConfigurationForm = res.data;
          this.showUpdateConfigDialog = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    updateOrganizationConfiguration() {
      bus.$emit("showLoader", true);
      this.$api.organization
        .updateOrganizationConfiguration({
          id: this.organizationConfigurationForm.id,
          data: this.organizationConfigurationForm,
        })
        .then(() => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Organization Configuration updated!",
            color: "success",
          });
          this.showUpdateConfigDialog = false;
          this.updateList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.formErrors = err.data;
        });
    },
    updateList() {
      this.$refs.configurationList.updateList();
    },
  },
};
</script>