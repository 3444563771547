import { handleError, handleResponse } from "@/utils/functions.js";
import { auth } from "@/utils/constants";

export default (axios) => ({
  login(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${auth.token}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  refreshToken(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${auth.refresh}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
