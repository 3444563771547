<template>
  <BaseDialog
    v-model="showUserForm"
    :title="`${editMode ? 'Edit' : 'Add'} User`"
    width="80vh"
    @closeDialog="resetForm(), (showUserForm = false)"
  >
    <template #dialogContent>
      <v-form v-model="isValid" ref="userForm" id="userForm" name="userForm">
        <v-row no-gutters>
          <v-col cols="6" class="pr-3 pb-3">
            <v-text-field
              outlined
              dense
              hide-details="auto"
              label="First Name*"
              :rules="[(val) => !!val || 'First Name is required']"
              v-model="userDetails.first_name"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Last Name*"
              :rules="[(val) => !!val || 'Last Name is required']"
              v-model="userDetails.last_name"
            />
          </v-col>
          <v-col cols="6" class="pr-3 pb-3">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="User Name*"
              :rules="[(val) => !!val || 'User Name is required']"
              v-model="userDetails.username"
              :error-messages="
                formErrors && formErrors.username ? formErrors.username : ''
              "
              @input="
                formErrors && formErrors.username
                  ? delete formErrors.username
                  : ''
              "
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Email ID"
              v-model="userDetails.email"
              :error-messages="
                formErrors && formErrors.email ? formErrors.email : ''
              "
              @input="
                formErrors && formErrors.email ? delete formErrors.email : ''
              "
            />
          </v-col>
          <v-col cols="6" class="pr-3 pb-3" v-if="!editMode">
            <v-text-field
              type="password"
              outlined
              hide-details="auto"
              dense
              label="Password*"
              :rules="
                editMode
                  ? []
                  : [
                      (val) => !!val || 'Password is required',
                      (val) =>
                        (val && val.length >= 8) ||
                        'Password must be 8 character long',
                    ]
              "
              v-model="userDetails.password"
            />
          </v-col>
          <v-col cols="6" v-if="!editMode">
            <v-text-field
              type="password"
              outlined
              hide-details="auto"
              dense
              label="Confirm Password*"
              :rules="
                editMode
                  ? []
                  : [
                      (val) => !!val || 'Confirm Password is required',
                      (val) =>
                        !userDetails.password ||
                        val === userDetails.password ||
                        'Password & Confirm Password must be same',
                    ]
              "
              v-model="userDetails.confirm_password"
            />
          </v-col>
          <v-col cols="6" class="pr-3 pb-3">
            <BaseSelect
              label="Select Organization"
              :isClearable="true"
              :itemsList="organizationList"
              item-text="name"
              item-value="id"
              v-model="userDetails.organization"
            />
          </v-col>
          <v-col cols="6">
            <BaseSelect
              label="Select Client"
              :itemsList="clientList"
              :isClearable="true"
              item-text="name"
              item-value="id"
              v-model="userDetails.client"
            />
          </v-col>
          <v-col cols="6" class="pr-3">
            <BaseSelect
              label="Select User Type"
              :itemsList="userTypeList"
              :isClearable="true"
              item-text="display_value"
              item-value="value"
              v-model="userDetails.user_type"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn color="primary" v-if="!editMode" small @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()"
        >Submit</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";

export default {
  components: { BaseDialog, BaseSelect },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    userID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      userDetails: {},
      organizationList: [],
      clientList: [],
      userTypeList: [],
    };
  },
  watch: {
    showUserForm(val) {
      if (val) {
        this.getOrganizationList();
        this.getClientList();
        this.getUserTypeList();
        if (this.editMode && this.userID) {
          this.getUserObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showUserForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getOrganizationList() {
      this.$api.organization
        .getOrganizationList({ limit: "all" })
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getClientList() {
      this.$api.client
        .getClientList()
        .then((res) => {
          this.clientList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getUserTypeList() {
      this.$api.user
        .getUserTypeList({ request_type: "form" })
        .then((res) => {
          this.userTypeList = res.data.user_type;
        })
        .catch((err) => {
          console.log("err", err);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getUserObject() {
      this.$api.user
        .getUserObject(this.userID)
        .then((res) => {
          this.userDetails = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.userDetails,
      };
      if (!this.editMode) {
        this.$api.user
          .addUser(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "User Created!",
              color: "success",
            });
            this.resetForm();
            this.showUserForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.user
          .editUser({
            id: this.userDetails.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "User details updated!",
              color: "success",
            });
            this.resetForm();
            this.showUserForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      const form = this.$refs.userForm;
      if (form) {
        form.reset();
      }
    },
  },
};
</script>

<style></style>
