<template>
  <v-row>
    <v-col v-if="params.data.is_success == 'No'">
      <v-tooltip bottom color="#E5E5E5">
        <template v-slot:activator="{ on, attrs }">
          <span
            class="text-white text-body-2 mr-1 font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="red"> mdi-alert </v-icon>
          </span>
        </template>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-list
              dense
              style="background: inherit !important"
              v-for="(error, i) in errors"
              :key="i"
            >
              <v-list-item dense style="min-height: 10px !important">
                <span>{{ error }}</span>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-tooltip>
    </v-col>
  </v-row>
</template>
    
<script>
export default {
  name: "ShipmentAlertCell",
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    checkResponse() {
      return this.params.data.is_success == "No" && this.params.data.response;
    },
  },
  mounted() {
    if (
      this.checkResponse &&
      this.params.data.response.error &&
      typeof this.params.data.response.error == "object" &&
      Object.keys(this.params.data.response.error).length
    ) {
      this.errors = this.params.data.response.error;
    } else if (this.checkResponse && this.params.data.response.message) {
      this.errors = this.params.data.response;
    } else if (this.checkResponse && this.params.data.status_code == 500) {
      this.errors = ["Connection error"];
    }
  },
};
</script>