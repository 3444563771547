<template>
  <BaseDialog
    v-model="showVendorMetadataForm"
    :title="`${editMode ? 'Edit' : 'Add'} Metadata`"
    width="80vh"
    @closeDialog="resetForm(), (showVendorMetadataForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="vendorMetadataForm"
        id="vendorMetadataForm"
        name="vendorMetadataForm"
      >
        <v-row no-gutters>
          <v-col cols="12" class="pb-3">
            <BaseSelect
              label="Metadata Type*"
              :isSearchRequired="false"
              :required="true"
              :itemsList="metadataTypes"
              :isClearable="false"
              item-text="display_name"
              item-value="value"
              :rules="[(val) => !!val || 'Metadata Type is required']"
              v-model="vendorMetadataForm.metadata_type"
              :error-messages="
                formErrors && formErrors.metadata_type
                  ? formErrors.metadata_type
                  : ''
              "
              @change="
                formErrors && formErrors.metadata_type
                  ? delete formErrors.metadata_type
                  : ''
              "
            />
          </v-col>
          <v-col cols="12">
            <JsonEditorVue
              :mode="'code'"
              v-model="vendorMetadataForm.metadata"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn color="primary" v-if="!editMode" small @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()">
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseDialog, BaseSelect, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    vendorMetaDataId: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      vendorMetadataForm: {},
      metadataTypes: [],
    };
  },
  watch: {
    showVendorMetadataForm(val) {
      if (val) {
        this.getVendorMetadataTypeList();
        if (this.editMode && this.vendorMetaDataId) {
          this.getVendorMetadataObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showVendorMetadataForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getVendorMetadataTypeList() {
      this.$api.vendor
        .getVendorMetadataTypeList({ limit: "all" })
        .then((res) => {
          this.metadataTypes = res.data.actions.POST.metadata_type.choices;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    getVendorMetadataObject() {
      this.$api.vendor
        .getVendorMetadataObject(this.vendorMetaDataId)
        .then((res) => {
          this.vendorMetadataForm = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        vendor: this.$route.params.name,
        ...this.vendorMetadataForm,
      };
      if (!this.editMode) {
        this.$api.vendor
          .addVendorMetadata(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Vendor Metadata Added!",
              color: "success",
            });
            this.resetForm();
            this.showVendorMetadataForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.vendor
          .editVendorMetadata({
            id: this.vendorMetadataForm.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Vendor Metadata updated!",
              color: "success",
            });
            this.resetForm();
            this.showVendorMetadataForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      const form = this.$refs.vendorMetadataForm;
      if (form) {
        form.reset();
      }
      this.vendorMetadataForm.metadata = null;
    },
  },
};
</script>

<style></style>
