import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const defaultTheme = {
  primary: "#112F4F",
  secondary: "#17B7D0",
  accent: "#82B1FF",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FB8C00",
  heading: "#90a4ae",
  background_color: "#1976D2",
};
export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      dark: true,
      light: {
        ...defaultTheme,
      },
    },
  },
});
