<template>
  <v-row>
    <!------------------------------------------ Inbound Request Start -------------------------------------------------->
    <v-col v-if="params.column.colId == 'reference_number'" cols="12">
      <span
        v-if="$route.path == '/vendor-shipment/list'"
        class="primary--text font-weight-bold pointer"
        @click="viewVendorDetails()"
      >
        {{ params.data.reference_number }}
      </span>
      <span
        v-else
        class="primary--text font-weight-bold pointer"
        @click="viewDetails()"
      >
        {{ params.data.reference_number }}
      </span>
    </v-col>

    <v-col v-if="params.column.colId == 'vendor_awb'" cols="12">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            class="primary--text font-weight-bold pointer"
          >
            {{ params.data.vendor_awb }}
            <v-icon v-if="params.data.vendor_awb" small color="primary"
              >mdi-arrow-top-right-thin</v-icon
            >
          </span>
        </template>
        <span> Go To Vendor Details </span>
      </v-tooltip>
    </v-col>

    <v-col
      v-if="
        params.column.colId == 'awb_number' ||
        params.column.colId == 'order_awb'
      "
      cols="12"
    >
      <!-- <span
        v-if="$route.path == '/vendor-shipment/list'"
        class="primary--text font-weight-bold pointer"
        @click="viewVendorDetails()"
      >
        {{ params.data.awb_number }}
        <v-icon v-if="params.data.awb_number" small color="primary"
          >mdi-arrow-top-right-thin</v-icon
        >
      </span> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            class="primary--text font-weight-bold pointer"
          >
            {{
              params.data.awb_number
                ? params.data.awb_number
                : params.data.order_awb
            }}
            <v-icon
              v-if="params.data.awb_number || params.data.order_awb"
              small
              color="primary"
              >mdi-arrow-top-right-thin</v-icon
            >
          </span>
        </template>
        <span>Go To AWB Details</span>
      </v-tooltip>
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'created'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1" v-bind="attrs" v-on="on">
            {{ formatLastUpdatedTime(params.data.created) }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ getDate }}
        </span>
      </v-tooltip>
      <br />
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'modified'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1" v-bind="attrs" v-on="on">
            {{ formatLastUpdatedTime(params.data.modified) }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ getModifiedDate }}
        </span>
      </v-tooltip>
      <br />
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'last_updated'">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="mr-1" v-bind="attrs" v-on="on">
            {{
              params.data.last_updated
                ? formatLastUpdatedTime(params.data.last_updated)
                : ""
            }}
          </span>
        </template>
        <span class="text-white font-weight-bold">
          {{ getLastUpdatedDate }}
        </span>
      </v-tooltip>
      <br />
    </v-col>

    <v-col v-if="params.column.colId == 'is_success'">
      <v-tooltip
        content-class="custom-tooltip"
        color="#E5E5E5"
        bottom
        v-if="
          (params.data.is_success == 'No' || params.data.is_success == false) &&
          params.data.response !== null
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            class="text-white text-body-2 mr-1 font-weight-bold"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="red">mdi-close-circle</v-icon>
          </span>
        </template>
        <v-row v-if="nonFieldErrors">
          <v-col cols="12" class="pa-0">
            <v-list dense style="background: inherit !important">
              <v-list-item dense style="min-height: 10px !important">
                <span>{{ nonFieldErrors }}</span>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="pa-0">
            <v-list
              dense
              style="background: inherit !important"
              v-for="(error, i) in errors"
              :key="i"
            >
              <v-list-item dense style="min-height: 10px !important">
                <span>{{ error }}</span>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-tooltip>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <v-col v-if="params.column.colId == 'has_updated'">
      <v-icon v-if="params.data.has_updated == 'No'" color="red">
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'has_resend'">
      <v-icon v-if="params.data.has_resend == 'No'" color="red">
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <v-col cols="12" v-if="params.column.colId == 'is_terminating'">
      <v-icon v-if="params.data.is_terminating == false" color="red">
        mdi-close-circle
      </v-icon>
      <v-icon v-else color="green">mdi-checkbox-marked-circle</v-icon>
    </v-col>

    <!-------------------------------------------- Inbound Request End -------------------------------------------------->
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  data() {
    return {
      errors: [],
      nonFieldErrors: "",
    };
  },
  computed: {
    getDate() {
      return convertDateTime(this.params.data.created);
    },
    getModifiedDate() {
      return convertDateTime(this.params.data.modified);
    },
    getLastUpdatedDate() {
      return this.params.data.last_updated
        ? convertDateTime(this.params.data.last_updated)
        : null;
    },
    checkResponse() {
      return this.params.data.is_success == "No" && this.params.data.response;
    },
    isCreatedColumn() {
      return (
        (this.params.context.parentComponent.fromClientConfigure ||
          this.params.context.parentComponent.fromOrganizationConfigure) &&
        this.params.column.colId == "created"
      );
    },
    isModifiedColumn() {
      return (
        (this.params.context.parentComponent.fromClientConfigure ||
          this.params.context.parentComponent.fromOrganizationConfigure) &&
        this.params.column.colId == "modified"
      );
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    formatLastUpdatedTime(value) {
      return formatLastUpdated(value);
    },
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    viewVendorDetails() {
      this.params.context.parentComponent.viewVendorDetails(
        this.params.data.id,
        this.params.data.awb
      );
    },
    lastUpdated() {
      formatLastUpdated(this.params.data.updated);
    },
  },
  mounted() {
    if (
      this.checkResponse &&
      this.params.data.response.error &&
      typeof this.params.data.response.error == "object" &&
      Object.keys(this.params.data.response.error).length
    ) {
      this.errors = this.params.data.response.error;
    } else if (this.checkResponse && this.params.data.response.message) {
      this.errors = this.params.data.response;
    } else if (this.checkResponse && this.params.data.status_code == 500) {
      this.errors = ["Connection error"];
    } else if (this.checkResponse && this.checkResponse.non_field_errors) {
      this.nonFieldErrors = this.checkResponse.non_field_errors;
    }
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
