<template>
  <BaseDialog
    v-model="showIntegrationPartnerApiForm"
    :title="`${editMode ? 'Edit' : 'Add'} Integration Partner Api`"
    width="70vh"
    @closeDialog="resetForm(), (showIntegrationPartnerApiForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="IntegrationPartnerApiForm"
        id="IntegrationPartnerApiForm"
        name="IntegrationPartnerApiForm"
      >
        <v-row>
          <v-col
            cols="12"
            v-if="
              formErrors.non_field_errors &&
              formErrors.non_field_errors.length > 0
            "
            class="pa-0"
          >
            <v-alert dense type="error">
              <v-list
                dense
                style="background: inherit !important"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                <v-list-item dense style="min-height: 10px !important">
                  <span>{{ error }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
          <v-col cols="12" class="pa-2">
            <BaseSelect
              :required="true"
              label="Integration Partner Name*"
              item-text="name"
              item-value="id"
              :itemsList="integrationPartnerList"
              disabled
              v-model="integrationPartnerApiForm.integration_partner"
            />
          </v-col>
          <v-col cols="6" class="pa-2">
            <BaseSelect
              :required="true"
              label="Request For*"
              item-text="display_value"
              item-value="value"
              :itemsList="requestForList"
              :rules="[(val) => !!val || 'Request For is required']"
              :error-messages="
                formErrors && formErrors.request_for
                  ? formErrors.request_for
                  : ''
              "
              v-model="integrationPartnerApiForm.request_for"
              @input="
                formErrors && formErrors.request_for
                  ? delete formErrors.request_for
                  : ''
              "
            />
          </v-col>
          <v-col cols="6" class="pa-2">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Integration Partner Api name*"
              :rules="[
                (val) => !!val || 'Integration Partner Api Name is required',
              ]"
              :error-messages="
                formErrors && formErrors.api_name ? formErrors.api_name : ''
              "
              v-model="integrationPartnerApiForm.api_name"
              @input="
                formErrors && formErrors.api_name
                  ? delete formErrors.api_name
                  : ''
              "
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              type="url"
              hide-details="auto"
              dense
              label="Integration Partner Api url*"
              :rules="[
                (val) => !!val || 'Integration Partner Api url is required',
              ]"
              :error-messages="
                formErrors && formErrors.api_url ? formErrors.api_url : ''
              "
              v-model="integrationPartnerApiForm.api_url"
              @input="
                formErrors && formErrors.api_url
                  ? delete formErrors.api_url
                  : ''
              "
            />
          </v-col>
          <v-col cols="12" class="pa-2">
            Schema
            <JsonEditorVue
              :mode="'code'"
              v-model="integrationPartnerApiForm.payload_schema"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        v-if="!editMode"
        class="rounded-lg"
        small
        @click="resetForm()"
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!isValid"
        class="rounded-lg"
        small
        @click="submitForm()"
      >
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>
    
    <script>
import { bus } from "@/main";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseSelect, BaseDialog, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    integrationPartnerID: {
      type: Number,
    },
    integrationPartnerApiID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      integrationPartnerApiForm: {},
      requestForList: [],
      integrationPartnerList: [],
    };
  },

  computed: {
    showIntegrationPartnerApiForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showIntegrationPartnerApiForm(val) {
      if (val) {
        this.getIntegrationPartnerList();
        this.getRequestForList();
        if (this.editMode && this.integrationPartnerApiID) {
          this.getIntegrationPartnerApiObject(this.integrationPartnerApiID);
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    getIntegrationPartnerList() {
      let params = { limit: "all" };
      this.$api.integrationPartner
        .getIntegrationPartnerSelectionList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.integrationPartnerList = res.data;
          this.integrationPartnerApiForm.integration_partner =
            this.integrationPartnerID;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    getRequestForList() {
      this.$api.organizationUrl
        .getRequestForOptions({ request_type: "form" })
        .then((res) => {
          this.requestForList = res.data.request_for;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getIntegrationPartnerApiObject(id) {
      this.$api.integrationPartner
        .getIntegrationPartnerApiObject(id)
        .then((res) => {
          this.integrationPartnerApiForm = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.IntegrationPartnerApiForm;
      if (form) {
        form.reset();
      }
      this.integrationPartnerApiForm.payload_schema = null;
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.integrationPartnerApiForm,
      };
      if (!this.editMode) {
        this.$api.integrationPartner
          .addIntegrationPartnerApi(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Integration Partner Api created!",
              color: "success",
            });
            this.resetForm();
            this.showIntegrationPartnerApiForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.integrationPartner
          .editIntegrationPartnerApi(this.integrationPartnerApiForm.id, data)
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Integration Partner Api details updated!",
              color: "success",
            });
            this.resetForm();
            this.showIntegrationPartnerApiForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
  },
};
</script>
    
  <style>
</style>