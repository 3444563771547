<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        name="UserList"
        ref="userList"
        title="User"
        searchable
        :table-header="columns"
        :table-data="userList"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        localStorageKey="userColumns"
        @getList="getUserList"
      >
        <template #addButton>
          <v-btn color="primary" small depressed @click="addUser">
            Add User
          </v-btn>
        </template>
        <template #leftFilterSlot>
          <v-row class="pa-0 ma-0 d-flex">
            <v-col cols="3" class="pa-0 ma-0 d-flex">
              <BaseSelect
                class="pl-2 rounded-pill"
                label="Organization"
                :isClearable="true"
                :itemsList="organizationList"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.organization"
                @change="
                  applyFilter();
                  getClientList();
                "
              />
            </v-col>
            <v-col cols="3" class="pa-0 ma-0 d-flex">
              <BaseSelect
                class="pl-2 rounded-pill"
                label="Client"
                :isClearable="true"
                :itemsList="clientList"
                item-text="name"
                item-value="id"
                multiple
                v-model="filters.client"
                @change="applyFilter"
              />
            </v-col>
          </v-row>
        </template>
        <template #dialogs>
          <UserForm
            ref="userForm"
            v-model="showUserForm"
            :editMode="editMode"
            :userID="userID"
            @updateList="updateList"
          />
        </template>
      </BaseListLayout>
    </v-col>
  </v-row>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import UserForm from "./UserForm.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { bus } from "@/main";

export default {
  name: "User",
  components: {
    BaseListLayout,
    UserForm,
    BaseSelect,
    ActionButton,
  },
  data() {
    return {
      fromUser: true,
      userID: null,
      totalItems: 0,
      userList: [],
      filters: {},
      showUserForm: false,
      editMode: false,
      clientList: [],
      organizationList: [],
      columns: [
        {
          headerName: "First Name",
          field: "first_name",
          minWidth: 100,
          pinned: "left",
        },
        { headerName: "Last Name", field: "last_name", minWidth: 100 },
        { headerName: "Username", field: "username", minWidth: 100 },
        { headerName: "Email", field: "email", minWidth: 100 },
        {
          headerName: "Organization",
          field: "organization_name",
          minWidth: 100,
        },
        { headerName: "Client", field: "client", minWidth: 100 },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
          pinned: "right",
        },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getUserList(params) {
      bus.$emit("showLoader", true);
      let filters = localStorage.getItem("User_Filters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("organization" in filters && filters != null) {
        filters.organization = filters.organization.join(",");
      }
      if ("client" in filters && filters != null) {
        filters.client = filters.client.join(",");
      }
      if ("search" in filters) {
        filters.search = filters.search.toString();
      }

      this.$api.user
        .getUserList({ ...filters, ...params })
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.userList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    refreshList() {
      this.$refs.userList.refreshList();
    },
    updateList() {
      this.$refs.userList.updateList();
    },
    addUser() {
      this.editMode = false;
      this.showUserForm = true;
    },
    editDetails(id) {
      this.editMode = true;
      this.userID = id;
      this.showUserForm = true;
    },
    getClientList() {
      let org =
        this.filters &&
        this.filters.organization &&
        this.filters.organization.length
          ? this.filters.organization.join(",")
          : null;
      this.$api.client
        .getClientList({ limit: "all", organization: org })
        .then((res) => {
          this.clientList = res?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizationOptions() {
      this.$api.organization
        .getOrganizationOptions()
        .then((res) => {
          this.organizationList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    applyFilter() {
      localStorage.setItem("User_Filters", JSON.stringify(this.filters));
      this.refreshList();
    },
  },
  mounted() {
    this.getOrganizationOptions();
    let f = localStorage.getItem("User_Filters");
    if (f) {
      this.filters = JSON.parse(f);
    }
    this.getClientList();
  },
};
</script>
