<template>
  <v-app>
    <router-view />
    <v-snackbar
      v-model="showToastMessage"
      :color="snackBarColor"
      class="text-capitalize"
      timeout="2000"
      vertical
    >
      {{ snackbarMessage }}
    </v-snackbar>
    <v-overlay :value="showLoader" z-index="1000">
      <v-progress-circular color="primary" indeterminate size="64">
      </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { bus } from "./main";

export default {
  name: "App",

  data: () => ({
    showToastMessage: false,
    snackBarColor: "primary",
    snackbarMessage: "",
    showLoader: false,
  }),
  mounted() {
    bus.$on("showToastMessage", ({ color, message }) => {
      this.snackBarColor = color;
      this.snackbarMessage = message;
      this.showToastMessage = true;
    });
    bus.$on("showLoader", (value) => {
      this.showLoader = value;
    });
  },
  beforeDestroy() {
    bus.$off("showToastMessage");
    bus.$off("showLoader");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
</style>

