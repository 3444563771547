<template>
  <v-row class="ma-0">
    <v-col cols="6" class="pa-0 ma-0 pr-2">
      <v-card rounded="lg" elevation="1">
        <v-card-title class="py-1 px-3 ma-0 secondary text-white">
          Basic Information
        </v-card-title>
        <v-card-text class="px-3 py-2">
          <v-row
            no-gutters
            v-for="detail in basicDetails"
            :key="detail.label"
            class="mt-1"
          >
            <v-col cols="6">
              {{ detail.label }}
            </v-col>
            <v-col cols="6" class="text-right">
              <span class="primary--text font-weight-bold">
                {{ detail.value ? detail.value : "-" }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" class="pa-0">
      <v-card class="rounded-lg" elevation="1">
        <v-card-title class="py-1 px-3 ma-0 secondary text-white">
          Additional Information
        </v-card-title>
        <v-card-text class="px-3 py-2">
          <v-row
            no-gutters
            v-for="detail in additionalDetails"
            :key="detail.label"
            class="mt-1"
          >
            <v-col cols="6">
              {{ detail.label }}
            </v-col>
            <v-col cols="6" class="text-right">
              <span class="primary--text font-weight-bold">
                {{ detail.value ? detail.value : "-" }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="6" class="pa-0 mt-3">
      <v-card class="rounded-lg" elevation="1">
        <v-card-title class="py-1 px-3 ma-0 secondary text-white">
          Sender Details
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text class="px-3 py-2">
          <v-row
            no-gutters
            v-for="details in senderDetails"
            :key="details.label"
            class="mt-1"
          >
            <v-col cols="4">
              {{ details.label }}
            </v-col>
            <v-col cols="8" class="text-right">
              <span class="primary--text font-weight-bold">
                {{ details.value ? details.value : "-" }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- <v-card class="mt-4 rounded-xl" elevation="0">
        <v-card-title class="ma-0 primary--text">
          Supplier Details
        </v-card-title>
        <v-card-text>
          <v-row
            no-gutters
            v-for="details in supplierDetails"
            :key="details.label"
          >
            <v-col cols="4">
              {{ details.label }}
            </v-col>
            <v-col cols="8">
              <span class="primary--text">
                {{ details.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> -->
    </v-col>

    <v-col cols="6" class="pa-0 pl-2 mt-3">
      <v-card class="rounded-lg" elevation="1">
        <v-card-title class="py-1 px-3 ma-0 secondary text-white">
          Recipient Details
        </v-card-title>
        <v-card-text class="px-3 py-2">
          <v-row
            no-gutters
            v-for="details in recipientDetails"
            :key="details.label"
            class="mt-1"
          >
            <v-col cols="4">
              {{ details.label }}
            </v-col>
            <v-col cols="8" class="text-right">
              <span class="primary--text font-weight-bold">
                {{ details.value ? details.value : "-" }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  name: "GFSAwbTrack",
  components: {},
  props: {
    vendorDetails: Object,
    trackingDetails: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    requestData() {
      return this.vendorDetails.request_data;
    },
    basicDetails() {
      return [
        {
          label: "Last Update Time",
          value: this.vendorDetails.last_updated_timestamp
            ? convertDateTime(this.vendorDetails.last_updated_timestamp)
            : null,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Org At",
          value: this.vendorDetails.last_update_sent_to_org_at
            ? convertDateTime(this.vendorDetails.last_update_sent_to_org_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_sent_to_org_at) +
              ")"
            : null,
        },
        {
          label: "Last Update Received At",
          value: this.vendorDetails.last_update_received_at
            ? convertDateTime(this.vendorDetails.last_update_received_at) +
              " (" +
              formatLastUpdated(this.vendorDetails.last_update_received_at) +
              ")"
            : null,
        },
        {
          label: "Status",
          value: this.trackingDetails && this.trackingDetails.status_desc,
        },
        {
          label: "Date",
          value:
            this.trackingDetails && this.trackingDetails.date
              ? convertDateTime(this.trackingDetails.date)
              : null,
        },
      ];
    },
    additionalDetails() {
      return [
        {
          label: "Reference Id",
          value: this.requestData?.reference_id,
        },
        {
          label: "Payment Type",
          value: this.requestData?.payment_type,
        },
        {
          label: "Package Type",
          value: this.requestData?.package_type?.courier_type,
        },
        {
          label: "Parcel Value",
          value: this.requestData?.parcel_value,
        },
        {
          label: "Payer",
          value: this.requestData?.payer,
        },
        {
          label: "Dimensions Weight",
          value: this.requestData?.dimensions.weight,
        },
      ];
    },
    createdByDetails() {
      return [
        {
          label: "Name",
          value:
            this.trackingDetails &&
            this.trackingDetails.created_by_user &&
            this.trackingDetails.created_by_user.name,
        },
        {
          label: "Description",
          value:
            this.trackingDetails &&
            this.trackingDetails.created_by_user &&
            this.trackingDetails.created_by_user.description,
        },
      ];
    },
    supplierDetails() {
      return [
        {
          label: "Name",
          value:
            this.trackingDetails &&
            this.trackingDetails.supplier &&
            this.trackingDetails.supplier.name,
        },
        {
          label: "Phone",
          value:
            this.trackingDetails &&
            this.trackingDetails.supplier &&
            this.trackingDetails.supplier.phone,
        },
      ];
    },
    senderDetails() {
      return [
        {
          label: "Name",
          value: this.requestData?.sender_data?.name,
        },
        {
          label: "Street",
          value: this.requestData?.sender_data?.street,
        },
        {
          label: "Building",
          value: this.requestData?.sender_data?.building,
        },
        {
          label: "Apartment",
          value: this.requestData?.sender_data?.apartment,
        },
        {
          label: "Latitude",
          value: this.requestData?.sender_data?.lat,
        },
        {
          label: "Longitude",
          value: this.requestData?.sender_data?.lon,
        },
        {
          label: "Email",
          value: this.requestData?.sender_data?.email,
        },
        {
          label: "Phone",
          value: this.requestData?.sender_data?.phone,
        },
      ];
    },
    recipientDetails() {
      return [
        {
          label: "Name",
          value: this.requestData?.recipient_data?.name,
        },
        {
          label: "Street",
          value: this.requestData?.recipient_data?.street,
        },
        {
          label: "Building",
          value: this.requestData?.recipient_data?.building,
        },
        {
          label: "Apartment",
          value: this.requestData?.recipient_data?.apartment,
        },
        {
          label: "Latitude",
          value: this.requestData?.recipient_data?.lat,
        },
        {
          label: "Longitude",
          value: this.requestData?.recipient_data?.lon,
        },
        {
          label: "Email",
          value: this.requestData?.recipient_data?.email,
        },
        {
          label: "Phone",
          value: this.requestData?.recipient_data?.phone,
        },
      ];
    },
  },
};
</script>

<style></style>
