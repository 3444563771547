import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-list"},[_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-2 list-box-Shadow light_background",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"text-left d-flex align-center",attrs:{"cols":"6"}},[_c('h3',{staticClass:"text-uppercase text-h6 text_color--text font-weight-bold"},[_vm._v(" AWB Track And Trace ")])])],1)],1)],1)],1),_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Search AWBs here..","clearable":"","append-icon":"mdi-magnify"},on:{"click:append":function($event){return _vm.getAwbLogs()},"click:clear":function($event){_vm.searchAWBs = [];
          _vm.ordersList = [];}},model:{value:(_vm.searchAWBs),callback:function ($$v) {_vm.searchAWBs=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchAWBs"}})],1)],1),(_vm.ordersList.length)?_c(VRow,{staticClass:"ma-0"},[_c(VCol,{attrs:{"cols":"12"}},[_c('h5',{staticClass:"text-h6 text_color--text font-weight-bold"},[_vm._v("Shipments")]),_c('hr')]),_c(VCol,{attrs:{"cols":"12"}},[_c('BaseListLayout',{attrs:{"table-header":_vm.columns,"table-data":_vm.ordersList,"context":_vm.context,"total":_vm.totalItems,"name":"AWBsList","localStorageKey":"awbTrackAndTraceColumns"},on:{"getList":_vm.getAwbLogs}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }