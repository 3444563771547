import { inboundLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  //Webhook Logs
  getOrganizationWebhookLogsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(inboundLogs.webhook, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationWebhookLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${inboundLogs.webhook}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkResendOrganizationWebhookLog(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${inboundLogs.webhook}resend_bulk_request/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
