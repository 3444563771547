<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-space-between pa-8">
        <div>
          <h3 class="text-uppercase text-h6 font-weight-bold">Dashboard</h3>
        </div>
      </v-col>

      <v-col cols="12" md="12" sm="12" class="px-8 pt-0">
        <v-row>
          <v-col
            cols="12"
            md="2"
            sm="2"
            v-for="(value, key, index) in dashboardStatistics"
            :key="index"
          >
            <v-card elevation="1" class="rounded-lg secondary">
              <v-card-title class="d-flex justify-center">
                <h3
                  class="text--primary font-weight-bold text-body-2 text-capitalize"
                >
                  {{ key.replace(/_/g, "  ") }}
                </h3>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-icon style="margin-right: 10px">
                      {{ dashboardIcon[key] }}
                    </v-icon>
                    <span class="text-body-1 text--primary font-weight-bold">{{
                      value
                    }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="6" md="4" class="pr-2 pl-8">
        <v-card flat outlined class="rounded-lg" min-height="290">
          <v-card-title>
            <v-row class="d-flex justify-space-between align-center">
              <v-col class="d-flex justify-start">
                <span class="text-body-1 font-weight-bold">Users</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="d-flex justify-center">
            <PieChart style="height: 300px" :ratio="0.7" color="green" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "Dashboard",
  data() {
    return {
      dashboardStatistics: {},
      dashboardIcon: {
        user: "mdi-account-group",
        organization: "mdi-office-building",
        client: "mdi-account-group",
        organization_request_log: "mdi-list-box-outline",
        client_request_log: "mdi-list-box-outline",
      },
    };
  },
  methods: {
    getDashboardStatistics() {
      bus.$emit("showLoader", true);
      this.$api.dashboard
        .getDashboardStatistics()
        .then((res) => {
          bus.$emit("showLoader", false);
          this.dashboardStatistics = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDashboardStatistics();
  },
};
</script>