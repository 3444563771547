import { webhookUpdate } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions.js";

export default (axios) => ({
  getWebhookUpdateList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(webhookUpdate.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  resendWebhookUpdate(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(webhookUpdate.resend_webhook_update, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  // Resend single webhook update
  resendSingleWebhookUpdate(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${webhookUpdate.base}${id}/resend_single_webhook_update/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
