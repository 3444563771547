<template>
  <BaseDialog
    v-model="showIntegrationPartnerDetails"
    :title="`Integration Partner Api (${integrationPartnerDetails.name})`"
    fullscreen
    @closeDialog="showIntegrationPartnerDetails = false"
  >
    <template #dialogContent>
      <v-row>
        <v-col cols="12" class="d-flex justify-end pa-0">
          <v-btn
            small
            color="primary"
            depressed
            @click="addIntegrationPartnerApi"
          >
            Add Integration Partner Api
          </v-btn>
        </v-col>
        <v-col cols="12" class="pa-0">
          <BaseListLayout
            ref="integrationPartnerApi"
            :table-header="columns"
            :table-data="integrationPartnerApiList"
            :context="context"
            :total="totalIntegrationPartnerApi"
            name="integrationPartnerApiList"
            @getList="getIntegrationPartnerApiList"
            :gridStyle="gridStyle"
            :showColumnSelection="false"
          />
          <BaseDialog title="Confirmation" v-model="showDialog" max-width="400">
            <template #dialogContent>
              <span class="text-lg-subtitle-1 font-weight-bold">
                Are you sure you want to proceed ?
              </span>
            </template>
            <template #actions>
              <v-btn color="error" small @click="showDialog = false">
                Cancel
              </v-btn>

              <v-btn color="success" small @click="apply"> Confirm </v-btn>
            </template>
          </BaseDialog>
          <IntegrationPartnerApiForm
            ref="IntegrationPartnerApiForm"
            v-model="showIntegrationPartnerApiForm"
            :editMode="editMode"
            :integrationPartnerID="integrationPartnerID"
            :integrationPartnerApiID="integrationPartnerApiID"
            @refreshList="refreshList"
          />
        </v-col>
      </v-row>
    </template>
  </BaseDialog>
</template>
     
  
  <script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import IntegrationPartnerApiForm from "./IntegrationPartnerApiForm.vue";
import { bus } from "@/main";

export default {
  name: "IntegrationPartnerDetails",
  components: {
    BaseListLayout,
    ActionsButton,
    BaseDialog,
    IntegrationPartnerApiForm,
  },
  props: {
    value: Boolean,
    integrationPartnerDetails: Object,
  },
  data() {
    return {
      canEdit: true,
      canApplyChangesToClient: true,
      showDialog: false,
      integrationPartnerApiList: [],
      totalIntegrationPartnerApi: 0,
      showIntegrationPartnerApiForm: false,
      editMode: null,
      integrationPartnerID: null,
      integrationPartnerApiID: null,

      gridStyle: {
        width: "100%",
        height: "70vh",
      },
      columns: [
        { headerName: "Request For", field: "request_for" },
        { headerName: "Api Name", field: "api_name" },
        { headerName: "Api Url", field: "api_url" },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ],
    };
  },
  watch: {
    showIntegrationPartnerDetails(val) {
      if (val) {
        let params = { offset: 0, limit: 10 };
        this.getIntegrationPartnerApiList(params);
      } else {
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    showIntegrationPartnerDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    refreshList() {
      this.$refs.integrationPartnerApi.refreshList();
    },
    getIntegrationPartnerApiList(params) {
      bus.$emit("showLoader", true);
      params = {
        integration_partner: this.integrationPartnerDetails.id,
        ...params,
      };
      this.$api.integrationPartner
        .getIntegrationPartnerApiList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalIntegrationPartnerApi = res.count;
          this.integrationPartnerApiList = res?.data?.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    addIntegrationPartnerApi() {
      this.editMode = false;
      this.integrationPartnerApiID = null;
      this.integrationPartnerID = this.integrationPartnerDetails.id;
      this.showIntegrationPartnerApiForm = true;
    },
    editDetails(id) {
      this.integrationPartnerApiID = id;
      this.integrationPartnerID = this.integrationPartnerDetails.id;

      this.editMode = true;
      this.showIntegrationPartnerApiForm = true;
    },
    apply() {
      bus.$emit("showLoader", true);
      this.$api.integrationPartner
        .applySchemaChangesToClient(this.integrationPartnerApiID)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
          this.showDialog = false;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
    applyChangesToClient(id) {
      this.showDialog = true;
      this.integrationPartnerApiID = id;
    },
  },
};
</script>
  