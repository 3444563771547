<template>
  <BaseDialog
    v-model="showOrganizationVendorForm"
    :title="`${editMode ? 'Edit' : 'Add'} Vendor`"
    width="90vh"
    @closeDialog="resetForm(), (showOrganizationVendorForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="organizationVendorForm"
        id="organizationVendorForm"
        name="organizationVendorForm"
      >
        <v-row>
          <v-col
            cols="12"
            v-if="
              formErrors.non_field_errors &&
              formErrors.non_field_errors.length > 0
            "
            class="pa-0"
          >
            <v-alert dense type="error">
              <v-list
                dense
                style="background: inherit !important"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                <v-list-item dense style="min-height: 10px !important">
                  <span>{{ error }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
          <v-col cols="12" md="4" sm="4" lg="4" class="pa-1">
            <BaseSelect
              label="Select Vendor*"
              :required="true"
              :itemsList="vendorList"
              :isClearable="false"
              item-text="display_value"
              item-value="value"
              :rules="[(val) => !!val || 'Vendor is required']"
              v-model="organizationVendorForm.vendor"
              :error-messages="
                formErrors && formErrors.vendor ? formErrors.vendor : ''
              "
              @change="
                formErrors && formErrors.vendor ? delete formErrors.vendor : ''
              "
            />
          </v-col>
          <v-col cols="12" md="4" sm="4" lg="4" class="pa-1">
            <v-text-field
              label="Vendor Code*"
              outlined
              dense
              hide-details="auto"
              :rules="[(val) => !!val || 'Vendor Code is required']"
              v-model="organizationVendorForm.code"
              :error-messages="
                formErrors && formErrors.code ? formErrors.code : ''
              "
              @change="
                formErrors && formErrors.code ? delete formErrors.code : ''
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4" lg="4" class="pa-1">
            <BaseSelect
              label="Select Authentication Method*"
              :required="true"
              :itemsList="authMethodList"
              :isClearable="false"
              item-text="display_value"
              item-value="value"
              :rules="[(val) => !!val || 'Authentication Method is required']"
              v-model="organizationVendorForm.auth_method"
              :error-messages="
                formErrors && formErrors.auth_method
                  ? formErrors.auth_method
                  : ''
              "
              @change="
                formErrors && formErrors.auth_method
                  ? delete formErrors.auth_method
                  : ''
              "
            />
          </v-col>
          <v-col cols="6" class="pa-1">
            Headers
            <JsonEditorVue
              :mode="'code'"
              v-model="organizationVendorForm.headers"
            />
          </v-col>
          <v-col cols="6" class="pa-1">
            Additional Information
            <JsonEditorVue
              :mode="'code'"
              v-model="organizationVendorForm.meta_data"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn color="primary" v-if="!editMode" small @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()">
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseDialog, BaseSelect, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    organizationVendorID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      organizationVendorForm: {},
      vendorList: [],
      authMethodList: [],
    };
  },
  watch: {
    showOrganizationVendorForm(val) {
      if (val) {
        this.organizationVendorForm.organization = this.$route.params.id;
        this.getVendorListForOptions();
        if (this.editMode && this.organizationVendorID) {
          this.getOrganizationVendorObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showOrganizationVendorForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  methods: {
    getVendorListForOptions() {
      this.$api.organizationVendor
        .getVendorOptions({ request_type: "form" })
        .then((res) => {
          this.vendorList = res.data.vendor;
          this.authMethodList = res.data.auth_method;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getOrganizationVendorObject() {
      this.$api.organizationVendor
        .getOrganizationVendorObject(this.organizationVendorID)
        .then((res) => {
          this.organizationVendorForm = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.organizationVendorForm,
      };
      if (!this.editMode) {
        this.$api.organizationVendor
          .addOrganizationVendor(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Vendor added successfully.",
              color: "success",
            });
            this.resetForm();
            this.showOrganizationVendorForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.organizationVendor
          .editOrganizationVendor({
            id: this.organizationVendorForm.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Vendor details updated successfully.",
              color: "success",
            });
            this.resetForm();
            this.showOrganizationVendorForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            this.showOrganizationVendorForm = false;
          });
      }
    },
    resetForm() {
      const form = this.$refs.organizationVendorForm;
      if (form) {
        form.reset();
        this.formErrors = {};
      }
      this.organizationVendorForm.headers = null;
    },
  },
  mounted() {},
};
</script>

<style>
</style>