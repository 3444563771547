import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"relative-position",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-1 list-box-Shadow",staticStyle:{"background-color":"#f5f8fa"},attrs:{"elevation":"0"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"text-left d-flex align-center",attrs:{"cols":"2"}},[_c('h3',{staticClass:"text-uppercase text--primary"},[_vm._v("Vendor")])])],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticStyle:{"height":"800px"},attrs:{"elevation":"0"}},[_c(VRow,_vm._l((_vm.vendorList),function(vendor){return _c(VCol,{key:vendor.name,attrs:{"cols":"2"}},[_c(VCard,{ref:"vendorList",refInFor:true,attrs:{"elevation":"1"}},[_c(VCardTitle,{staticClass:"grey lighten-2 primary--text"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"d-flex justify-center pa-2",attrs:{"cols":"12"}},[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(vendor.name)+" ")])],1)],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-center"},[_c(VBtn,{attrs:{"x-small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.viewDetails(vendor.name)}}},[_vm._v(" View ")])],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }