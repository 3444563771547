<template>
  <div>
    <v-row class="mt-0 primary d-flex align-center">
      <v-col cols="10">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-white text-body-2 mr-1 font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              {{ orderInformation.awb_number }}
            </span>
          </template>
          AWB Number
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="text-white text-body-2 mr-1 font-weight-bold"
              v-bind="attrs"
              v-on="on"
            >
              ({{ orderInformation.trace_id }})
            </span>
          </template>
          Trace Id
        </v-tooltip>
      </v-col>
      <v-col cols class="d-flex align-center justify-end">
        <v-btn small depressed @click="$emit('closeDetails')" color="primary">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 ma-0" cols="12">
        <v-card elevation="0" class="pa-0 ma-0">
          <v-divider></v-divider>
          <v-card-text>
            <v-row
              no-gutters
              v-for="detail in details"
              :key="detail.label"
              class="mb-1"
            >
              <v-col cols="6">
                <h4 class="text-caption text--primary">
                  {{ detail.label }}
                </h4>
              </v-col>

              <v-col
                cols="6"
                class="d-flex justify-end"
                v-if="detail.label == 'Status Code'"
              >
                <v-chip
                  :color="
                    detail.value == 200 || detail.value == 201 ? 'green' : 'red'
                  "
                  size="x-small"
                  class="text-white font-weight-bold"
                >
                  <span>
                    {{ detail.value ? detail.value : "-" }}
                  </span>
                </v-chip>
              </v-col>

              <v-col cols="6" class="d-flex justify-end" v-else>
                <h5>{{ detail.value ? detail.value : "-" }}</h5>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>

          <!-- Headers -->
          <v-card-text
            v-if="
              orderInformation &&
              orderInformation.headers &&
              Object.keys(orderInformation).length
            "
          >
            <v-row no-gutters>
              <v-col cols="6">
                <h4 class="text-body-2 text--primary font-weight-bold">
                  Headers
                </h4>
              </v-col>

              <v-col cols="12" class="mt-2">
                <JsonViewer
                  :value="orderInformation.headers"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></JsonViewer>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Payload -->
          <v-card-text
            v-if="
              orderInformation &&
              orderInformation.payload &&
              Object.keys(orderInformation).length
            "
          >
            <v-row no-gutters>
              <v-col cols="6">
                <h4 class="text-body-2 text--primary font-weight-bold">
                  Payload
                </h4>
              </v-col>
              <v-col cols="12" class="mt-2">
                <JsonViewer
                  :value="orderInformation.payload"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></JsonViewer>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Response -->
          <v-card-text
            v-if="
              orderInformation &&
              orderInformation.response &&
              Object.keys(orderInformation).length
            "
          >
            <v-row no-gutters>
              <v-col cols="6">
                <h4 class="text-body-2 text--primary font-weight-bold">
                  Response
                </h4>
              </v-col>
              <v-col cols="12" class="mt-2">
                <JsonViewer
                  :value="orderInformation.response"
                  :expand-depth="5"
                  copyable
                  boxed
                  sort
                ></JsonViewer>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main";
import JsonViewer from "vue-json-viewer";
import "vue-json-viewer/style.css";

export default {
  name: "BaseLogDetailsLayout",
  components: {
    JsonViewer,
  },
  props: {
    trace_id: { Number, String },
    details: Array,
    detailsObject: Object,
  },
  data() {
    return {
      orderInformation: {},
    };
  },
  watch: {
    detailsObject(val) {
      if (val) {
        this.orderInformation = this.detailsObject;
      }
    },
  },
  methods: {
    copyItem(value) {
      if (value) {
        navigator.clipboard.writeText(JSON.stringify(value));
        bus.$emit("showToastMessage", {
          message: "Copied Successfully",
          color: "success",
        });
      }
    },
  },
  mounted() {
    if (this.detailsObject) {
      delete this.detailsObject?.payload?.order_reference_number;
    }
    this.orderInformation = this.detailsObject;
  },
};
</script>
