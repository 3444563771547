import { organization } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getOrganizationList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(organization.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organization.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewOrganizationDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organization.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addOrganization(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(organization.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editOrganization(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${organization.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  generateApiKey(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organization.generateApi}/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationOptions(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organization.optionList}/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationConfigurationList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(organization.config, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationConfigurationObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${organization.config}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateOrganizationConfiguration(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${organization.config}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
