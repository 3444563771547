import { user } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getUserList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(user.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${user.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addUser(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(user.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  editUser(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${user.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserTypeList(params) {
    return new Promise((resolve, reject) => {
      axios
        .options(`${user.base}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  changePassword(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${user.base}${payload.id}/change_password/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
