<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <!-- Details Card -->
        <v-card
          color="primary"
          theme="dark"
          variant="elevated"
          class="list-box-Shadow"
          v-if="Object.values(vendorDetails).length"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" v-for="details in basicDetails" :key="details">
                <v-row>
                  <v-col cols="12" class="text-caption text-white">
                    <span> {{ details.label }}: </span>
                    <span class="font-weight-bold text-capitalize">
                      {{ details.value }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Other Details -->
    <v-row no-gutters>
      <!-- Events -->
      <v-col
        cols="4"
        v-if="trackingDetailObject && trackingDetailObject.events"
        class="px-3"
      >
        <v-card
          class="overflow-y-auto py-2"
          :style="`height: calc(100vh - 282px)`"
        >
          <v-card-title class="text-h6 py-0"> Events </v-card-title>
          <hr />
          <v-timeline dense side="end" light align-top>
            <v-timeline-item
              v-for="event in trackingDetailObject.events"
              :key="event"
              small
              fill-dot
            >
              <div class="text-black">
                <div class="font-weight-normal text-capitalize">
                  <strong>{{ event.customer_update }}</strong>
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-calendar-clock </v-icon>
                  {{ convertEventTimeStamp(event.event_time) }}
                </div>
                <div class="text-caption">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.hub_name }}
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card>
      </v-col>
      <v-col cols="8" class="px-3">
        <v-row>
          <v-col cols="6" v-if="trackingDetailObject.origin_details">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
            >
              <v-card-title class="text-h6 py-1"> Origin Address </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in originAddress"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" v-if="trackingDetailObject.destination_details">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
            >
              <v-card-title class="text-h6 py-1">
                Destination Address
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in destinationAddress"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            v-if="trackingDetailObject && trackingDetailObject.pieces_detail"
          >
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
            >
              <v-card-title class="text-h6 py-1"> Pieces Details </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="details in piecesDetails"
                    :key="details"
                  >
                    <v-row>
                      <v-col cols="2" class="text-left">
                        <span> {{ details.label }}: </span>
                      </v-col>
                      <v-col cols="8" class="text-left">
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                      <br />
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import { convertDateTime } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "SkipExpressAwbTrack",
  components: {
    BaseListLayout,
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetailObject: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicDetails() {
      return [
        {
          label: "Organization AWB",
          value: this.vendorDetails.order_awb,
        },
        {
          label: "Vendor AWB",
          value: this.vendorDetails.vendor_awb,
        },
        {
          label: "Vendor AWb Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Status",
          value: this.trackingDetailObject.status,
        },
        {
          label: "Creation Date",
          value: this.convertEventTimeStamp(
            this.trackingDetailObject.creation_date
          ),
        },
        {
          label: "Tracking Number",
          value: this.vendorDetails.track_no,
        },
        {
          label: "Last Status Sent To Organization",
          value: this.vendorDetails.last_status_sent_to_org
            ? this.vendorDetails.last_status_sent_to_org
            : "N/A",
        },
        {
          label: "Last Update Sent To Organization At",
          value: convertDateTime(this.vendorDetails.last_update_sent_to_org_at),
        },
        {
          label: "Last Update Received At",
          value: convertDateTime(this.vendorDetails.last_update_received_at),
        },
        {
          label: "Reference Number",
          value: this.trackingDetailObject.reference_number,
        },
        {
          label: "Customer Reference Number",
          value: this.trackingDetailObject.customer_reference_number,
        },
        {
          label: "Receiver Name",
          value: this.trackingDetailObject.receiver_name,
        },
        {
          label: "Receiver Relation",
          value: this.trackingDetailObject.receiver_relation,
        },
        {
          label: "Service Type",
          value: this.trackingDetailObject.service_type_id,
        },
        {
          label: "Description",
          value: this.trackingDetailObject.description,
        },
        {
          label: "COD Amount",
          value: this.trackingDetailObject.cod_amount,
        },
        {
          label: "No Of Pieces",
          value: this.trackingDetailObject.num_pieces,
        },
        {
          label: "Customer Name",
          value: this.trackingDetailObject.customer_name,
        },
        {
          label: "Customer Reference Number",
          value: this.trackingDetailObject.customer_reference_number,
        },
        {
          label: "Weight",
          value: this.trackingDetailObject.weight,
        },
        {
          label: "Shipment Type",
          value: this.trackingDetailObject.shipment_type,
        },
        {
          label: "Delivery Date",
          value: this.trackingDetailObject.delivery_date,
        },
      ];
    },
    originAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.origin_details?.Name,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.origin_details?.Phone,
        },
        {
          label: "Address Line 1",
          value: this.trackingDetailObject?.origin_details?.address_line_1,
        },
        {
          label: "Address Line 2",
          value: this.trackingDetailObject?.origin_details?.address_line_2,
        },
        {
          label: "City",
          value: this.trackingDetailObject?.origin_details?.city,
        },
        {
          label: "State",
          value: this.trackingDetailObject?.origin_details?.state,
        },
        {
          label: "Pincode",
          value: this.trackingDetailObject?.origin_details?.pincode,
        },
        {
          label: "Country",
          value: this.trackingDetailObject?.origin_details?.country,
        },
      ];
    },
    destinationAddress() {
      return [
        {
          label: "Name",
          value: this.trackingDetailObject?.destination_details?.Name,
        },
        {
          label: "Phone",
          value: this.trackingDetailObject?.destination_details?.Phone,
        },
        {
          label: "Address Line 1",
          value: this.trackingDetailObject?.destination_details?.address_line_1,
        },
        {
          label: "Address Line 2",
          value: this.trackingDetailObject?.destination_details?.address_line_2,
        },
        {
          label: "City",
          value: this.trackingDetailObject?.destination_details?.city,
        },
        {
          label: "State",
          value: this.trackingDetailObject?.destination_details?.state,
        },
        {
          label: "Pincode",
          value: this.trackingDetailObject?.destination_details?.pincode,
        },
        {
          label: "Country",
          value: this.trackingDetailObject?.destination_details?.country,
        },
      ];
    },
    piecesDetails() {
      return [
        {
          label: "Description",
          value: this.trackingDetailObject?.pieces_detail?.description,
        },
        {
          label: "Volume Unit",
          value: this.trackingDetailObject?.pieces_detail?.volume_unit,
        },
        {
          label: "Weight Unit",
          value: this.trackingDetailObject?.pieces_detail?.weight_unit,
        },
        {
          label: "Dimension Unit",
          value: this.trackingDetailObject?.pieces_detail?.dimension_unit,
        },
        {
          label: "Declared Value",
          value: this.trackingDetailObject?.pieces_detail?.declared_value,
        },
        {
          label: "Height",
          value: this.trackingDetailObject?.pieces_detail?.height,
        },
        {
          label: "Width",
          value: this.trackingDetailObject?.pieces_detail?.width,
        },
        {
          label: "Length",
          value: this.trackingDetailObject?.pieces_detail?.length,
        },
        {
          label: "Weight",
          value: this.trackingDetailObject?.pieces_detail?.weight,
        },
        {
          label: "Volume",
          value: this.trackingDetailObject?.pieces_detail?.volume,
        },
        {
          label: "Quantity",
          value: this.trackingDetailObject?.pieces_detail?.quantity,
        },
      ];
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
  },
};
</script>

<style>
</style>