<template>
  <BaseDialog
    v-model="showOrganizationURLForm"
    :title="`${editMode ? 'Edit' : 'Add'} API`"
    width="70vh"
    @closeDialog="resetForm(), (showOrganizationURLForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="organizationApiForm"
        id="organizationApiForm"
        name="organizationApiForm"
      >
        <v-row>
          <v-col
            cols="12"
            v-if="
              formErrors.non_field_errors &&
              formErrors.non_field_errors.length > 0
            "
            class="pa-0"
          >
            <v-alert dense type="error">
              <v-list
                dense
                style="background: inherit !important"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
              >
                <v-list-item dense style="min-height: 10px !important">
                  <span>{{ error }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
          <v-col cols="12" class="pa-2">
            <BaseSelect
              :required="true"
              label="Request For*"
              item-text="display_value"
              item-value="value"
              :itemsList="requestForList"
              :rules="[(val) => !!val || 'Request For is required']"
              v-model="organizationApiForm.request_for"
              :error-messages="
                formErrors && formErrors.request_for
                  ? formErrors.request_for
                  : ''
              "
              @change="
                formErrors && formErrors.request_for
                  ? delete formErrors.request_for
                  : ''
              "
            />
          </v-col>
          <v-col cols="12" class="pa-2">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Organization URL*"
              :rules="[(val) => !!val || 'Organization URL is required']"
              :error-messages="
                formErrors && formErrors.url ? formErrors.url : ''
              "
              v-model="organizationApiForm.url"
              @input="formErrors && formErrors.url ? delete formErrors.url : ''"
            ></v-text-field>
          </v-col>
          <v-col v-if="!isUserTypeOrganization" cols="12" class="pa-2">
            Schema
            <JsonEditorVue
              :mode="'code'"
              v-model="organizationApiForm.schema"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn color="primary" v-if="!editMode" small @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()">
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseDialog, BaseSelect, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    organizationApiID: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      organizationApiForm: {},
      requestForList: [],
    };
  },

  computed: {
    showOrganizationURLForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  watch: {
    showOrganizationURLForm(val) {
      if (val) {
        if (this.isUserTypeOrganization) {
          this.getOrganizationList();
        } else {
          this.organizationApiForm.organization = this.$route.params.id;
        }
        this.getRequestForList();
        if (this.editMode && this.organizationApiID) {
          this.getOrganizationApiObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    getOrganizationList() {
      this.$api.organization
        .getOrganizationList({ limit: "all" })
        .then((res) => {
          let org = res.data.find((org) => {
            if (org.name == getUserProfile().organization) {
              return org;
            }
          });
          this.organizationApiForm.organization = org.id;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getRequestForList() {
      this.$api.organizationUrl
        .getRequestForOptions({ request_type: "form" })
        .then((res) => {
          this.requestForList = res.data.request_for;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getOrganizationApiObject() {
      this.$api.organizationUrl
        .getOrganizationURLObject(this.organizationApiID)
        .then((res) => {
          this.organizationApiForm = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    resetForm() {
      const form = this.$refs.organizationApiForm;
      if (form) {
        form.reset();
        this.formErrors = {};
      }
      this.organizationApiForm.schema = null;
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.organizationApiForm,
      };
      if (!this.editMode) {
        this.$api.organizationUrl
          .addOrganizationURL(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Api created!",
              color: "success",
            });
            this.resetForm();
            this.showOrganizationURLForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.organizationUrl
          .editOrganizationURL({
            id: this.organizationApiForm.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Organization Api details updated!",
              color: "success",
            });
            this.resetForm();
            this.showOrganizationURLForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
            this.organizationApiForm = false;
          });
      }
    },
  },
};
</script>

<style></style>
