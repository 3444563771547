<template>
  <Bar :data="data" :options="options" />
</template>
<script>
import { Bar } from "vue-chartjs";

// * Tree-shakable way
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  components: { Bar },
  props: {
    data: Object,
    options: Object,
  },
};
</script>