import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,_vm._b({attrs:{"scrollable":""},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},'v-dialog',_vm.$attrs,false),[_c(VCard,{attrs:{"rounded":"lg","elevation":"2"}},[_c(VCardTitle,{staticClass:"grey lighten-3"},[(_vm.title)?_c('span',{staticClass:"text-lg-subtitle-1 text-uppercase font-weight-bold text--primary"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c(VSpacer),_c(VIcon,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("mdi-close")])],1),_c(VCardText,{class:_vm.customClass ? _vm.customClass : 'pa-4'},[_c(VRow,{staticClass:"ma-0"},[(_vm.nonFieldErrors && _vm.nonFieldErrors.length > 0)?_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c(VAlert,{staticClass:"ma-0",attrs:{"dense":"","type":"error"}},_vm._l((_vm.nonFieldErrors),function(error,i){return _c(VList,{key:i,staticClass:"pa-0",staticStyle:{"background":"inherit !important"},attrs:{"dense":""}},[_c(VListItem,{staticStyle:{"min-height":"20px !important"},attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(i + 1)+" . ")]),_vm._v("  "),_c('span',[_vm._v(_vm._s(error))])])],1)}),1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_vm._t("dialogContent")],2)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-end grey lighten-3"},[_vm._t("actions")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }