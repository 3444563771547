<template>
  <BaseDialog
    v-model="showClientForm"
    width="60vh"
    :title="`${editMode ? 'Edit' : 'Add'} Client`"
    @closeDialog="showClientForm = false"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="clientForm"
        id="clientForm"
        name="clientForm"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            v-if="
              formErrors.non_field_errors &&
              formErrors.non_field_errors.length > 0
            "
          >
            <v-alert dense type="error">
              <v-list
                dense
                style="background: inherit !important"
                v-for="(error, i) in formErrors.non_field_errors"
                :key="i"
                class="pa-0"
              >
                <v-list-item dense>
                  <span>{{ error }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-text-field
              outlined
              type="url"
              hide-details="auto"
              dense
              label="Client Code*"
              :rules="[(val) => !!val || 'Client Code is required']"
              :error-messages="
                formErrors && formErrors.code ? formErrors.code : ''
              "
              v-model="clientForm.code"
              @input="
                formErrors && formErrors.code ? delete formErrors.code : ''
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-text-field
              outlined
              type="url"
              hide-details="auto"
              dense
              label="Client Name*"
              :rules="[(val) => !!val || 'Client Name is required']"
              :error-messages="
                formErrors && formErrors.name ? formErrors.name : ''
              "
              v-model="clientForm.name"
              @input="
                formErrors && formErrors.name ? delete formErrors.name : ''
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pb-3" v-if="!isUserTypeOrganization">
            <BaseSelect
              label="Select Organization*"
              :required="true"
              :itemsList="organizationList"
              :isClearable="false"
              item-text="name"
              item-value="id"
              :rules="[(val) => !!val || 'Organization is required']"
              v-model="clientForm.organization"
              :error-messages="
                formErrors && formErrors.organization
                  ? formErrors.organization
                  : ''
              "
              @change="
                formErrors && formErrors.organization
                  ? delete formErrors.organization
                  : ''
              "
            />
          </v-col>
          <v-col cols="12" class="pb-3">
            <BaseSelect
              label="Select Integration Partner*"
              :required="true"
              :itemsList="integrationPartnerList"
              :isClearable="false"
              item-text="name"
              item-value="id"
              :rules="[(val) => !!val || 'Integration Partner is required']"
              v-model="clientForm.integration_partner"
              :error-messages="
                formErrors && formErrors.integration_partner
                  ? formErrors.integration_partner
                  : ''
              "
              @change="
                formErrors && formErrors.integration_partner
                  ? delete formErrors.integration_partner
                  : ''
              "
            />
          </v-col>
          <v-col cols="12" class="d-flex align-center">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              readonly
              class="pr-2"
              label="Api Key*"
              v-model="clientForm.api_key"
              :rules="[(val) => !!val || 'Api key is required']"
              :error-messages="
                formErrors && formErrors.api_key ? formErrors.api_key : ''
              "
              :append-icon="copied ? 'mdi-check' : 'mdi-content-copy'"
              @click:append="copyApiValue(clientForm.api_key)"
            />
            <v-btn color="secondary" small @click="generateApiKey">
              Generate
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn v-if="!editMode" small color="primary" @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()">
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import { getUserProfile } from "@/utils/functions.js";

export default {
  components: { BaseDialog, BaseSelect },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    clientID: {
      type: Number,
    },
    from: {
      type: String,
    },
    organizationId: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      copied: false,
      isValid: true,
      clientForm: {
        api_key: "",
        is_webhook_enabled: false,
      },
      organizationList: [],
      integrationPartnerList: [],
    };
  },

  computed: {
    showClientForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isUserTypeOrganization() {
      return getUserProfile().user_type == "organization";
    },
  },
  watch: {
    showClientForm(val) {
      if (val) {
        this.getOrganizationList();
        this.getIntegrationPartnerSelectionList();
        if (this.editMode && this.clientID) {
          this.getClientObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  methods: {
    getClientObject() {
      this.$api.client
        .getClientObject(this.clientID)
        .then((res) => {
          this.clientForm = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    getOrganizationList() {
      this.$api.organization
        .getOrganizationList({ limit: "all" })
        .then((res) => {
          this.organizationList = res.data;

          if (this.isUserTypeOrganization) {
            let org = res.data.find((org) => {
              if (org.name == getUserProfile().organization) {
                return org;
              }
            });
            this.clientForm.organization = org.id;
          }
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getIntegrationPartnerSelectionList() {
      let params = {
        limit: "all",
      };
      this.$api.integrationPartner
        .getIntegrationPartnerSelectionList(params)
        .then((res) => {
          this.integrationPartnerList = res.data;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    resetForm() {
      const form = this.$refs.clientForm;
      if (form) {
        form.reset();
        this.copied = false;
      }
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        ...this.clientForm,
      };

      if (!this.editMode) {
        this.$api.client
          .addClient(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Client Created!",
              color: "success",
            });
            this.resetForm();
            this.showClientForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.client
          .editClient({
            id: this.clientForm.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Client details updated!",
              color: "success",
            });
            this.resetForm();
            this.showClientForm = false;
            this.$emit("updateList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
    generateApiKey() {
      if (this.formErrors && this.formErrors.api_key) {
        delete this.formErrors.api_key;
      }
      this.$api.organization
        .generateApiKey()
        .then((res) => {
          this.copied = false;
          this.clientForm.api_key = res.data.api_key;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    copyApiValue(value) {
      if (value) {
        navigator.clipboard.writeText(value);
        this.copied = true;
      }
    },
  },
  mounted() {},
};
</script>