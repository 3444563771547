<template>
  <v-row>
    <v-col cols="12">
      <!-- <v-menu
        offset-y
        v-if="params.context.parentComponent.fromShippingShipments"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" x-small v-bind="attrs" v-on="on">
            <v-icon> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-if="params.data.is_success == 'No'"
            value="Edit & Book"
            @click="editAndBook()"
          >
            <v-icon small class="mr-2" color="green"> mdi-pencil </v-icon>
            <v-list-item-title>Edit & Book</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="params.data.is_success === 'Yes'"
            value="Fetch Status"
            @click="fetchShipmentStatus()"
          >
            <v-icon small class="mr-2" color="green"> mdi-reload </v-icon>
            <v-list-item-title>Fetch Status</v-list-item-title>
          </v-list-item>

          <v-list-item value="View" @click="viewDetails()">
            <v-icon small class="mr-2" color="primary"> mdi-eye </v-icon>
            <v-list-item-title>View</v-list-item-title>
          </v-list-item>

          <v-list-item value="View AWB Logs" @click="viewAWBLogs()">
            <span
              class="text-h6 text--primary rounded-lg text-caption font-weight-bold mr-2"
            >
              AWB
            </span>
            <v-list-item-title>AWB Logs</v-list-item-title>
          </v-list-item>

          <v-list-item value="View Webhook Logs" @click="viewWebhookLogs()">
            <v-icon small class="mr-2" color="primary"> mdi-webhook </v-icon>
            <v-list-item-title>Webhook Logs</v-list-item-title>
          </v-list-item>

          <v-list-item value="Track Order" @click="trackOrder()">
            <v-icon small class="mr-2" color="primary">
              mdi-archive-search
            </v-icon>
            <v-list-item-title>Track Order</v-list-item-title>
          </v-list-item>

          <v-list-item value="Track Order" @click="downloadPdf()">
            <v-icon small class="mr-2" color="primary">
              mdi-file-download
            </v-icon>
            <v-list-item-title>Download PDF</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            v-if="params.data.is_success == 'No'"
            icon
            @click="editAndBook()"
          >
            <v-icon color="green">mdi-pencil</v-icon>
          </v-btn>
        </template>
        Edit & Book
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="params.data.is_success === 'Yes'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="fetchShipmentStatus()"
          >
            <v-icon color="green">mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Fetch Status</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewDetails()">
            <v-icon color="primary">mdi-eye</v-icon>
          </v-btn>
        </template>
        View
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewAWBLogs()">
            <span
              class="text-h6 text--primary rounded-lg text-caption font-weight-bold"
            >
              AWB
            </span>
          </v-btn>
        </template>
        AWB Logs
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="viewWebhookLogs()">
            <v-icon color="primary">mdi-webhook</v-icon>
          </v-btn>
        </template>
        Webhook Logs
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="trackOrder()">
            <v-icon color="primary">mdi-archive-search</v-icon>
          </v-btn>
        </template>
        Track Order
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="downloadPdf()">
            <v-icon color="primary">mdi-file-download</v-icon>
          </v-btn>
        </template>
        Download PDF
      </v-tooltip> -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    viewDetails() {
      this.params.context.parentComponent.viewDetails(this.params.data.id);
    },
    editAndBook() {
      this.params.context.parentComponent.editAndBook(this.params.data);
    },
    fetchShipmentStatus() {
      this.params.context.parentComponent.fetchShipmentStatus(this.params.data);
    },
    viewAWBLogs() {
      this.params.context.parentComponent.viewAWBLogs(this.params.data.id);
    },
    viewWebhookLogs() {
      this.params.context.parentComponent.viewWebhookLogs(this.params.data);
    },
    trackOrder() {
      this.params.context.parentComponent.trackOrder(this.params.data);
    },
    downloadPdf() {
      this.params.context.parentComponent.downloadPdf(this.params.data);
    },
  },
};
</script>

<style></style>
