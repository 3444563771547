<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <!-- Details Card -->
        <v-card
          color="primary"
          theme="dark"
          variant="elevated"
          class="list-box-Shadow"
          v-if="vendorDetails"
        >
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="2" v-for="(details, i) in basicDetails" :key="i">
                <v-row>
                  <v-col cols="12" class="text-caption text-white">
                    <span> {{ details.label }}: </span>
                    <span class="font-weight-bold text-capitalize">
                      {{ details.value }}</span
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Other Details -->
    <v-row no-gutters>
      <v-col cols="12" class="px-3">
        <v-row>
          <v-col cols="6">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="createdByDetails"
            >
              <v-card-title class="text-h6 py-1">
                Created By Details
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="(details, i) in createdByDetails"
                    :key="i"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              color="#fbfbfb"
              theme="dark"
              variant="elevated"
              class="text-caption"
              v-if="supplierDetails"
            >
              <v-card-title class="text-h6 py-1">
                Supplier Details
              </v-card-title>
              <hr />
              <v-card-text class="text-caption">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="(details, i) in supplierDetails"
                    :key="i"
                  >
                    <v-row>
                      <v-col cols="12" class="">
                        <span> {{ details.label }}: </span>
                        <span class="font-weight-bold">
                          {{ details.value }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import { convertDateTime } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "SkipExpressAwbTrack",
  components: {
    BaseListLayout,
    convertDateTime,
  },
  props: {
    vendorDetails: Object,
    trackingDetails: Object,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicDetails() {
      return [
        {
          label: "Order Awb",
          value: this.vendorDetails.order_awb,
        },
        {
          label: "Vendor Awb Status",
          value: this.vendorDetails.vendor_awb_status,
        },
        {
          label: "Last Update Sent To Org At",
          value: this.vendorDetails.last_update_sent_to_org_at,
        },
        {
          label: "Last Update Time",
          value: this.vendorDetails.last_updated_timestamp
            ? convertDateTime(this.vendorDetails.last_updated_timestamp)
            : null,
        },
        {
          label: "AWB Number",
          value: this.trackingDetails.id,
        },
        {
          label: "Status",
          value: this.trackingDetails.status_desc,
        },
        {
          label: "Date",
          value: this.trackingDetails.date
            ? convertDateTime(this.trackingDetails.date)
            : null,
        },
      ];
    },
    createdByDetails() {
      return [
        {
          label: "Name",
          value:
            this.trackingDetails &&
            this.trackingDetails.created_by_user &&
            this.trackingDetails.created_by_user.name,
        },
        {
          label: "Description",
          value:
            this.trackingDetails &&
            this.trackingDetails.created_by_user &&
            this.trackingDetails.created_by_user.description,
        },
      ];
    },
    supplierDetails() {
      return [
        {
          label: "Name",
          value:
            this.trackingDetails &&
            this.trackingDetails.supplier &&
            this.trackingDetails.supplier.name,
        },
        {
          label: "Phone",
          value:
            this.trackingDetails &&
            this.trackingDetails.supplier &&
            this.trackingDetails.supplier.phone,
        },
      ];
    },
  },
};
</script>

<style></style>
