<template>
  <div>
    <v-card style="background-color: #f5f8fa" elevation="0">
      <v-card-title class="ma-0 pa-0 pb-2">
        <span class="pl-2 text--primary text-sm-body-2">
          <v-icon @click="$router.back()">mdi-chevron-left</v-icon>
          Go Back to Vendors
        </span>
        <v-spacer></v-spacer>
        <span class="pr-10 text--primary text-sm-body-2">
          Vendor : {{ $route.params.name }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-tabs
          class="rounded-pill"
          background-color="#17b7d0"
          v-model="currentTab"
        >
          <v-tabs-slider style="width: 0%"></v-tabs-slider>
          <v-tab
            v-for="item in items"
            :key="item"
            value="one"
            class="white--text"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <BaseListLayout
              name="apiList"
              ref="apiList"
              :showColumnSelection="false"
              :table-header="apiColumns"
              :table-data="vendorApiList"
              :hasAddButton="true"
              :context="context"
              :total="totalItems"
            >
              <template #addButton>
                <v-btn color="primary" small depressed @click="addVendorApi">
                  Add Api
                </v-btn>
              </template>
              <template #dialogs>
                <VendorApiForm
                  ref="vendorApiForm"
                  v-model="showVendorApiForm"
                  :editMode="editMode"
                  :vendorApiId="vendorApiId"
                  @refreshList="getVendorApiList"
                />
              </template>
            </BaseListLayout>
          </v-tab-item>
          <v-tab-item>
            <BaseListLayout
              :showColumnSelection="false"
              name="vendorMetaDataList"
              ref="vendorMetaDataList"
              :table-header="metadataColumns"
              :table-data="vendorMetaDataList"
              :hasAddButton="true"
              :context="context"
              :total="totalItems"
            >
              <template #addButton>
                <v-btn
                  color="primary"
                  small
                  depressed
                  @click="addVendorMetadata"
                >
                  Add Metadata
                </v-btn>
              </template>
              <template #dialogs>
                <VendorMetadataForm
                  ref="vendorMetadataForm"
                  v-model="showVendorMetadataForm"
                  :editMode="editMode"
                  :vendorMetaDataId="vendorMetaDataId"
                  @refreshList="getVendorMetaDataList"
                />
              </template>
            </BaseListLayout>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { bus } from "@/main";
import VendorApiForm from "./VendorApiForm.vue";
import VendorMetadataForm from "./VendorMetadataForm.vue";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import cellRenderer from "@/components/AgGridButtons/CellRenderer.vue";

export default {
  name: "Vendor-Details",
  components: {
    BaseListLayout,
    VendorApiForm,
    VendorMetadataForm,
    ActionButton,
    cellRenderer,
  },
  data() {
    return {
      fromVendor: true,
      totalItems: 0,
      currentTab: null,
      items: ["Api", "Meta Data"],
      editMode: false,

      vendorApiId: null,
      vendorApiList: [],
      showVendorApiForm: false,
      apiColumns: [
        { headerName: "Api Name", field: "api_name" },
        { headerName: "Api Type", field: "api_type" },
        { headerName: "Api URL", field: "api_url" },
        {
          headerName: "Created",
          field: "created",
          cellRenderer: "cellRenderer",
        },
        {
          headerName: "Updated",
          field: "modified",
          cellRenderer: "cellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
          minWidth: 100,
          maxWidth: 100,
        },
      ],

      vendorMetaDataId: null,
      vendorMetaDataList: [],
      showVendorMetadataForm: false,
      metadataColumns: [
        { headerName: "Metadata Type", field: "metadata_type" },
        {
          headerName: "Has Api",
          field: "has_api",
          cellRenderer: "cellRenderer",
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionButton",
        },
      ],
    };
  },
  watch: {
    currentTab(val) {
      if (val == 0) {
        this.getVendorApiList();
      } else if (val == 1) {
        this.getVendorMetaDataList();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    addVendorApi() {
      this.vendorApiId = null;
      this.editMode = false;
      this.showVendorApiForm = true;
    },
    editVendorDetails(id) {
      if (this.currentTab == 0) {
        this.vendorApiId = id;
        this.editMode = true;
        this.showVendorApiForm = true;
      } else {
        this.vendorMetaDataId = id;
        this.editMode = true;
        this.showVendorMetadataForm = true;
      }
    },
    getVendorApiList() {
      let params = { vendor: this.$route.params.name };
      this.$api.vendor
        .getVendorApiList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.vendorApiList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching data",
            color: "red",
          });
        });
    },

    addVendorMetadata() {
      this.vendorMetaDataId = null;
      this.editMode = false;
      this.showVendorMetadataForm = true;
    },
    getVendorMetaDataList() {
      let params = { vendor: this.$route.params.name };
      this.$api.vendor
        .getVendorMetaDataList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.totalItems = res.count;
          this.vendorMetaDataList = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching meta data",
            color: "red",
          });
        });
    },
    viewVendorMetadataDetails(metadata) {
      this.metadata = metadata;
      this.showMetadata = true;
    },
  },
};
</script>
<style scoped>
.v-tab--active {
  border-radius: 20px;
  background-color: lightblue;
}
</style>