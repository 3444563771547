<template>
  <div class="custom-list">
    <v-row class="ma-0">
      <v-col cols="12">
        <v-card
          elevation="0"
          style="background-color: #f5f8fa"
          class="pa-2 list-box-Shadow light_background"
        >
          <v-row no-gutters>
            <v-col cols="6" class="text-left d-flex align-center">
              <h3
                class="text-uppercase text-h6 text_color--text font-weight-bold"
              >
                AWB Track And Trace
              </h3>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12">
        <v-text-field
          v-model.trim="searchAWBs"
          outlined
          dense
          label="Search AWBs here.."
          clearable
          append-icon="mdi-magnify"
          @click:append="getAwbLogs()"
          @click:clear="
            searchAWBs = [];
            ordersList = [];
          "
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="ma-0" v-if="ordersList.length">
      <v-col cols="12">
        <h5 class="text-h6 text_color--text font-weight-bold">Shipments</h5>
        <hr />
      </v-col>
      <v-col cols="12">
        <BaseListLayout
          :table-header="columns"
          :table-data="ordersList"
          :context="context"
          :total="totalItems"
          name="AWBsList"
          localStorageKey="awbTrackAndTraceColumns"
          @getList="getAwbLogs"
        >
        </BaseListLayout>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import { bus } from "@/main";

export default {
  name: "AWBTrackAndTrace",
  components: {
    BaseListLayout,
    CellRenderer,
  },
  data() {
    return {
      searchAWBs: [],
      ordersList: [],
      columns: [
        { headerName: "AWB Number", field: "awb_number" },
        { headerName: "Customer Code", field: "customer_code" },
        { headerName: "Customer Name", field: "customer_name" },
        { headerName: "Last Location", field: "current_branch" },
        { headerName: "Status", field: "status" },
        {
          headerName: "Last Updated",
          field: "updated",
          cellRenderer: "CellRenderer",
        },
      ],
      error: null,
      totalItems: 0,
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getAwbLogs() {
      bus.$emit("showLoader", true);
      let awbs = [];

      let values = this.searchAWBs.split(",");
      awbs.push(values);

      let params = {
        reference_number: awbs.join(),
      };

      this.$api.awbTrackAndTrace
        .getAWBLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          if (res.data.results.length) {
            this.ordersList = res.data.results;
          } else {
            bus.$emit("showToastMessage", {
              message: "No data found",
              color: "red",
            });
          }
          this.error = null;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.ordersList = [];
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
};
</script>

<style>
</style>