<template>
  <v-row class="mt-1">
    <v-col cols="12" class="d-flex align-center">
      <v-btn elevation="0" @click="$router.back()">
        <v-icon large color="primary">mdi-arrow-left-circle</v-icon>
      </v-btn>
      <span class="text-h5 mr-3">
        {{ organizationDetails.code }} - {{ organizationDetails.name }}
      </span>
      <v-chip style="background-color: #112f4f">
        <span class="text-body-2 white--text"> Base URL: </span>
        <span class="mx-1 font-weight-bold white--text">
          {{ organizationDetails.url }}
        </span>
      </v-chip>
    </v-col>
    <v-col cols="12">
      <v-row class="mx-2">
        <v-col
          cols="12"
          md="3"
          :lg="detail.col"
          sm="4"
          v-for="detail in statistics"
          :key="detail.label"
        >
          <v-card
            elevation="0"
            class="rounded-lg"
            style="border: 1px solid skyblue"
          >
            <v-card-title class="d-flex justify-center">
              <h3
                class="text--primary font-weight-bold text-body-2 text-capitalize"
              >
                {{ detail.label }}
              </h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-icon style="margin-right: 10px"> mdi-people </v-icon>
                  <span class="text-body-1 text--primary font-weight-bold">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row class="mx-2">
        <v-col cols="12" lg="4">
          <v-card elevation="0" style="border: 1px solid skyblue">
            <v-card-title> Recent Activity </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 635px" class="overflow-y-auto">
              <v-timeline dense>
                <v-timeline-item
                  v-for="(log, i) in organizationDetails.logs"
                  :key="i"
                  small
                >
                  <div>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span
                          style="font-size: 10px"
                          class="text-caption font-weight-bold"
                        >
                          {{ log.created ? getDate(log.created) : "" }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span class="text-caption font-weight-bold">
                          <v-icon small class="pr-2">mdi-account</v-icon>
                          {{ log.added_by }}
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <h5 class="black--text text-caption font-weight-bold">
                          {{ log.message }}
                        </h5>
                      </v-col>
                    </v-row>
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-row>
            <v-col cols="12">
              <v-card elevation="0" style="border: 1px solid skyblue">
                <v-card-title> Day Wise Chart </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 275px" class="overflow-y-auto">
                  <BarChart
                    v-if="
                      organizationDetails && organizationDetails.day_wise_chart
                    "
                    ref="barChart"
                    :labels="organizationDetails.day_wise_chart.labels"
                    :data="organizationDetails.day_wise_chart"
                    :options="barChartOptions"
                    :height="230"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" style="border: 1px solid skyblue">
                <v-card-title> Month Wise Chart </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 275px" class="overflow-y-auto">
                  <BarChart
                    v-if="
                      organizationDetails &&
                      organizationDetails.month_wise_chart
                    "
                    ref="barChart"
                    :labels="organizationDetails.month_wise_chart.labels"
                    :data="organizationDetails.month_wise_chart"
                    :options="barChartOptions"
                    :height="230"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4">
          <v-row>
            <v-col cols="12">
              <v-card elevation="0" style="border: 1px solid skyblue">
                <v-card-title> Organization Clients </v-card-title>
                <v-card-text style="height: 275px" class="overflow-y-auto">
                  <v-data-table
                    :headers="organizationClientHeaders"
                    :items="organizationClientList"
                    fixed-header
                    hide-default-footer
                  >
                    <template #item.created="{ item }">
                      {{ getDate(item.created) }}
                    </template>
                    <template #item.modified="{ item }">
                      {{ getDate(item.modified) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card elevation="0" style="border: 1px solid skyblue">
                <v-card-title> Organization Vendors </v-card-title>
                <v-card-text style="height: 275px" class="overflow-y-auto">
                  <v-data-table
                    :headers="organizationVendorHeaders"
                    :items="organizationVendorList"
                    fixed-header
                    hide-default-footer
                  >
                    <template #item.created="{ item }">
                      {{ getDate(item.created) }}
                    </template>
                    <template #item.modified="{ item }">
                      {{ getDate(item.modified) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- <v-col cols="12">
      <v-card style="background-color: #f5f8fa" elevation="0" class="ma-0 pa-0">
        <v-card-title>
          <v-row>
            <v-col cols="2">Incoming Request Log</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <BaseListLayout
            ref="organizationOrderLogsList"
            :table-header="orderColumns"
            :table-data="organizationOrderLogsList"
            :context="context"
            :total="totalOrders"
            name="organizationOrderLogsList"
            @getList="getOrganizationOrderLogsList"
            :gridStyle="gridStyle"
            :showColumnSelection="false"
          />
        </v-card-text>
        </v-card-text>
      </v-card>
     </v-col>  -->
  </v-row>
</template>
   

<script>
import CellRenderer from "@/components/AgGridButtons/CellRenderer.vue";
import BarChart from "@/components/BaseComponents/BarChart.vue";
import { convertDateTime } from "@/utils/functions";
import { bus } from "@/main";

export default {
  name: "OrganizationDetails",
  components: {
    CellRenderer,
    BarChart,
  },
  data() {
    return {
      totalOrders: 0,
      organizationDetails: {},
      organizationClientList: [],
      organizationOrderLogsList: [],
      organizationVendorList: [],

      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        height: "100",
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
      },

      organizationClientHeaders: [
        { text: "Code", value: "code" },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Created",
          value: "created",
        },
        {
          text: "Updated",
          value: "modified",
        },
        // {
        //   text: "Total Awbs",
        //   value: "total_awbs",
        // },
      ],
      organizationVendorHeaders: [
        { text: "Vendor", value: "vendor" },
        {
          text: "Code",
          value: "code",
        },
        {
          text: "Created",
          value: "created",
        },
        {
          text: "Updated",
          value: "modified",
        },
      ],
      orderColumns: [
        { text: "Trace ID", value: "trace_id" },
        {
          text: "Created Date",
          value: "created",
          // title: "CellRenderer",
        },
        {
          text: "Success",
          value: "is_success",
          // cellRenderer: "CellRenderer",
        },
      ],
    };
  },
  computed: {
    statistics() {
      return [
        {
          label: "Total AWBs",
          value: this.organizationDetails.total_awbs,
          col: 2,
        },
        {
          label: "Total Client",
          value: this.organizationDetails.total_clients,
          col: 2,
        },
        {
          label: "Total Client Requests",
          value: this.organizationDetails.total_client_request,
          col: 2,
        },
        {
          label: "Total Outgoing Client Updates",
          value: this.organizationDetails.total_outgoing_client_updates,
          col: 2,
        },
        {
          label: "Total Vendor",
          value: this.organizationDetails.total_vendors,
          col: 2,
        },
        {
          label: "Total Vendor Requests",
          value: this.organizationDetails.total_vendor_request,
          col: 2,
        },
        // {
        //   label: "Webhook Log Count",
        //   value: this.organizationDetails.webhook_log_count,
        // },
      ];
    },
  },
  methods: {
    getDate(value) {
      return value ? convertDateTime(value) : "";
    },
    viewOrganizationDetails(id) {
      this.$api.organization
        .viewOrganizationDetails(id)
        .then((res) => {
          this.organizationDetails = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    // getOrganizationOrderLogsList(params) {
    //   bus.$emit("showLoader", true);
    //   params = {
    //     organization: this.organizationDetails.id,
    //     ...params,
    //   };
    //   this.$api.inboundLogs
    //     .getOrderLogsList(params)
    //     .then((res) => {
    //       bus.$emit("showLoader", false);
    //       this.totalOrders = res.count;
    //       this.organizationOrderLogsList = res.data.results;
    //     })
    //     .catch((err) => {
    //       bus.$emit("showLoader", false);
    //       bus.$emit("showToastMessage", {
    //         message: err.data.detail,
    //         color: "red",
    //       });
    //     });
    // },
    getOrganizationClientList() {
      let params = {
        organization: this.$route.params.id,
        limit: "all",
      };
      this.$api.client
        .getClientList(params)
        .then((res) => {
          this.organizationClientList = res?.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOrganizationVendorList() {
      bus.$emit("showLoader", true);
      let params = {
        organization: this.$route.params.id,
        limit: "all",
      };
      this.$api.organizationVendor
        .getOrganizationVendorList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.organizationVendorList = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.viewOrganizationDetails(this.$route.params.id);
      this.getOrganizationClientList();
      this.getOrganizationVendorList();
    }
  },
};
</script>
