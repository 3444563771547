<template>
  <div>
    <v-row class="ma-0">
      <v-col class="d-flex align-center">
        <v-btn
          elevation="0"
          size="large"
          icon
          color="primary"
          class="mr-2"
          name="back-button"
          @click="$router.back()"
        >
          <v-icon large color="primary">mdi-arrow-left-circle</v-icon>
        </v-btn>
        <span class="text-h6 font-weight-bold text--primary pa-0 text-uppercase"
          >Vendor Shipments</span
        >
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col
        cols="12"
        lg="3"
        class="overflow-y-auto py-0 pr-lg-0"
        style="max-height: calc(85vh)"
      >
        <v-card elevation="1" rounded="lg" class="shadow-on-hover">
          <v-card rounded="lg" class="primary">
            <v-card-text class="pa-2">
              <v-row class="ma-0">
                <v-col cols="9" lg="9" class="pa-1">
                  <span class="text-caption text-white">Vendor AWB</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{ vendorShipmentObject.vendor_awb }}
                  </span>
                </v-col>

                <v-col
                  cols="3"
                  lg="3"
                  class="pa-1 d-flex align-center justify-end"
                >
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{ vendorShipmentObject.organization_vendor_name }}
                          </span>
                        </v-chip>
                      </template>
                      Vendor
                    </v-tooltip>
                  </div>
                </v-col>

                <v-col cols="12 pa-1">
                  <v-divider class="white"></v-divider>
                </v-col>

                <v-col cols="8" class="pa-1">
                  <span class="text-caption text-white">Vendor AWB Status</span>
                  <v-spacer />
                  <span class="text-body-2 text-white font-weight-bold">
                    {{ vendorShipmentObject.vendor_awb_status }}
                  </span>
                </v-col>

                <v-col cols="4" class="pa-1 d-flex align-center justify-end">
                  <div class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          color="white"
                          size="small"
                          class="text--primary"
                        >
                          <span class="text-right">
                            {{
                              $globalConstant.formatDateValue.formatDate(
                                vendorShipmentObject.created,
                                true
                              )
                            }}
                          </span>
                        </v-chip>
                      </template>
                      Created
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card-text class="pa-0 ma-0 px-2">
            <v-row class="ma-0">
              <v-col class="pa-0">
                <table class="Primary-Table pt-5">
                  <tbody>
                    <tr>
                      <td class="text-caption text--primary">organization</td>

                      <th
                        class="text-right text-caption text--primary font-weight-bold"
                      >
                        {{ vendorShipmentObject.organization }}
                      </th>
                    </tr>
                    <tr>
                      <th colspan="2">
                        <v-divider class=""></v-divider>
                      </th>
                    </tr>
                    <tr>
                      <td class="text-caption font-weight-bold">
                        Organization AWB
                      </td>

                      <th
                        class="text-right text-caption text--primary font-weight-bold"
                      >
                        {{ vendorShipmentObject.order_awb }}
                      </th>
                    </tr>
                    <tr>
                      <th colspan="2">
                        <v-divider class=""></v-divider>
                      </th>
                    </tr>
                    <tr>
                      <td class="text-caption font-weight-bold">
                        Organization AWB Status
                      </td>

                      <th
                        class="text-right text-caption text--primary font-weight-bold"
                      >
                        {{ vendorShipmentObject.organization_awb_status }}
                      </th>
                    </tr>
                    <tr>
                      <th colspan="2">
                        <v-divider class=""></v-divider>
                      </th>
                    </tr>
                    <tr>
                      <td class="text-caption font-weight-bold">
                        Last Update Received At
                      </td>

                      <th
                        class="text-right text-caption text--primary font-weight-bold"
                      >
                        {{
                          vendorShipmentObject.last_update_received_at
                            ? $globalConstant.formatDateValue.formatDate(
                                vendorShipmentObject.last_update_received_at,
                                true
                              )
                            : "-"
                        }}
                      </th>
                    </tr>
                    <tr>
                      <th colspan="2">
                        <v-divider class=""></v-divider>
                      </th>
                    </tr>
                    <tr>
                      <td class="text-caption font-weight-bold">
                        Tracking Number
                      </td>

                      <th
                        class="text-right text-caption text--primary font-weight-bold"
                      >
                        {{ vendorShipmentObject.track_no }}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card elevation="1" class="my-3 rounded-lg">
          <v-card-title class="primary py-1 px-3 text-white">
            <v-row no-gutters>
              <v-col cols="6">Shipment Timeline</v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-2 mt-0 mb-3 pt-0">
            <v-stepper vertical elevation="0">
              <v-stepper-step step="1" :complete="curr > 1">
                Created
              </v-stepper-step>
              <v-stepper-step step="2" :complete="curr > 2">
                Picked Up
              </v-stepper-step>
              <v-stepper-step step="3" :complete="curr > 3">
                Arrived At Hub
              </v-stepper-step>
              <v-stepper-step step="4" :complete="curr > 4">
                Delivered
              </v-stepper-step>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Tabs Section -->
      <v-col
        cols="12"
        lg="9"
        style="height: calc(100vh - 185px)"
        class="ma-0 pa-0 pl-2"
      >
        <v-card elevation="0" rounded="lg">
          <v-card-text class="ma-0 pa-0">
            <v-tabs grow v-model="currentTab">
              <v-tab name="summary">Summary</v-tab>
              <v-tab name="history_logs">History</v-tab>
              <v-tab name="system_logs">System Logs</v-tab>
            </v-tabs>
          </v-card-text>
        </v-card>

        <v-window
          v-model="currentTab"
          style="background-color: transparent !important"
        >
          <!-- Summary Tab -->
          <v-window-item
            class="overflow-auto"
            style="height: calc(100vh - 185px)"
          >
            <v-row class="mt-0">
              <v-col cols="12">
                <SHLAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'SHL'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetailObject="trackingDetails"
                />
                <DHLAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'DHL'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetails="
                    trackingDetails.shipments
                      ? trackingDetails.shipments[0]
                      : {}
                  "
                />
                <SKPAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name ==
                      'Skip Express'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetailObject="trackingDetails ? trackingDetails : {}"
                />
                <ATSAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'ATS'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetailObject="trackingDetails"
                />
                <GFSAwbTrack
                  v-if="
                    vendorShipmentObject &&
                    vendorShipmentObject.organization_vendor_name == 'GFS'
                  "
                  :vendorDetails="vendorShipmentObject"
                  :trackingDetails="trackingDetails[0]"
                />
              </v-col>
            </v-row>
          </v-window-item>

          <!-- History Logs -->
          <v-window-item style="height: calc(100vh - 185px)">
            <v-card
              elevation="1"
              class="overflow-auto rounded-lg mt-2"
              style="height: calc(100vh - 180px)"
            >
              <v-card-text v-if="!historyLogs.length">
                <div class="py-3 d-flex justify-center">No History Found</div>
              </v-card-text>

              <v-card-text v-else class="ma-0 pa-0">
                <v-timeline dense>
                  <v-timeline-item
                    color="secondary"
                    v-for="item in historyLogs"
                    :key="item.id"
                  >
                    <v-alert
                      class="py-2 mb-1 text-caption2 text-white font-weight-bold"
                      color="secondary lighten-1"
                      :value="true"
                    >
                      <v-row>
                        <v-col cols="9">
                          {{ item.message }}
                        </v-col>
                        <v-col cols="3" class="d-flex">
                          {{
                            item.created
                              ? convertEventTimeStamp(item.created)
                              : null
                          }}
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-window-item>

          <!-- System Logs -->
          <v-window-item>
            <v-card class="overflow-auto" style="height: calc(100vh - 185px)">
              <v-card-text class="pa-0 ma-0">
                <BaseListLayout
                  name="systemList"
                  ref="systemList"
                  :table-header="systemColumns"
                  :table-data="systemLogs"
                  :hasAddButton="true"
                  :context="context"
                  :gridStyle="gridStyle"
                  :showColumnSelection="false"
                  :total="totalItems"
                  @getList="getVendorShipmentSystemLogs"
                />
              </v-card-text>
            </v-card>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>

    <BaseDialog
      v-model="showPayloadPreview"
      width="60vh"
      title="Payload"
      @closeDialog="showPayloadPreview = false"
    >
      <template #dialogContent>
        <div id="pdf-content">
          <pre>{{ payload }}</pre>
        </div>
      </template>
      <template #actions>
        <v-btn small text>
          <v-icon color="primary" @click="copyPayload">
            mdi-content-copy
          </v-icon>
        </v-btn>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { bus } from "@/main";
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout.vue";
import ShipmentCellRenderer from "@/components/AgGridButtons/ShipmentCellRenderer.vue";
import ActionButton from "@/components/AgGridButtons/ActionButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import DHLAwbTrack from "./DHLAwbTrack";
import SHLAwbTrack from "./SHLAwbTrack";
import SKPAwbTrack from "./SKPAwbTrack";
import ATSAwbTrack from "./ATSAwbTrack";
import GFSAwbTrack from "./GFSAwbTrack";
import { convertDateTime, formatLastUpdated } from "@/utils/functions";

export default {
  name: "Shipments",
  components: {
    BaseListLayout,
    SHLAwbTrack,
    DHLAwbTrack,
    SKPAwbTrack,
    ATSAwbTrack,
    GFSAwbTrack,
    ShipmentCellRenderer,
    ActionButton,
    BaseDialog,
  },
  data() {
    return {
      totalItems: 0,
      currentTab: "summary",
      payload: {},
      showPayloadPreview: false,
      fromVendorShipmentDetails: true,

      gridStyle: {
        width: "100%",
        height: "60vh",
      },

      curr: 3,
      steps: [
        { name: "Created" },
        { name: "Picked Up" },
        {
          name: "Arrived At Hub",
        },
        { name: "Delivered" },
      ],

      vendorShipmentObject: {},
      trackingDetails: {},
      historyLogs: [],
      systemLogs: [],
      systemColumns: [
        {
          headerName: "Message",
          field: "message",
        },
        {
          headerName: "Is Success",
          field: "is_success",
          cellRenderer: "ShipmentCellRenderer",
          maxWidth: 150,
        },
        {
          headerName: "Status Code",
          field: "status_code",
          maxWidth: 150,
        },
        {
          headerName: "More Details",
          field: "actions",
          cellRenderer: "ActionButton",
          maxWidth: 150,
        },
      ],
    };
  },
  watch: {
    currentTab(val) {
      if (val == 0) {
        this.viewVendorShipment();
      } else if (val == 1) {
        this.getVendorShipmentHistoryLogs();
      } else if (val == 2) {
        // this.getVendorShipmentSystemLogs();
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
  },

  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
    viewVendorShipment() {
      let id = this.$route.query.awb
        ? this.$route.query.awb
        : this.$route.query.id;

      this.$api.vendorShipments
        .viewVendorShipment(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vendorShipmentObject = res.data;
          this.trackingDetails = res.data.tracking_details;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.errors[0].error_message,
            color: "red",
          });
        });
    },
    getVendorShipmentSystemLogs(params = {}) {
      params = { vendor_request_log: this.$route.query.id };
      this.$api.vendorShipments
        .getVendorShipmentSystemLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.systemLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    getVendorShipmentHistoryLogs(params = {}) {
      params = { vendor_request_log: this.$route.query.id };
      this.$api.vendorShipments
        .getVendorShipmentHistoryLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.historyLogs = res.data.results;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    moreDetails(payload) {
      this.payload = payload;
      this.showPayloadPreview = true;
    },
    copyPayload() {
      if (this.payload) {
        navigator.clipboard.writeText(JSON.stringify(this.payload));
        bus.$emit("showToastMessage", {
          message: "Payload Copied Successfully..",
          color: "green",
        });
      }
    },
    fetchVendorShipmentStatus() {
      bus.$emit("showLoader", true);

      let params = {
        vendor_awb: this.vendorShipmentObject.vendor_awb,
        organization_awb_status:
          this.vendorShipmentObject.last_status_sent_to_org,
      };

      this.$api.vendorShipments
        .fetchVendorShipmentStatus(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: res.data.message,
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.data.detail,
            color: "red",
          });
        });
    },
  },
};
</script>
<style scoped>
.v-tab--active {
  background-color: #112f4f !important;
  color: white;
}
</style>
