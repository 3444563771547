import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"ma-0"},[_c(VCol,{staticClass:"q-pa-md relative-position"},[_c('BaseListLayout',{ref:"vendorShipmentListRef",attrs:{"name":"vendorShipmentList","hasTabs":true,"title":"Vendor Shipments","searchable":"","table-header":_vm.columns,"table-data":_vm.vendorShipmentList,"context":_vm.context,"total":_vm.totalItems,"localStorageKey":"shipmentColumns"},on:{"getList":_vm.getVendorShipmentList,"selectionChanged":_vm.selectionChanged},scopedSlots:_vm._u([{key:"rightSlot",fn:function(){return [_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ma-1",attrs:{"disabled":!_vm.selectedShipments.length,"color":"primary","small":"","depressed":""},on:{"click":function($event){return _vm.getVendorFormattedStatusList()}}},'v-btn',attrs,false),on),[_vm._v(" Update Status ")])]}}])},[_c(VList,_vm._l((_vm.formattedStatusList),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.getSelectedFormattedStatus(item.formatted_vendor_status)}}},[_c(VListItemTitle,[_vm._v(_vm._s(item.formatted_vendor_status))])],1)}),1)],1)]},proxy:true},{key:"leftFilterSlot",fn:function(){return [_c('div',[_c('FilterPanel',{attrs:{"filter_for":"vendor"},on:{"applyFilters":_vm.applyFilter}})],1)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }