import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"width":"60vh","title":`${_vm.editMode ? 'Edit' : 'Add'} Client`},on:{"closeDialog":function($event){_vm.showClientForm = false}},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c(VForm,{ref:"clientForm",attrs:{"id":"clientForm","name":"clientForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VRow,{attrs:{"no-gutters":""}},[(
            _vm.formErrors.non_field_errors &&
            _vm.formErrors.non_field_errors.length > 0
          )?_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{attrs:{"dense":"","type":"error"}},_vm._l((_vm.formErrors.non_field_errors),function(error,i){return _c(VList,{key:i,staticClass:"pa-0",staticStyle:{"background":"inherit !important"},attrs:{"dense":""}},[_c(VListItem,{attrs:{"dense":""}},[_c('span',[_vm._v(_vm._s(error))])])],1)}),1)],1):_vm._e(),_c(VCol,{staticClass:"pb-3",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","type":"url","hide-details":"auto","dense":"","label":"Client Code*","rules":[(val) => !!val || 'Client Code is required'],"error-messages":_vm.formErrors && _vm.formErrors.code ? _vm.formErrors.code : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.code ? delete _vm.formErrors.code : ''}},model:{value:(_vm.clientForm.code),callback:function ($$v) {_vm.$set(_vm.clientForm, "code", $$v)},expression:"clientForm.code"}})],1),_c(VCol,{staticClass:"pb-3",attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","type":"url","hide-details":"auto","dense":"","label":"Client Name*","rules":[(val) => !!val || 'Client Name is required'],"error-messages":_vm.formErrors && _vm.formErrors.name ? _vm.formErrors.name : ''},on:{"input":function($event){_vm.formErrors && _vm.formErrors.name ? delete _vm.formErrors.name : ''}},model:{value:(_vm.clientForm.name),callback:function ($$v) {_vm.$set(_vm.clientForm, "name", $$v)},expression:"clientForm.name"}})],1),(!_vm.isUserTypeOrganization)?_c(VCol,{staticClass:"pb-3",attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"label":"Select Organization*","required":true,"itemsList":_vm.organizationList,"isClearable":false,"item-text":"name","item-value":"id","rules":[(val) => !!val || 'Organization is required'],"error-messages":_vm.formErrors && _vm.formErrors.organization
                ? _vm.formErrors.organization
                : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.organization
                ? delete _vm.formErrors.organization
                : ''}},model:{value:(_vm.clientForm.organization),callback:function ($$v) {_vm.$set(_vm.clientForm, "organization", $$v)},expression:"clientForm.organization"}})],1):_vm._e(),_c(VCol,{staticClass:"pb-3",attrs:{"cols":"12"}},[_c('BaseSelect',{attrs:{"label":"Select Integration Partner*","required":true,"itemsList":_vm.integrationPartnerList,"isClearable":false,"item-text":"name","item-value":"id","rules":[(val) => !!val || 'Integration Partner is required'],"error-messages":_vm.formErrors && _vm.formErrors.integration_partner
                ? _vm.formErrors.integration_partner
                : ''},on:{"change":function($event){_vm.formErrors && _vm.formErrors.integration_partner
                ? delete _vm.formErrors.integration_partner
                : ''}},model:{value:(_vm.clientForm.integration_partner),callback:function ($$v) {_vm.$set(_vm.clientForm, "integration_partner", $$v)},expression:"clientForm.integration_partner"}})],1),_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"pr-2",attrs:{"outlined":"","hide-details":"auto","dense":"","readonly":"","label":"Api Key*","rules":[(val) => !!val || 'Api key is required'],"error-messages":_vm.formErrors && _vm.formErrors.api_key ? _vm.formErrors.api_key : '',"append-icon":_vm.copied ? 'mdi-check' : 'mdi-content-copy'},on:{"click:append":function($event){return _vm.copyApiValue(_vm.clientForm.api_key)}},model:{value:(_vm.clientForm.api_key),callback:function ($$v) {_vm.$set(_vm.clientForm, "api_key", $$v)},expression:"clientForm.api_key"}}),_c(VBtn,{attrs:{"color":"secondary","small":""},on:{"click":_vm.generateApiKey}},[_vm._v(" Generate ")])],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(!_vm.editMode)?_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.isValid,"small":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Submit ")])]},proxy:true}]),model:{value:(_vm.showClientForm),callback:function ($$v) {_vm.showClientForm=$$v},expression:"showClientForm"}})
}
var staticRenderFns = []

export { render, staticRenderFns }