<template>
  <div>
    <v-card
      v-if="shipmentObject.category == 'Order'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="1"
      class="overflow-y-auto rounded-lg"
    >
      <v-row no-gutters>
        <v-col cols="6" class="mt-0">
          <v-card elevation="1" class="rounded-lg">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Basic Information
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="detail in basicInformation"
                :key="detail.label"
                class="mt-1"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ detail.value ? detail.value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-3 rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Order Information
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="detail in orderInformation"
                :key="detail.label"
                class="mt-1"
              >
                <v-col cols="6" class="text-capitalize">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ detail.value ? detail.value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" class="pl-2">
          <v-card elevation="1" class="rounded-lg">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Shipper
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="(value, key) in payloadData.shipper_information"
                :key="key"
                class="mt-1"
              >
                <v-col cols="4" class="text-capitalize">
                  {{ key.replace(/\_/g, " ") }}
                </v-col>
                <v-col cols="8" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ value ? value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-3 rounded-lg" elevation="1">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Consignee
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="(value, key) in payloadData.consignee_information"
                :key="key"
                class="mt-1"
              >
                <v-col cols="4" class="text-capitalize">
                  {{ key.replace(/\_/g, " ") }}
                </v-col>
                <v-col cols="8" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ value ? value : "-" }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="mt-3">
          <v-card class="rounded-lg" elevation="2">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Order Packages
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <BaseListLayout
                name="packages"
                ref="packages"
                :table-header="orderPackageColumns"
                :table-data="payloadData.order_packages"
                :hasAddButton="true"
                :context="context"
                :gridStyle="{ height: '40vh' }"
                :showColumnSelection="false"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="shipmentObject.category == 'Cancel Order'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="1"
      class="overflow-y-auto rounded-lg"
    >
      <v-row no-gutters>
        <v-col cols="6" class="mt-0">
          <v-card elevation="1" rounded="lg">
            <v-card-title class="py-1 px-3 ma-0 secondary text-white">
              Basic Information
            </v-card-title>
            <v-card-text class="px-3 py-2">
              <v-row
                no-gutters
                v-for="detail in basicInformation"
                :key="detail.label"
                class="mt-1"
              >
                <v-col cols="6">
                  {{ detail.label }}
                </v-col>
                <v-col cols="6" class="text-right">
                  <span class="primary--text font-weight-bold">
                    {{ detail.value }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card elevation="1" rounded="lg">
            <v-card-title
              class="ma-0"
              :class="
                shipmentObject.response.status_code == 400
                  ? 'red--text'
                  : 'green--text'
              "
            >
              {{ shipmentObject.response }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="shipmentObject.category == 'Track AWB'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="1"
      class="overflow-y-auto rounded-lg"
    >
      <v-card class="rounded-lg mb-4" elevation="1">
        <v-card-title class="py-1 px-3 ma-0 secondary text-white">
          Basic Information
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 py-2">
          <v-row
            no-gutters
            v-for="detail in basicInformation"
            :key="detail.label"
            class="mt-1"
          >
            <v-col cols="2">
              {{ detail.label }}
            </v-col>
            <v-col cols="2" class="text-right">
              <span class="primary--text font-weight-bold">
                {{ detail.value }}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="rounded-lg" elevation="1">
        <v-card-title
          v-if="
            shipmentObject &&
            shipmentObject.response &&
            shipmentObject.response.status_code == 400
          "
          class="ma-0 red--text"
        >
          {{ shipmentObject.response }}
        </v-card-title>
        <v-card-title v-else class="py-1 px-3 ma-0 secondary text-white">
          Response
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-3 py-2">
          <v-row>
            <v-col
              cols="4"
              v-for="(res, i) in shipmentObject.response"
              :key="i"
              class="mt-1"
            >
              <v-card style="background-color: #f5f8fa" elevation="1">
                <v-card-title>{{ res.awb_number }}</v-card-title>
                <v-card-text>
                  <v-timeline dense>
                    <v-timeline-item
                      v-for="(log, i) in res.awb_logs"
                      :key="i"
                      small
                    >
                      <div>
                        <v-row no-gutters>
                          <v-col cols="6">
                            <span style="font-size: 10px" class="text-caption">
                              {{ log.status }}
                            </span>
                          </v-col>
                          <v-col cols="6" class="text-right">
                            <span
                              class="font-weight-light text_color--text"
                              style="font-size: 10px"
                            >
                              {{ log.created }}
                            </span>
                          </v-col>
                          <v-col cols="12">
                            <h5 class="text_color--text text-caption">
                              {{ log.message }}
                            </h5>
                          </v-col>
                          <v-col cols="12">
                            <span style="font-size: 10px" class="text-caption">
                              Country - {{ log.country }}, Branch -
                              {{ log.current_branch }}
                            </span>
                          </v-col>
                        </v-row>
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <v-card
      v-if="shipmentObject.category == 'Generate AWB PDF'"
      style="background-color: #f5f8fa; height: 800px"
      elevation="1"
      class="overflow-y-auto rounded-lg"
    >
      <v-row> <v-col cols="12">PDF Order</v-col> </v-row>
    </v-card>
  </div>
</template>
<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";

export default {
  name: "FeroSummary",
  props: ["shipmentObject", "payloadData"],
  components: { BaseListLayout },
  data() {
    return {
      curr: 0,
      steps: [
        { name: "Created" },
        { name: "Picked Up" },
        {
          name: "Arrived At Hub",
        },
        { name: "Delivered" },
      ],
      orderPackageColumns: [
        {
          headerName: "Width",
          field: "width",
        },
        {
          headerName: "Height",
          field: "height",
        },
        {
          headerName: "Weight",
          field: "weight",
        },
        {
          headerName: "Length",
          field: "length",
        },
        {
          headerName: "Pieces",
          field: "pieces",
        },
        {
          headerName: "Content",
          field: "content",
        },
        {
          headerName: "Barcode Value",
          field: "barcode_value",
        },
      ],
    };
  },
  watch: {
    shipmentObject(val) {
      switch (val.last_status) {
        case "Created":
          return (this.curr = 1);
        case "Picked Up":
          return (this.curr = 2);
        case "Arrived At Hub":
          return (this.curr = 3);
        case "Delivered":
          return (this.curr = 4);
        default:
          return (this.curr = 0);
      }
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    basicInformation() {
      return [
        {
          label: "Integration Partner",
          value: this.shipmentObject.integration_partner_name,
        },
        {
          label: "Organization",
          value: this.shipmentObject.organization_name,
        },
        {
          label: "Client",
          value: this.shipmentObject.client_name,
        },
        {
          label: "Last AWB Status",
          value: this.shipmentObject.last_awb_status,
        },
        {
          label: "Last Status Updated At",
          value: this.shipmentObject.last_awb_status,
        },
      ];
    },
    orderInformation() {
      return [
        {
          label: "ready at time",
          value: this.payloadData.ready_at_time,
        },
        {
          label: "closing time",
          value: this.payloadData.closing_time,
        },
        {
          label: "shipment process",
          value: this.payloadData.shipment_process,
        },
        {
          label: "service type",
          value: this.payloadData.service_type,
        },
        {
          label: "is dangerous goods",
          value: this.payloadData.is_dangerous_goods ? "Yes" : "No",
        },
        {
          label: "is stack able",
          value: this.payloadData.is_stackable ? "Yes" : "No",
        },
        {
          label: "is multi piece shipment",
          value: this.payloadData.is_multipiece_shipment ? "Yes" : "No",
        },
        {
          label: "is reverse shipment",
          value: this.payloadData.is_reverse_shipment ? "Yes" : "No",
        },
        {
          label: "cod value",
          value: this.payloadData.cod_value,
        },
        {
          label: "consignment value",
          value: this.payloadData.consignment_value,
        },
        {
          label: "insurance value",
          value: this.payloadData.insurance_value,
        },
        {
          label: "special instruction",
          value: this.payloadData.special_instruction,
        },
        {
          label: "integration reference no",
          value: this.payloadData.integration_reference_no,
        },
      ];
    },
  },
};
</script>
