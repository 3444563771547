<template>
  <v-row class="ma-0">
    <v-col class="q-pa-md relative-position">
      <BaseListLayout
        ref="listLayout"
        title="API Configuration"
        :table-header="columns"
        :table-data="tableData"
        :hasAddButton="true"
        :context="context"
        :total="totalItems"
        name="configurationList"
      >
      </BaseListLayout>
      <BaseDialog
        v-model="showUpdate"
        title="Update Configuration"
        max-width="400"
        @closeDialog="showUpdate = false"
      >
        <template #dialogContent>
          <v-select
            label="Select Days"
            dense
            outlined
            hide-details
            :items="[7, 30, 60, 90]"
          >
          </v-select>
        </template>
        <template #actions>
          <v-btn color="primary" small @click="showUpdate = false">
            Update
          </v-btn>
        </template>
      </BaseDialog>
    </v-col>
  </v-row>
</template>
<script>
import BaseListLayout from "@/components/BaseLayoutComponents/BaseListLayout";
import ActionsButton from "@/components/AgGridButtons/ActionsButton.vue";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";

export default {
  name: "Configuration",
  components: { BaseListLayout, ActionsButton, BaseDialog },
  data() {
    return {
      canEdit: true,
      showUpdate: false,
      totalItems: 0,
      tableData: [
        { request_log: "Order", timing: "Last 30 days" },
        { request_log: "Webhook", timing: "Last 30 days" },
      ],
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    columns() {
      return [
        { headerName: "Api", field: "request_log" },
        { headerName: "Timing", field: "timing" },

        {
          headerName: "Actions",
          field: "actions",
          cellRenderer: "ActionsButton",
          minWidth: 100,
        },
      ];
    },
  },
  methods: {
    editDetails(id) {
      this.showUpdate = true;
    },
  },
};
</script>