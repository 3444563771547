import { report } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions.js";

export default (axios) => ({
  getReportList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(report.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  generateReport(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(report.base, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadReport(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${report.base}${id}/download/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOptionsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${report.base}options_values/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
