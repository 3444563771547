<template>
  <BaseDialog
    v-model="showVendorApiForm"
    :title="`${editMode ? 'Edit' : 'Add'} Api`"
    width="80vh"
    @closeDialog="resetForm(), (showVendorApiForm = false)"
  >
    <template #dialogContent>
      <v-form
        v-model="isValid"
        ref="vendorApiForm"
        id="vendorApiForm"
        name="vendorApiForm"
      >
        <v-row no-gutters>
          <v-col cols="6" class="pb-3 pr-2">
            <v-text-field
              outlined
              hide-details="auto"
              dense
              label="Api name*"
              :rules="[(val) => !!val || 'Api name is required']"
              :error-messages="
                formErrors && formErrors.api_name ? formErrors.api_name : ''
              "
              v-model="vendorApiForm.api_name"
              @input="
                formErrors && formErrors.api_name
                  ? delete formErrors.api_name
                  : ''
              "
            />
          </v-col>
          <v-col cols="6" class="pb-3">
            <BaseSelect
              :isSearchRequired="false"
              label="Vendor API Choices*"
              :required="true"
              :itemsList="vendorApiChoicesList"
              :isClearable="false"
              item-text="display_name"
              item-value="value"
              :rules="[(val) => !!val || 'Vendor API Choices is required']"
              v-model="vendorApiForm.api_type"
              :error-messages="
                formErrors && formErrors.api_type ? formErrors.api_type : ''
              "
              @change="
                formErrors && formErrors.api_type
                  ? delete formErrors.api_type
                  : ''
              "
            />
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-text-field
              outlined
              type="url"
              hide-details="auto"
              dense
              label="Api URL*"
              :rules="[(val) => !!val || 'Api URL is required']"
              :error-messages="
                formErrors && formErrors.api_url ? formErrors.api_url : ''
              "
              v-model="vendorApiForm.api_url"
              @input="
                formErrors && formErrors.api_url
                  ? delete formErrors.api_url
                  : ''
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            Schema
            <JsonEditorVue
              :mode="'code'"
              style="height: 100%"
              v-model="vendorApiForm.payload_schema"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn color="primary" v-if="!editMode" small @click="resetForm">
        Reset
      </v-btn>
      <v-btn color="primary" :disabled="!isValid" small @click="submitForm()">
        Submit
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { bus } from "@/main";
import BaseDialog from "@/components/BaseComponents/BaseDialog.vue";
import BaseSelect from "@/components/BaseComponents/BaseSelect.vue";
import JsonEditorVue from "vue-json-editor";

export default {
  components: { BaseDialog, BaseSelect, JsonEditorVue },
  props: {
    value: {
      type: Boolean,
    },
    editMode: {
      type: Boolean,
    },
    vendorApiId: {
      type: Number,
    },
  },
  data() {
    return {
      formErrors: {
        non_field_errors: [],
      },
      isValid: true,
      vendorApiForm: {},
      vendorApiChoicesList: [],
    };
  },
  watch: {
    showVendorApiForm(val) {
      if (val) {
        this.getVendorApiChoicesList();
        if (this.editMode && this.vendorApiId) {
          this.getVendorApiObject();
        }
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showVendorApiForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getVendorApiChoicesList() {
      this.$api.vendor
        .getVendorApiChoicesList({ limit: "all" })
        .then((res) => {
          this.vendorApiChoicesList = res.data.actions.POST.api_type.choices;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "error fetching list",
            color: "red",
          });
        });
    },
    getVendorApiObject() {
      this.$api.vendor
        .getVendorApiObject(this.vendorApiId)
        .then((res) => {
          this.vendorApiForm = res.data;
        })
        .catch((err) => {
          this.formErrors = err.data;
        });
    },
    submitForm() {
      bus.$emit("showLoader", true);
      let data = {
        vendor: this.$route.params.name,
        ...this.vendorApiForm,
      };
      if (!this.editMode) {
        this.$api.vendor
          .addVendorApi(data)
          .then((res) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Vendor Api Added!",
              color: "success",
            });
            this.resetForm();
            this.showVendorApiForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        this.$api.vendor
          .editVendorApi({
            id: this.vendorApiForm.id,
            data: data,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Vendor Api updated!",
              color: "success",
            });
            this.resetForm();
            this.showVendorApiForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      }
    },
    resetForm() {
      const form = this.$refs.vendorApiForm;
      if (form) {
        form.reset();
      }
      this.vendorApiForm.payload_schema = null;
    },
  },
};
</script>

<style>
textarea.jsoneditor-text,
.ace-jsoneditor {
  min-height: 400px;
}
</style>
