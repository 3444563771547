import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{staticClass:"mt-0 primary d-flex align-center"},[_c(VCol,{attrs:{"cols":"10"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-white text-body-2 mr-1 font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.orderInformation.awb_number)+" ")])]}}])},[_vm._v(" AWB Number ")]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-white text-body-2 mr-1 font-weight-bold"},'span',attrs,false),on),[_vm._v(" ("+_vm._s(_vm.orderInformation.trace_id)+") ")])]}}])},[_vm._v(" Trace Id ")])],1),_c(VCol,{staticClass:"d-flex align-center justify-end",attrs:{"cols":""}},[_c(VBtn,{attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$emit('closeDetails')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pa-0 ma-0",attrs:{"elevation":"0"}},[_c(VDivider),_c(VCardText,_vm._l((_vm.details),function(detail){return _c(VRow,{key:detail.label,staticClass:"mb-1",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-caption text--primary"},[_vm._v(" "+_vm._s(detail.label)+" ")])]),(detail.label == 'Status Code')?_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c(VChip,{staticClass:"text-white font-weight-bold",attrs:{"color":detail.value == 200 || detail.value == 201 ? 'green' : 'red',"size":"x-small"}},[_c('span',[_vm._v(" "+_vm._s(detail.value ? detail.value : "-")+" ")])])],1):_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('h5',[_vm._v(_vm._s(detail.value ? detail.value : "-"))])])],1)}),1),_c(VDivider),(
            _vm.orderInformation &&
            _vm.orderInformation.headers &&
            Object.keys(_vm.orderInformation).length
          )?_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v(" Headers ")])]),_c(VCol,{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('JsonViewer',{attrs:{"value":_vm.orderInformation.headers,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1):_vm._e(),(
            _vm.orderInformation &&
            _vm.orderInformation.payload &&
            Object.keys(_vm.orderInformation).length
          )?_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v(" Payload ")])]),_c(VCol,{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('JsonViewer',{attrs:{"value":_vm.orderInformation.payload,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1):_vm._e(),(
            _vm.orderInformation &&
            _vm.orderInformation.response &&
            Object.keys(_vm.orderInformation).length
          )?_c(VCardText,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"6"}},[_c('h4',{staticClass:"text-body-2 text--primary font-weight-bold"},[_vm._v(" Response ")])]),_c(VCol,{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('JsonViewer',{attrs:{"value":_vm.orderInformation.response,"expand-depth":5,"copyable":"","boxed":"","sort":""}})],1)],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }