import { outbound } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getOrganizationShipmentList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(outbound.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  viewOrganizationShipment(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outbound.base}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationShipmentSystemLogs(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outbound.base}${id}/system_logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrganizationShipmentHistoryLogs(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${outbound.base}${id}/history_logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
