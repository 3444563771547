const statusOption = [
  { label: "Success", value: "true" },
  { label: "Failed", value: "false" },
];
const statusCodeOption = [
  { label: "200", value: 200 },
  { label: "201", value: 201 },
  { label: "400", value: 400 },
  { label: "404", value: 404 },
  { label: "405", value: 405 },
];
const orderStatusOption = [
  { label: "Created", value: "Created" },
  { label: "Ready For Pickup", value: "Ready For Pickup" },
  { label: "Scheduled For Pickup", value: "Scheduled For Pickup" },
  { label: "Picked Up", value: "Picked Up" },
  { label: "Pickup Failed", value: "Pickup Failed" },
  { label: "Ready for Delivery", value: "Ready for Delivery" },
  { label: "Scheduled For Delivery", value: "Scheduled For Delivery" },
  { label: "Out For Delivery", value: "Out For Delivery" },
  { label: "Delivered", value: "Delivered" },
  { label: "Delivery Failed", value: "Delivery Failed" },
  { label: "Ready For Return", value: "Ready For Return" },
  { label: "Out For Return", value: "Out For Return" },
  { label: "Returned", value: "Returned" },
];

export default {
  statusOption,
  statusCodeOption,
  orderStatusOption,
};
