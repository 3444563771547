<template>
  <v-dialog v-model="showLogsDialog" persistent width="40%" max-width="100vw">
    <v-card rounded="lg" elevation="2">
      <v-card-title class="primary">
        <v-row class="d-flex justify-space-between">
          <v-col cols="10">
            <h3 class="text-body-1 text-white font-weight-bold">
              {{ logTitle }} Logs
            </h3>
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-icon color="white" @click="showLogsDialog = false">
              mdi-close
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-3" :style="logHeight">
        <div class="pa-3 py-0">
          <p v-if="logs && !logs.length" class="text-caption text-center mt-3">
            No logs to show!
          </p>
          <v-card-text class="ma-0 pa-0 mt-2" v-if="logTitle == 'AWB'">
            <v-timeline dense>
              <v-timeline-item
                v-for="(log, i) in logs"
                :key="i"
                small
                fill-dot
                class="Timeline_Item"
                width="100%"
                dot-color="primary"
              >
                <div>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <span style="font-size: 10px" class="text-caption">
                        {{ log.status }} (
                        {{
                          log.created
                            ? convertEventTimeStamp(log.created)
                            : null
                        }})
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <h5 class="black--text text-caption">
                        {{ log.message }}
                      </h5>
                    </v-col>
                    <v-col cols="12">
                      <span style="font-size: 10px" class="text-caption">
                        Customer Name - {{ log.customer_name }}, Current Branch
                        -
                        {{ log.current_branch }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>

          <v-card-text v-else>
            <v-timeline dense>
              <v-timeline-item
                v-for="(log, i) in logs"
                :key="i"
                small
                fill-dot
                class="Timeline_Item"
                width="100%"
                dot-color="primary"
              >
                <div>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <span style="font-size: 10px" class="text-caption">
                        {{ log.status }} (
                        {{
                          log.created
                            ? convertEventTimeStamp(log.created)
                            : null
                        }})
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <h5 class="black--text text-caption">
                        {{ log.message }}
                      </h5>
                    </v-col>
                  </v-row>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { convertDateTime } from "@/utils/functions";

export default {
  name: "Logs",
  props: {
    value: {
      type: Boolean,
    },
    logs: {
      type: Array,
      default: () => [],
    },
    logHeight: {
      type: String,
      default: "",
    },
    logTitle: {
      type: String,
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    showLogsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    convertEventTimeStamp(value) {
      return convertDateTime(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.Timeline_Item {
  .v-card {
    overflow: visible;
  }

  .v-card:not(.v-card--flat)::after,
  .v-card:not(.v-card--flat):not(.v-card--link)::before {
    content: "";
    position: absolute;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid rgb(0, 0, 0, calc(var(--v-border-opacity) - 0.04));
    top: calc(50% - 10px);
    left: -10px;
  }
}
</style>
