<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="100"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom max-width="450">
        <template #activator="tooltipObj">
          <v-badge
            class="ml-2 text-white table-chip-index"
            top
            overlap
            :content="visibleCols.length"
            :value="visibleCols.length"
            color="secondary"
            style="z-index: 2 !important"
          >
            <v-btn
              color="primary"
              class="ma-1"
              small
              depressed
              v-on="{ ...on }"
              v-bind="{ ...attrs, ...tooltipObj.attrs }"
              @click="rightDrawer = true"
            >
              <v-icon color="white" size="25px">
                mdi-format-list-checks
              </v-icon>
            </v-btn>
          </v-badge>
        </template>
      </v-tooltip>
    </template>
    <div id="col-select-item-list-container">
      <v-list dense max-height="350">
        <v-list-item
          class="white"
          :selectable="false"
          v-for="(header, i) in allowedHeaders"
          :key="i"
          @click="toggleSelection(header)"
        >
          <v-list-item-icon>
            <v-icon v-if="isSelected(header)" color="primary">
              mdi-checkbox-marked
            </v-icon>
            <v-icon v-else> mdi-checkbox-blank-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ header.headerName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-menu>
</template>

<script>
/**
 * This component allow user to toggle
 * columns in ag-grid using ag-grid's
 * column API instance provided in
 * 'gridColInstance'.
 *
 * This accepts ag-grid headers list and
 * excludes the columns listed in 'hideColumns'
 * Note: 'actions' field is by default excluded
 *
 * If the localStorage key is provided is
 * sync the grid status with the server
 */

import { getAllUserPreferences, getUserPreferences } from "@/utils/functions";

export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    gridColInstance: {
      type: Object,
    },
    localStorageKey: {
      type: String,
    },
    hideColumns: {
      default: () => ["actions"],
    },
  },
  data() {
    return {
      menu: false,
      state: [],
      initialLoadInterval: null,
      instanceTimeout: null,
    };
  },
  computed: {
    allowedHeaders() {
      return this.headers.filter(
        (head) => this.hideColumns.indexOf(head.field) == -1
      );
    },
    visibleCols() {
      return this.state.filter((col) => !col.hide).map((col) => col.colId);
    },
    selectedColumns() {
      return this.headers
        .filter((head) => this.visibleCols.indexOf(head.field) > -1)
        .map((col) => col.headerName)
        .join(", ");
    },
  },
  methods: {
    toggleSelection(col) {
      this.gridColInstance.setColumnVisible(col.field, !this.isSelected(col));
      setTimeout(() => {
        this.getColState();
        this.syncPreferences();
      }, 100);
    },
    isSelected(col) {
      if (this.state && this.state.length) {
        const state = this.state.find((cs) => cs.colId == col.field);
        if (state) {
          return !state.hide;
        }
      } else {
        return true;
      }
    },
    getColState() {
      if (this.gridColInstance) {
        this.state = this.gridColInstance.getColumnState();
      }
    },
    syncPreferences() {
      if (this.instanceTimeout) {
        clearTimeout(this.instanceTimeout);
      }
      this.getColState();
      this.instanceTimeout = setTimeout(() => {
        if (this.localStorageKey) {
          let allPreference = getAllUserPreferences();
          allPreference[this.localStorageKey] = this.state;
          localStorage.setItem(
            "userPreferences",
            JSON.stringify(allPreference)
          );
        }
      }, 1000);
    },
    loadState() {
      if (this.initialLoadInterval) {
        clearInterval(this.initialLoadInterval);
      }
      this.initialLoadInterval = setInterval(() => {
        if (this.gridColInstance) {
          clearInterval(this.initialLoadInterval);
          let preference;
          if (!this.localStorageKey) {
            this.getColState();
            return;
          }
          preference = getUserPreferences(this.localStorageKey);
          if (preference) {
            this.gridColInstance.applyColumnState({ state: preference });
            preference.forEach((col, index) => {
              this.gridColInstance.moveColumn(col.colId, index);
            });
          }
          this.getColState();
        }
      }, 50);
      setTimeout(() => {
        if (this.initialLoadInterval) {
          clearInterval(this.initialLoadInterval);
        }
      }, 10000);
    },
  },
  mounted() {
    this.loadState();
    // this.$bus.$on("refreshState", this.loadState);
    // this.$bus.$on("syncPreferences", this.syncPreferences);
  },
  beforeDestroy() {
    // this.$bus.$off("refreshState");
    // this.$bus.$off("syncPreferences");
  },
};
</script>
<style >
.table-chip-index .v-badge__wrapper span {
  z-index: 2 !important;
}
</style>